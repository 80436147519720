import { Participant, ZoomClient } from '../pages/meeting-view/types/video-types'
import styled, { css } from 'styled-components'

import type { AdvancedFeatureSwitch } from '../pages/meeting-view/types/video-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { NetworkQuality } from '@zoom/videosdk'
import { capitalizeAll } from '../helpers/formatters'
import { useRef } from 'react'
import { useZoomContext } from '../context/ZoomContext'

interface AvatarProps {
    participant: Participant
    style?: { [key: string]: string }
    isActive: boolean
    className?: string
    volume?: number
    isUserCameraControlled?: boolean
    advancedFeature?: AdvancedFeatureSwitch
    networkQuality?: NetworkQuality
    onAdvancedFeatureToggle?: (userId: number, key: string) => void
    setLocalVolume?: (userId: number, volume: number) => void
    zoomClient: ZoomClient
}

const Avatar = (props: AvatarProps) => {
    const { isUserHandRaised } = useZoomContext()
    const { participant, style, isActive, networkQuality } = props
    const { userId, displayName, audio, muted, bVideoOn, isInFailover } = participant
    const avatarRef = useRef(null)

    const capitalizedDisplayName = capitalizeAll(displayName)

    return (
        <AvatarStyled
            isActive={isActive}
            bVideoOn={bVideoOn}
            className={`avatar ${isActive ? 'avatar-active' : ''}`}
            style={style}
            ref={avatarRef}
        >
            {bVideoOn ? (
                <div className="user">
                    <div className="top-left">
                        {audio === 'computer' && muted && (
                            <FontAwesomeIcon
                                icon={['far', 'microphone-slash']}
                                style={{ color: '#FF647C', marginRight: '5px' }}
                            />
                        )}
                        {bVideoOn && <span>{capitalizedDisplayName}</span>}
                    </div>
                    <div className="top-right">
                        {networkQuality && <QualitySignal {...networkQuality} />}
                        {isUserHandRaised(userId) && <FontAwesomeIcon icon={['far', 'hand']} className="raised-hand" />}
                        {isInFailover && <FontAwesomeIcon icon={['far', 'spinner']} spin className="failover" />}
                    </div>
                </div>
            ) : (
                <p className="center-name">{capitalizedDisplayName}</p>
            )}
        </AvatarStyled>
    )
}

export default Avatar

const QualitySignal = ({ downlink, uplink }: NetworkQuality) => {
    const nql = Math.min(downlink ?? Number.MAX_VALUE, uplink ?? Number.MAX_VALUE)
    return (
        <QualityIcon nql={nql}>
            <FontAwesomeIcon className="icon" icon={['far', nql <= 1 ? 'wifi-exclamation' : 'wifi']} />
        </QualityIcon>
    )
}

const QualityIcon = styled.div<{ nql: number }>`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => (props.nql && props.nql <= 1 ? '#FF647C' : 'rgba(255,255,255,0)')};
    border-radius: 30px;
    .icon {
        filter: drop-shadow(0px 2px 3px #202124);
    }
`

const AvatarStyled = styled.div<{ isActive: boolean; bVideoOn: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: visible;
    background: ${props => (props.bVideoOn ? 'transparent' : 'rgb(26,26,26)')};
    ${props =>
        props.isActive &&
        css`
            outline: ${props => `4px solid ${props.theme.color.electricBlue}`};
        `};
    border-radius: 0px;
    top: 0;
    .user {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        padding: 2% 3%;
        align-items: center;
        max-width: 100%;
        width: 100%;
        height: auto;
        font-size: 14px;
        text-shadow: 0px 2px 3px #202124;
        font-weight: 600;
        color: #fff;
        background: none !important;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        svg {
            min-width: 15px;
        }
        .top-left {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            min-height: 25px;
        }
        .top-right {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            .raised-hand {
                background-color: white;
                color: #003750;
                border-radius: 50%;
                width: 15px;
                height: auto;
                text-align: center;
                padding: 5px;
            }
            .failover {
                color: white;
                width: 25px;
            }
        }
    }
    span.anticon {
        margin-right: 5px;
    }
    .center-name {
        font-size: 28px;
        font-weight: 700;
        color: #fff;
        text-align: center;
    }
    .more-button {
        position: absolute;
        top: 3px;
        right: 3px;
        border: 0;
        border-radius: 3px;
        background: #0171eb;
        display: none;
    }
    &.more-button-active {
        display: inline-block;
    }
`
