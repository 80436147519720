import { LinkBtn, SalmonBtn } from '../../components/Buttons'
import { useEffect, useState } from 'react'

import { ConnectionChangeReason } from '../meeting-view/hooks/useMediaContext'
import { Feedback } from './Feedback'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingPage from '../static/LoadingPage'
import { Reason } from './Reason'
import ZoomVideo from '@zoom/videosdk'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import { useActions } from '../../hooks/useActions'
import { useReJoin } from '../meeting-view/hooks/useReJoin'
import { useSearchParams } from 'react-router-dom'
import { useZoomContext } from '../../context/ZoomContext'
import { useGeneralData } from '../../hooks/useGeneralData'

const PostMeeting = () => {
    const reJoin = useReJoin()
    const { zoomClient, setSidebarMode } = useZoomContext()
    const [searchParams] = useSearchParams()
    const reason = searchParams.get('reason') as ConnectionChangeReason
    const isEndedByHost = reason === 'ended by host'
    const isMoving = reason === 'moving'

    const { data } = useGeneralData('general')
    const isZoomSDK = data?.meetingType === 'zoom-sdk'
    const zoomSDKId = data?.data.generalMeet
    const generalUrl = (isZoomSDK && zoomSDKId?.length && `https://zoom.us/j/${zoomSDKId}`) || ''
    const [ready] = useState(true)

    useActions()

    useEffect(() => {
        zoomClient.leave()
        ZoomVideo.destroyClient()
    }, [zoomClient])

    useEffect(() => {
        setSidebarMode(null)
    }, [setSidebarMode])

    useEffect(() => {
        toast.dismiss()
    }, [])

    if (!ready) return <LoadingPage />

    return (
        <PostMeetingStyled>
            <div className="container">
                <Reason />
                {!isMoving && (
                    <>
                        <Feedback />
                        <div className="btn-container">
                            {isEndedByHost && isZoomSDK && (
                                <SalmonBtn onClick={() => window.open(generalUrl)}>
                                    <FontAwesomeIcon icon={['fas', 'circle-video']} className="button-icon" />
                                    Entrar al encuentro general
                                </SalmonBtn>
                            )}
                            {!isEndedByHost && <SalmonBtn onClick={reJoin}>Volver al encuentro</SalmonBtn>}
                            <LinkBtn onClick={() => window.close()}>Salir</LinkBtn>
                        </div>
                    </>
                )}
            </div>
        </PostMeetingStyled>
    )
}

export default PostMeeting

const PostMeetingStyled = styled.section`
    width: 100vw;
    height: 100vh;
    background-color: ${defaultTheme.color.midnightBlack};
    color: ${defaultTheme.color.white};
    text-align: center;
    overflow: auto;
    ::-webkit-scrollbar {
        width: 0px;
    }

    & > .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > .btn-container {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            & > button {
                padding: 14px;
                & > .button-icon {
                    font-size: 20px;
                }
            }
        }

        @media (max-width: 768px) {
            padding: 20px;
        }
    }
`
