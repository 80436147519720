import {
    faArrowRight,
    faCheckCircle,
    faCheckSquare,
    faCircleNotch,
    faCircleQuestion,
    faClock,
    faCoffee,
    faHandPaper,
    faLinkSimple,
    faListUl,
    faMailbox,
    faSpinner,
    faTimesCircle,
    faUserCircle,
    faAngleDown as falAngleDown,
    faAngleLeft as falAngleLeft,
    faAngleRight as falAngleRight,
    faArrowLeft as falArrowLeft,
    faArrowRight as falArrowRight,
    faArrowRightFromBracket as falArrowRightFromBracket,
    faBell as falBell,
    faBolt as falBolt,
    faBrowser as falBrowser,
    faBullseyeArrow as falBullseyeArrow,
    faCalendar as falCalendar,
    faCamera as falCamera,
    faCameraMovie as falCameraMovie,
    faCameraSlash as falCameraSlash,
    faCheck as falCheck,
    faChevronLeft as falChevronLeft,
    faChevronRight as falChevronRight,
    faCircle as falCircle,
    faCircleArrowDown as falCircleArrowDown,
    faCircleCheck as falCircleCheck,
    faCircleInfo as falCircleInfo,
    faCircleLocationArrow as falCircleLocationArrow,
    faCircleQuestion as falCircleQuestion,
    faClock as falClock,
    faCode as falCode,
    faComments as falComments,
    faCompress as falCompress,
    faCopy as falCopy,
    faCube as falCube,
    faDatabase as falDatabase,
    faDisplaySlash as falDisplaySlash,
    faDownload as falDownload,
    faDrawCircle as falDrawCircle,
    faEgg as falEgg,
    faEllipsisVertical as falEllipsisVertical,
    faExpand as falExpand,
    faFileCertificate as falFileCertificate,
    faFilePdf as falFilePdf,
    faFolder as falFolder,
    faFutbol as falFutbol,
    faGamepadModern as falGamepadModern,
    faGauge as falGauge,
    faGaugeMax as falGaugeMax,
    faGraduationCap as falGraduationCap,
    faGrid2 as falGrid2,
    faHeadset as falHeadset,
    faHeart as falHeart,
    faHome as falHome,
    faInfoCircle as falInfoCircle,
    faLinkSimple as falLinkSimple,
    faList as falList,
    faMagnifyingGlass as falMagnifyingGlass,
    faMailbox as falMailbox,
    faMedal as falMedal,
    faMicrophone as falMicrophone,
    faMicrophoneSlash as falMicrophoneSlash,
    faMusic as falMusic,
    faNote as falNote,
    faPaperPlaneTop as falPaperPlaneTop,
    faPlane as falPlane,
    faPlayCircle as falPlayCircle,
    faScreencast as falScreencast,
    faScrewdriverWrench as falScrewdriverWrench,
    faSearch as falSearch,
    faShoppingBag as falShoppingBag,
    faShoppingCart as falShoppingCart,
    faSignal1 as falSignal1,
    faSignal2 as falSignal2,
    faSignal3 as falSignal3,
    faSignal4 as falSignal4,
    faSmoke as falSmoke,
    faStar as falStar,
    faStreetView as falStreetView,
    faThumbsDown as falThumbsDown,
    faTimes as falTimes,
    faTreePalm as falTreePalm,
    faTrophy as falTrophy,
    faTvRetro as falTvRetro,
    faUser as falUser,
    faUserCheck as falUserCheck,
    faUserClock as falUserClock,
    faUserGroup as falUserGroup,
    faUserHeadset as falUserHeadset,
    faUserSlash as falUserSlash,
    faUsers as falUsers,
    faVideo as falVideo,
    faWindowRestore as falWindowRestore,
    faCircleCheck as fatCircleCheck,
} from '@fortawesome/pro-light-svg-icons'
import {
    faBell,
    faCircle,
    faMedal,
    faTrophy,
    faUsersClass,
    faAngleLeft as fasAngleLeft,
    faAngleRight as fasAngleRight,
    faArrowLeft as fasArrowLeft,
    faArrowRight as fasArrowRight,
    faArrowUpRightFromSquare as fasArrowUpRightFromSquare,
    faCamera as fasCamera,
    faCheck as fasCheck,
    faCheckCircle as fasCheckCircle,
    faChevronLeft as fasChevronLeft,
    faChevronRight as fasChevronRight,
    faCircle0 as fasCircle,
    faCircleArrowRight as fasCircleArrowRight,
    faCircleCheck as fasCircleCheck,
    faCircleExclamation as fasCircleExclamation,
    faCirclePlay as fasCirclePlay,
    faCircleQuestion as fasCircleQuestion,
    faCircleVideo as fasCircleVideo,
    faCode as fasCode,
    faComments as fasComments,
    faDownload as fasDownload,
    faDrawCircle as fasDrawCircle,
    faEgg as fasEgg,
    faFileCertificate as fasFileCertificate,
    faFolder as fasFolder,
    faGraduationCap as fasGraduationCap,
    faHeart as fasHeart,
    faHome as fasHome,
    faInfoCircle as fasInfoCircle,
    faLinkSimple as fasLinkSimple,
    faMapPin as fasMapPin,
    faMessageQuestion as fasMessageQuestion,
    faPhoneHangup as fasPhoneHangup,
    faPlayCircle as fasPlayCircle,
    faQrcode as fasQrCode,
    faRightFromBracket as fasRightFromBracket,
    faRotateLeft as fasRotateLeft,
    faStar as fasStar,
    faTimes as fasTimes,
    faUserCheck as fasUserCheck,
    faUserClock as fasUserClock,
    faUserHeadset as fasUserHeadset,
    faUserSlash as fasUserSlash,
    faUsers as fasUsers,
    faVideo as fasVideo,
} from '@fortawesome/pro-solid-svg-icons'
import {
    faDiscord as fabDiscord,
    faFacebook as fabFacebook,
    faGoogle as fabGoogle,
    faInstagram as fabInstagram,
    faIntercom as fabIntercom,
    faLinkedin as fabLinkedin,
    faYoutube as fabYoutube,
} from '@fortawesome/free-brands-svg-icons'
import {
    faAngleUp as farAngleUp,
    faArrowRight as farArrowRight,
    faArrowRightFromBracket as farArrowRightFromBracket,
    faBolt as farBolt,
    faBrowsers as farBrowsers,
    faCalendar as farCalendar,
    faCalendarClock as farCalendarClock,
    faCameraSlash as farCameraSlash,
    faCheckCircle as farCheckCircle,
    faCircle as farCircle,
    faCircleExclamation as farCircleExclamation,
    faCircleInfo as farCircleInfo,
    faClock as farClock,
    faCupTogo as farCupTogo,
    faDiagramVenn as farDiagramVenn,
    faDisplaySlash as farDisplaySlash,
    faEllipsisVertical as farEllipsisVertical,
    faHand as farHand,
    faHeadset as farHeadset,
    faHourglassEnd as farHourglassEnd,
    faMagnifyingGlass as farMagnifyingGlass,
    faMegaphone as farMegaphone,
    faMessageDots as farMessageDots,
    faMessageQuestion as farMessageQuestion,
    faMicrophone as farMicrophone,
    faMicrophoneSlash as farMicrophoneSlash,
    faPaperPlaneTop as farPaperPlaneTop,
    faPhoneHangup as farPhoneHangup,
    faRankingStar as farRankingStar,
    faRightFromBracket as farRightFromBracket,
    faScreencast as farScreencast,
    faSignalFair as farSignalFair,
    faSignalGood as farSignalGood,
    faSignalStrong as farSignalStrong,
    faSignalWeak as farSignalWeak,
    faSparkles as farSparkles,
    faSpinner as farSpinner,
    faStar as farStar,
    faTimes as farTimes,
    faUsers as farUsers,
    faVideo as farVideo,
    faVideoSlash as farVideoSlash,
    faVolume as farVolume,
    faVolumeSlash as farVolumeSlash,
    faWifi as farWifi,
    faWifiExclamation as farWifiExclamation,
    faEgg as farEgg,
} from '@fortawesome/pro-regular-svg-icons'

import { faCheckCircle as fatCheckCircle } from '@fortawesome/pro-thin-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(
    faArrowRight,
    fabDiscord,
    faBell,
    fabFacebook,
    fabGoogle,
    fabInstagram,
    fabIntercom,
    fabLinkedin,
    fabYoutube,
    faCheckCircle,
    faCheckSquare,
    faCircle,
    faCircleNotch,
    faCircleQuestion,
    faClock,
    faCoffee,
    faHandPaper,
    falAngleDown,
    falAngleLeft,
    falAngleRight,
    falArrowLeft,
    falArrowRight,
    falArrowRight,
    falArrowRight,
    falArrowRightFromBracket,
    falBell,
    falBolt,
    falBrowser,
    falBullseyeArrow,
    falCalendar,
    falCamera,
    falCameraMovie,
    falCameraSlash,
    falCheck,
    falChevronLeft,
    falChevronLeft,
    falChevronLeft,
    falChevronRight,
    falChevronRight,
    falChevronRight,
    falCircle,
    falCircleArrowDown,
    falCircleCheck,
    falCircleInfo,
    falCircleLocationArrow,
    falCircleQuestion,
    farCircle,
    falClock,
    falCode,
    falCode,
    falCode,
    falComments,
    falCompress,
    falCopy,
    falCube,
    falDatabase,
    falDisplaySlash,
    falDownload,
    falDownload,
    falDownload,
    falDrawCircle,
    falEgg,
    falEgg,
    farEgg,
    falExpand,
    falFileCertificate,
    falFileCertificate,
    falFileCertificate,
    falFilePdf,
    falFolder,
    falFutbol,
    falGamepadModern,
    falGauge,
    falGaugeMax,
    falGraduationCap,
    falGraduationCap,
    falGraduationCap,
    falGrid2,
    falHeadset,
    falHeart,
    falHome,
    falInfoCircle,
    faLinkSimple,
    faListUl,
    falLinkSimple,
    falLinkSimple,
    falLinkSimple,
    falList,
    falMailbox,
    falMedal,
    falMicrophone,
    falMicrophoneSlash,
    falMusic,
    falNote,
    falPaperPlaneTop,
    falPlane,
    falPlayCircle,
    falScreencast,
    falScrewdriverWrench,
    falSearch,
    falShoppingBag,
    falShoppingCart,
    falSignal1,
    falSignal2,
    falSignal3,
    falSignal4,
    falSmoke,
    falStar,
    falStreetView,
    falThumbsDown,
    falTimes,
    falTreePalm,
    falTrophy,
    falTvRetro,
    falUser,
    falUserCheck,
    falUserClock,
    falUserGroup,
    falUserHeadset,
    falUsers,
    falUserSlash,
    falVideo,
    falWindowRestore,
    faMailbox,
    faMedal,
    farAngleUp,
    farArrowRightFromBracket,
    farArrowRight,
    farBolt,
    farCalendar,
    farCalendar,
    farCalendarClock,
    farCameraSlash,
    farCheckCircle,
    farDiagramVenn,
    farDisplaySlash,
    farHeadset,
    farHourglassEnd,
    farMegaphone,
    farMessageDots,
    farMessageQuestion,
    farMicrophone,
    farMicrophoneSlash,
    farPaperPlaneTop,
    farRankingStar,
    farRightFromBracket,
    farScreencast,
    farSparkles,
    farSpinner,
    farStar,
    farTimes,
    farVideo,
    farVideoSlash,
    farVolume,
    farHand,
    fasAngleLeft,
    fasAngleRight,
    fasArrowLeft,
    fasArrowRight,
    fasCamera,
    fasCheck,
    fasCheckCircle,
    fasChevronLeft,
    fasChevronRight,
    fasCircle,
    fasCircleArrowRight,
    fasCircleCheck,
    fasCircleExclamation,
    fasCircleExclamation,
    fasCircleQuestion,
    fasCode,
    fasComments,
    fasDownload,
    fasDrawCircle,
    fasEgg,
    fasFileCertificate,
    fasFolder,
    fasGraduationCap,
    fasHeart,
    fasHome,
    fasInfoCircle,
    fasLinkSimple,
    fasMapPin,
    fasMessageQuestion,
    faSpinner,
    fasPlayCircle,
    fasQrCode,
    fasRightFromBracket,
    fasRotateLeft,
    fasStar,
    fasTimes,
    fasUserCheck,
    fasUserClock,
    fasUserHeadset,
    fasUsers,
    fasUserSlash,
    fasVideo,
    fatCheckCircle,
    fatCircleCheck,
    faTimesCircle,
    faTrophy,
    faUserCircle,
    faUsersClass,
    falCircleArrowDown,
    falBolt,
    fasMapPin,
    farRankingStar,
    falMicrophone,
    falMicrophoneSlash,
    falCameraSlash,
    falAngleDown,
    falExpand,
    falCompress,
    falHeadset,
    falScreencast,
    falSignal1,
    falSignal2,
    falSignal3,
    falSignal4,
    falSmoke,
    falWindowRestore,
    falDisplaySlash,
    falAngleRight,
    falAngleLeft,
    fasAngleRight,
    fasAngleLeft,
    fasStar,
    falStar,
    falPaperPlaneTop,
    farPaperPlaneTop,
    farMicrophoneSlash,
    farMicrophone,
    farAngleUp,
    farHeadset,
    farVideo,
    farVideoSlash,
    farSpinner,
    farScreencast,
    farDisplaySlash,
    farMessageDots,
    farBolt,
    farCameraSlash,
    farVolume,
    farVolumeSlash,
    farSignalFair,
    farSignalWeak,
    farSignalGood,
    farSignalStrong,
    farWifi,
    farWifiExclamation,
    farUsers,
    falEllipsisVertical,
    falMagnifyingGlass,
    farMagnifyingGlass,
    farEllipsisVertical,
    farBrowsers,
    farCircleExclamation,
    farCupTogo,
    farClock,
    fasCircleVideo,
    farPhoneHangup,
    fasPhoneHangup,
    farCircleInfo,
    fasArrowUpRightFromSquare,
    fasCirclePlay
)
