import {
    ChatClient,
    CommandChannelClient,
    MediaStream,
    Participant,
    RecordingClient,
    ZoomClient,
} from '../pages/meeting-view/types/video-types'
import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import ZoomVideo, { LoggerClient, SessionInfo } from '@zoom/videosdk'

import { ConfirmationDialogComponents } from '../pages/meeting-view/MeetingView'
import { DiffusionMessage } from '../apis/api-egg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MeetingRoom } from '../pages/meeting-view/api'
import { NotificationWithCross } from '../components/NotificationWithCross'
import { useLocation } from 'react-router-dom'
import { useUI } from './UIContext'
import { Pip } from '../components/Pip'

const Context = createContext({} as ZoomContextValue)
Context.displayName = 'ZoomContext'

const zoomClient = ZoomVideo.createClient()

export const ZoomProvider = ({ children }: { children: ReactNode }) => {
    const [isStartedScreenShare, setIsStartedScreenShare] = useState(false)
    const [currentUserInfo, setCurrentUserInfo] = useState<Participant | undefined>(undefined)
    const [currentSessionInfo, setCurrentSessionInfo] = useState<SessionInfo | undefined>(undefined)
    const [infoText, setInfoText] = useState<string | null>(null)
    const [isStartingVideo, setIsStartingVideo] = useState(false)
    const [sidebarMode, setSidebarMode] = useState<SidebarModeType>(null)
    const [chatClient, setChatClient] = useState<ChatClient | null>(null)
    const [commandChannel, setCommandChannel] = useState<CommandChannelClient | null>(null)
    const [recordingClient, setRecordingClient] = useState<RecordingClient | null>(null)
    const [loggerClient, setLoggerClient] = useState<typeof LoggerClient | null>(null)
    const [unReadMessages, setUnReadMessages] = useState<UnreadMessageType>(false)
    const [isVideoStarted, setIsVideoStarted] = useState(false)
    const [isAudioStarted, setIsAudioStarted] = useState(false)
    const [mediaStreamGlobal, setMediaStreamGlobal] = useState<MediaStream | undefined>(undefined)
    const [isHost, setIsHost] = useState<boolean>(false)
    const [isRecording, setIsRecording] = useState(false)
    const [confirmationDialog, setConfirmationDialog] = useState<keyof typeof ConfirmationDialogComponents | undefined>(
        undefined
    )
    const [diffusion, setDiffusion] = useState(getContent)
    const [raisedHands, setRaisedHands] = useState<RaiseHandItem[]>([])

    const { showToast, pip } = useUI()
    /**
     *
     * Raise the user's hand
     *
     * @param userId? If not provided, the current user's hand will be raised.
     * @returns
     */
    const toggleRaiseHand = useCallback(
        (userId?: number, options?: RaiseHandOptions) => {
            if (!userId) userId = currentUserInfo?.userId
            if (!userId) return console.error('No user id provided')

            const { notify = true, ...rest } = options ?? {}

            const isRaised = raisedHands.some(item => item.userId === userId)

            if (isRaised)
                setRaisedHands(old =>
                    old
                        .filter(item => item.userId !== userId)
                        .sort((a, b) => a.order - b.order)
                        .map((item, index) => ({ ...item, order: index + 1 }))
                )
            // @ts-ignore
            else setRaisedHands(old => [...old, { userId, order: old.length + 1 }])

            if (!notify) return

            commandChannel?.send(
                JSON.stringify({ type: 'toggle-raise-hand', userId, isRaised: !isRaised, notify, ...rest })
            )

            const id = `${userId}`
            if (userId === currentUserInfo?.userId && !isRaised)
                showToast(<NotificationWithCross id={id}>Has levantado la mano</NotificationWithCross>, {
                    id,
                    icon: <FontAwesomeIcon icon={['far', 'hand']} style={{ color: '#00A3FF' }} />,
                })
        },
        [commandChannel, currentUserInfo?.userId, raisedHands, showToast]
    )

    const isUserHandRaised = useMemo(
        () => (userId: number) => raisedHands.some(item => item.userId === userId),
        [raisedHands]
    )
    const IRaisedMyHand = isUserHandRaised(currentUserInfo?.userId ?? 0)

    const getHandStatus = useCallback(
        (userId: number) => {
            const hand = raisedHands.find(item => item.userId === userId)

            if (hand)
                return {
                    isHandRaised: true,
                    ...hand,
                }

            return {
                isHandRaised: false,
                userId,
                order: -1,
            }
        },
        [raisedHands]
    )

    const clearInfo = () => setInfoText(null)

    const showInfo = (text: string) => {
        setInfoText(text)
        setTimeout(clearInfo, 5000)
    }
    const { pathname } = useLocation()

    useEffect(() => {
        if (pathname.includes('post-meeting')) setIsStartedScreenShare(false)
    }, [pathname])

    useEffect(() => setContent(diffusion), [diffusion])

    const isHostOrManager = (currentUserInfo?.isHost || currentUserInfo?.isManager) ?? false

    const props: ZoomContextValue = {
        isStartedScreenShare,
        setIsStartedScreenShare,
        currentUserInfo,
        setCurrentUserInfo,
        currentSessionInfo,
        setCurrentSessionInfo,
        infoText,
        showInfo,
        setInfoText,
        clearInfo,
        isStartingVideo,
        setIsStartingVideo,
        zoomClient,
        sidebarMode,
        setSidebarMode,
        chatClient,
        setChatClient,
        commandChannel,
        setCommandChannel,
        recordingClient,
        setRecordingClient,
        unReadMessages,
        setUnReadMessages,
        loggerClient,
        setLoggerClient,
        isVideoStarted,
        isAudioStarted,
        setIsVideoStarted,
        setIsAudioStarted,
        mediaStreamGlobal,
        setMediaStreamGlobal,
        isHost,
        setIsHost,
        isHostOrManager,
        isRecording,
        setIsRecording,
        confirmationDialog,
        setConfirmationDialog,
        diffusion,
        setDiffusion,
        raisedHands,
        IRaisedMyHand,
        isUserHandRaised,
        toggleRaiseHand,
        getHandStatus,
    }

    return (
        <Context.Provider value={props}>
            {children}
            {pip?.src && <Pip {...pip} />}
        </Context.Provider>
    )
}

export const useZoomContext = () => useContext(Context)

export interface ZoomContextValue {
    isStartedScreenShare: boolean
    setIsStartedScreenShare: Dispatch<SetStateAction<boolean>>
    currentUserInfo?: Participant
    setCurrentUserInfo: Dispatch<SetStateAction<Participant | undefined>>
    currentSessionInfo?: SessionInfo
    setCurrentSessionInfo: Dispatch<SetStateAction<SessionInfo | undefined>>
    infoText: string | null
    setInfoText: Dispatch<SetStateAction<string | null>>
    showInfo: (text: string) => void
    clearInfo: () => void
    isStartingVideo: boolean
    setIsStartingVideo: Dispatch<SetStateAction<boolean>>
    zoomClient: ZoomClient
    sidebarMode: SidebarModeType
    setSidebarMode: Dispatch<SetStateAction<SidebarModeType>>
    chatClient: ChatClient | null
    setChatClient: Dispatch<SetStateAction<ChatClient | null>>
    commandChannel: CommandChannelClient | null
    setCommandChannel: Dispatch<SetStateAction<CommandChannelClient | null>>
    recordingClient: RecordingClient | null
    setRecordingClient: Dispatch<SetStateAction<RecordingClient | null>>
    unReadMessages: UnreadMessageType
    setUnReadMessages: Dispatch<SetStateAction<UnreadMessageType>>
    loggerClient: typeof LoggerClient | null
    setLoggerClient: Dispatch<SetStateAction<typeof LoggerClient | null>>
    isVideoStarted: boolean
    isAudioStarted: boolean
    setIsVideoStarted: Dispatch<SetStateAction<boolean>>
    setIsAudioStarted: Dispatch<SetStateAction<boolean>>
    mediaStreamGlobal: MediaStream | undefined
    setMediaStreamGlobal: Dispatch<SetStateAction<MediaStream | undefined>>
    isHost: boolean
    setIsHost: Dispatch<SetStateAction<boolean>>
    isHostOrManager: boolean
    isRecording: boolean
    setIsRecording: Dispatch<SetStateAction<boolean>>
    confirmationDialog: ReactNode | undefined
    setConfirmationDialog: Dispatch<SetStateAction<keyof typeof ConfirmationDialogComponents | undefined>>
    diffusion: DiffusionMessage[]
    setDiffusion: Dispatch<SetStateAction<DiffusionMessage[]>>
    raisedHands: RaiseHandItem[]
    IRaisedMyHand: boolean
    isUserHandRaised: (userId: number) => boolean
    toggleRaiseHand: (userId?: number, options?: RaiseHandOptions) => void
    getHandStatus: (userId: number) => RaiseHandItem & { isHandRaised: boolean }
}

export type SidebarModeType = 'messages' | 'widget' | 'participants' | null

export interface MeetingParams {
    topic: string
    zoomToken: string
    widgetToken: string
    name: string
    room?: MeetingRoom
    generalToken?: string
    teamsToken?: string
    _algorithmId?: string
}

export const getContent = () => JSON.parse(sessionStorage.getItem('content') ?? '[]') as DiffusionMessage[]

export const setContent = (contents: DiffusionMessage[]) => sessionStorage.setItem('content', JSON.stringify(contents))

export const addContent = (content: DiffusionMessage) => setContent([...getContent(), content])

type UnreadMessageType = { type: 'chat' | 'diffusion'; status: true } | false

interface RaiseHandItem {
    userId: number
    order: number
}

interface RaiseHandOptions {
    force?: boolean
    notify?: boolean
}
