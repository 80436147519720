import { forwardRef, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaDevice } from '../../types/video-types'
import styled from 'styled-components'

type Props = {
    menuItems: MediaDeviceType[]
    onMenuItemClick: (item: any) => void
    activeDevices?: (string | undefined)[]
    onClose?: () => void
}

export type MediaDeviceType = Omit<MediaDevice, 'deviceId'> & {
    deviceId?: string
    key?: 'mirror' | 'statistic' | 'blur'
}

const DropdownMenu = ({ menuItems, onMenuItemClick, activeDevices = [], onClose }: Props) => {
    const microphones = menuItems.filter(({ type }) => type === 'microphone')
    const speakers = menuItems.filter(({ type }) => type === 'speaker')
    const cameras = menuItems.filter(({ type }) => type === 'camera')
    const options = menuItems.filter(({ type }) => type === 'option')
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) =>
            ref.current && !ref.current.contains(event.target as Node) && onClose?.()

        document.addEventListener('click', handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [onClose])

    if (!menuItems.length)
        return (
            <DropdownStyled visible ref={ref}>
                <span>
                    <strong>Cargando...</strong>
                </span>
            </DropdownStyled>
        )

    const getDevicesList = (devices: MediaDeviceType[]) =>
        devices.map(({ deviceId, type, ...rest }, index) => {
            const isActive = activeDevices.includes(deviceId)
            if (type !== 'option') {
                deviceId = deviceId?.split('-')[0]
            }
            return (
                <li key={deviceId ?? index}>
                    <StyledButton
                        className={`${isActive ? 'active' : ''}`}
                        onClick={() => onMenuItemClick({ ...rest, deviceId, type })}
                    >
                        <div
                            style={{
                                textAlign: 'left',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            {rest.label}
                            {isActive && <FontAwesomeIcon icon={['fas', 'check']} />}
                        </div>
                    </StyledButton>
                </li>
            )
        })

    return (
        <DropdownStyled visible ref={ref}>
            <ul>
                {microphones && microphones.length > 0 && (
                    <div className="devices-group">
                        <div className="dropdown-label">
                            <span>
                                <strong>Micrófonos</strong>
                            </span>
                        </div>
                        {getDevicesList(microphones)}
                    </div>
                )}
                {speakers && speakers.length > 0 && (
                    <div className="devices-group">
                        <div className="dropdown-label">
                            <span>
                                <strong>Altavoces</strong>
                            </span>
                        </div>
                        {getDevicesList(speakers)}
                    </div>
                )}
                {cameras && cameras.length > 0 && (
                    <div className="devices-group">
                        <div className="dropdown-label">
                            <span>
                                <strong>Cámaras</strong>
                            </span>
                        </div>
                        {getDevicesList(cameras)}
                    </div>
                )}
                {options?.length > 0 && (
                    <div className="devices-group">
                        <div className="dropdown-label">
                            <span>
                                <strong>Opciones</strong>
                            </span>
                        </div>
                        {getDevicesList(options)}
                    </div>
                )}
            </ul>
        </DropdownStyled>
    )
}

export default DropdownMenu

type DropdownProps = {
    visible: boolean
    children: React.ReactNode
}

export const DropdownStyled = forwardRef<HTMLDivElement, DropdownProps>((props, ref) => (
    <DropdownStyledComponent {...props} ref={ref} />
))

const DropdownStyledComponent = styled.div<DropdownProps>`
    background-color: ${props => props.theme.color.midnightBlack};
    border: 1px solid ${props => props.theme.color.slateGray};
    color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    z-index: 1000;
    display: ${props => (props.visible ? 'block' : 'none')};
    width: 450px;
    position: absolute;
    bottom: 30px;
    right: -175px;
    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        border-width: 10px;
        border-style: solid;
        border-color: ${props => props.theme.color.midnightBlack} transparent transparent transparent;
    }
    ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: flex-start !important;
        .devices-group {
            width: 100%;
            .dropdown-label {
                padding: 10px;
            }
            li {
                overflow: hidden;
                box-sizing: border-box;
                width: 100%;
                padding: 7px;
                &:not(:last-child) {
                    border-bottom: ${props => `1px solid ${props.theme.color.slateGray}`};
                    border-right: none;
                }
            }
        }
    }
    strong {
        font-size: 15px;
    }
`

export const StyledButton = styled.button`
    width: 100% !important;
    font-size: 13px !important;
    background-color: none;
    background: none;
    border: none;
    cursor: pointer;
    color: white !important;
    &:hover {
        font-weight: bold;
    }
    &.active {
        font-weight: bold;
        color: ${props => props.theme.color.electricBlue} !important;
    }
`
