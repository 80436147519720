import { useCallback, useEffect, useMemo, useState } from 'react'

import { Participant } from '../../types/video-types'
import { useZoomContext } from '../../../../context/ZoomContext'

export const useParticipants = () => {
    const { zoomClient, currentUserInfo, setIsHost, getHandStatus } = useZoomContext()
    const [participants, setParticipants] = useState<Participant[]>([])
    const [searchQuery, setSearchQuery] = useState<string>('')

    const updateParticipants = useCallback((updatedParticipants: Partial<Participant> | Partial<Participant>[]) => {
        setParticipants(prev => {
            const updated = Array.isArray(updatedParticipants) ? updatedParticipants : [updatedParticipants]
            return prev.map(p => {
                const update = updated.find(u => u.userId === p.userId)
                return update ? { ...p, ...update } : p
            })
        })
    }, [])

    const addParticipants = useCallback((newParticipants: Participant | Participant[]) => {
        setParticipants(prev => {
            const toAdd = Array.isArray(newParticipants) ? newParticipants : [newParticipants]
            return [...prev, ...toAdd.filter(p => !prev.some(existing => existing.userId === p.userId))]
        })
    }, [])

    const removeParticipants = useCallback((removedParticipants: Participant | Participant[]) => {
        setParticipants(prev => {
            const toRemove = Array.isArray(removedParticipants) ? removedParticipants : [removedParticipants]
            return prev.filter(p => !toRemove.some(removed => removed.userId === p.userId))
        })
    }, [])

    const searchParticipants = (query: string) => {
        setSearchQuery(query.trim())
    }

    useEffect(() => {
        if (zoomClient) {
            // Call zoomClient.getAllUser() only once
            setParticipants(zoomClient.getAllUser())
            setIsHost(zoomClient.getCurrentUserInfo()?.isHost)
            zoomClient.on('user-added', addParticipants)
            zoomClient.on('user-removed', removeParticipants)
            zoomClient.on('user-updated', updateParticipants)

            return () => {
                zoomClient.off('user-added', addParticipants)
                zoomClient.off('user-removed', removeParticipants)
                zoomClient.off('user-updated', updateParticipants)
            }
        }
    }, [zoomClient, setIsHost, addParticipants, removeParticipants, updateParticipants])

    const filteredParticipants = useMemo(() => {
        let newParticipants = [...participants]
        if (searchQuery)
            newParticipants = [...participants].filter(participant =>
                participant.displayName?.toLowerCase().includes(searchQuery.toLowerCase())
            )

        return [...newParticipants].sort((a, b) => {
            // Sort by Me
            if (a.userId === currentUserInfo?.userId) return -1
            if (b.userId === currentUserInfo?.userId) return 1

            // Sort by Host
            if (a.isHost && !b.isHost) return -1
            if (!a.isHost && b.isHost) return 1

            // Sort by Raised Hands
            const aStatus = getHandStatus(a.userId)
            const bStatus = getHandStatus(b.userId)
            if (aStatus.isHandRaised && bStatus.isHandRaised) return aStatus.order - bStatus.order
            if (aStatus.isHandRaised && !bStatus.isHandRaised) return -1
            if (!aStatus.isHandRaised && bStatus.isHandRaised) return 1

            // Sort by Muted
            if (a.muted && !b.muted) return 1
            if (!a.muted && b.muted) return -1

            // Sort by Name
            return a.displayName?.localeCompare(b.displayName ?? '') ?? 0
        })
    }, [currentUserInfo?.userId, getHandStatus, participants, searchQuery])

    // Calculate total number of participants
    const totalParticipants = useMemo(() => participants.length, [participants])

    return {
        participants: filteredParticipants,
        searchParticipants,
        totalParticipants,
    }
}
