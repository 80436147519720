import { GetTimelineResponse, getTimeline, updateTimelineEvent as mutationFn } from '../apis/api-egg'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { useSessionParams } from './useSessionParams'

export const useTimeline = () => {
    const { _classId } = useSessionParams()
    const queryClient = useQueryClient()
    const queryKey = _classId ? ['Course', 'Timeline', _classId] : []

    const { data, ...rest } = useQuery({
        queryKey,
        queryFn: () => getTimeline(_classId),
        enabled: !!_classId,
        staleTime: 1000 * 60 * 60, // 1 hour
        retry: 3,
    })

    const { mutate: updateTimelineEvent } = useMutation({
        mutationKey: queryKey,
        mutationFn,
        onMutate: ({ event }) => {
            const snapshot = queryClient.getQueryData<GetTimelineResponse['data']>(queryKey)

            snapshot &&
                queryClient.setQueryData(queryKey, {
                    ...snapshot,
                    currentEvent: {
                        ...snapshot.currentEvent,
                        ...event,
                        settings: {
                            ...snapshot.currentEvent?.settings,
                            ...event.settings,
                        },
                    },
                })

            return snapshot
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
        onError: (_error, _variables, snapshot) => queryClient.setQueryData(queryKey, snapshot),
    })

    return {
        ...data,
        ...rest,
        updateTimelineEvent,
    }
}
