import { useEffect, useState } from 'react'

import DayJS from '../helpers/DayJS'

export const CountDown = ({ duration, ...rest }: { duration: number; format?: string }) => {
    const [timer, setTimer] = useState(duration)

    useEffect(() => {
        setTimer(duration)
    }, [duration])

    useEffect(() => {
        const interval = setInterval(() => setTimer(prev => prev - 1000), 1000)
        return () => clearInterval(interval)
    }, [])

    if (timer <= 0) return null

    const format = rest.format ? rest.format : timer > 3600000 ? 'HH:mm:ss' : 'mm:ss'

    return <span>{DayJS(timer).format(format)}</span>
}
