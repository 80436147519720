import { IconName, IconPrefix } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

type Props = {
    icon: [IconPrefix, IconName]
    iconStyle?: Record<any, any>
    msg: string
}

const ToastNotification = ({ msg, icon, iconStyle }: Props) => {
    const [prefix, name] = icon

    return (
        <StyledNotification>
            <FontAwesomeIcon icon={[prefix, name]} className="icon" style={{ ...iconStyle }} />
            <span>{msg}</span>
        </StyledNotification>
    )
}

export default ToastNotification

const StyledNotification = styled.div`
    display: flex;
    align-items: center;
    .icon {
        margin-right: 5px;
        color: #48a0f8;
    }
`
