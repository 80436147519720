import { getSessionParameters } from '../../../helpers/urls'
import { useState } from 'react'

export const useQrCode = () => {
    const [qrURL, setQrURL] = useState<string | null>(null)
    const urlParams = getSessionParameters()
    const encodedParams = new URLSearchParams(urlParams as Record<string, string>).toString()

    const onOpen = () => setQrURL(`${window.location.origin}/meeting?${encodedParams}`)
    const onRequestClose = () => setQrURL(null)

    return {
        qrURL,
        onOpen,
        onRequestClose,
    }
}
