import { Participant } from '../types/video-types'
import { SharePermissionType } from '../components/VideoFooter/ScreenShare'
import ToastNotification from '../components/Misc/ToastNotification'
import { setSessionItem } from '../../../helpers/localStorage'
import { useSessionParams } from '../../../hooks/useSessionParams'
import { useTimeline } from '../../../hooks/useTimeline'
import { useUI } from '../../../context/UIContext'
import { useZoomContext } from '../../../context/ZoomContext'

export const useHostFunctions = () => {
    const zoom = useZoomContext()
    const { zoomClient, mediaStreamGlobal: mediaStream, commandChannel, setSidebarMode } = zoom
    const { showToast, showError } = useUI()
    const { currentEvent, updateTimelineEvent } = useTimeline()
    const { _classId: courseId } = useSessionParams()

    const enableMic = () => {
        if (!courseId) return
        updateTimelineEvent({ courseId, event: { ...currentEvent, settings: { enabledMic: true } } })
        commandChannel?.send(JSON.stringify({ type: 'enableMic' }))
        showToast(`Se habilitó el micrófono de los participantes`)
    }

    const disableMic = async () => {
        if (!courseId) return
        if (!mediaStream) {
            console.error('Media stream is null. Error on mute all participants')
            return
        }
        try {
            const allParticipants = zoomClient
                .getAllUser()
                .filter(participant => !participant.isHost || !participant.isManager)
            const mutePromises = allParticipants.map(participant => {
                return muteParticipant(participant)
                    .then(() => ({ status: 'fulfilled', participantId: participant.userId }))
                    .catch(error => ({
                        status: 'rejected',
                        participantId: participant.userId,
                        reason: error,
                    }))
            })
            await Promise.allSettled(mutePromises)
            showToast(`Todos los participantes han sido silenciados`)
            updateTimelineEvent({ courseId, event: { ...currentEvent, settings: { enabledMic: false } } })
            commandChannel?.send(JSON.stringify({ type: 'disableMic' }))
        } catch (error) {
            showToast('Error al silenciar a todos los participantes')
            throw new Error(`Error al silenciar a todos los participantes`)
        }
    }

    const muteParticipant = async (participant: Participant) => {
        try {
            const isMuted = await mediaStream?.isAudioMuted(participant.userId)
            if (isMuted || participant.audio === '') return
            await mediaStream?.muteAudio(participant.userId)
            commandChannel?.send(`Tu micrófono ha sido silenciado por el anfitrión`, participant.userId)
            showToast(`${participant.displayName} ha sido silenciado`, {
                id: participant.userGuid,
            })
            return `${participant.displayName} fue silenciado correctamente`
        } catch (error) {
            showError(`Error al silenciar a ${participant.displayName}`)
            throw new Error(`Error al silenciar a ${participant.displayName}: ${error}`)
        }
    }

    const makeHost = async (participant: Participant) => {
        try {
            await zoomClient?.makeHost(participant.userId).catch(() => {})
            commandChannel?.send(`Ahora eres el anfitrión`, participant.userId)
            showToast(`${participant.displayName} es ahora el anfitrión. Su estado de anfitrión ha cambiado`, {
                id: participant.userGuid,
            })
            setSidebarMode(null)
            return participant.displayName + ' es ahora el anfitrión'
        } catch (error) {
            showError(`El participante no pudo convertirse en anfitrión`)
            throw new Error(`Error al hacer anfitrión a ${participant.displayName}: ${error}`)
        }
    }

    const makeManager = async (participant: Participant) => {
        try {
            await zoomClient?.makeManager(participant.userId).catch(() => {})
            commandChannel?.send(`Ahora eres Co-anfitrión`, participant.userId)
            showToast(`${participant.displayName} es ahora el Co-anfitrión.`, {
                id: participant.userGuid,
            })
            setSidebarMode(null)
            return participant.displayName + ' es ahora el Co-anfitrión'
        } catch (error) {
            showError(`El participante no pudo convertirse en Co-anfitrión`)
            throw new Error(`Error al hacer Co-anfitrión a ${participant.displayName}: ${error}`)
        }
    }

    const removeUser = async (participant: Participant) => {
        try {
            await zoomClient?.removeUser(participant.userId)
            return participant.displayName + ' ha sido eliminado'
        } catch (error) {
            throw new Error(`Error al eliminar al participante ${participant.displayName}: ${error}`)
        }
    }

    const chatUser = (participant: Participant) => {
        setSidebarMode('messages')
        setSessionItem('receiverId', participant.userId)
    }

    const switchSharePermissions = (shareType: SharePermissionType) => {
        switch (shareType) {
            case SharePermissionType.SHARE_ALL:
                mediaStream?.setSharePrivilege(3)
                // commandChannel?.send(SharePermissionType.SHARE_ALL)
                showToast(
                    <ToastNotification
                        icon={['far', 'circle-info']}
                        iconStyle={{ color: '#48A0F8' }}
                        msg={'Todos los participantes pueden compartir pantalla'}
                    />,
                    {
                        id: shareType,
                    }
                )
                break
            case SharePermissionType.SHARE_COACH:
                mediaStream?.setSharePrivilege(1)
                // commandChannel?.send(SharePermissionType.SHARE_COACH)
                showToast(
                    <ToastNotification
                        icon={['far', 'circle-info']}
                        iconStyle={{ color: '#48A0F8' }}
                        msg={'Solo el Coach y Anfitriones pueden compartir pantalla'}
                    />,
                    {
                        id: shareType,
                    }
                )
                break
        }
    }

    return {
        enableMic,
        disableMic,
        muteParticipant,
        makeHost,
        makeManager,
        removeUser,
        chatUser,
        switchSharePermissions,
    }
}
