import { MeetingErrorType } from '../../components/Video/ErrorComponent'
import { QRCode } from '../../../../components/Modals/QRCode'
import { useQrCode } from '../useQrCode'
import { useZoomContext } from '../../../../context/ZoomContext'

export const useMeetingError = () => {
    const { onOpen, ...rest } = useQrCode()
    const { setSidebarMode } = useZoomContext()

    const handleError = ({ errorType, payload }: MeetingErrorType) => {
        setSidebarMode(null)
        return { errorType, payload }
    }

    return {
        handleError,
        QRCode: () => <QRCode {...rest} />,
    }
}
