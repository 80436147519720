import { Api } from './apis'
import Axios from 'axios'

const { REACT_APP_EGG_API_SIGNUP } = process.env

export const ApiSignUp = Axios.create({
    baseURL: REACT_APP_EGG_API_SIGNUP,
})

export const getCourseSettings = (courseId: string) =>
    ApiSignUp.get<Api<GetCourseSettings>>(`/v1/courses/${courseId}/settings`).then(({ data }) => data.data)

interface GetCourseSettings {
    id: string
    settings: {
        hasSingleViewMeeting: boolean
    }
}
