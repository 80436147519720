import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PrimaryBtnInverted } from '../../../../components/Buttons'
import styled from 'styled-components'

interface LeaveButtonProps {
    onClick: () => void
}

export const LeaveButton = (props: LeaveButtonProps) => (
    <LeaveButtonStyled {...props}>
        <FontAwesomeIcon className="icon" icon={['fas', 'phone-hangup']} />
    </LeaveButtonStyled>
)

export const LeaveButtonStyled = styled(PrimaryBtnInverted)`
    font-size: 12px !important;
    margin: 0;
    height: 45px !important;
    background-color: ${props => props.theme.color.errors} !important;
    color: white !important;
    transition: 0.1s ease-in-out;
    &:hover {
        background-color: #bf4558 !important;
        color: #b2b2b2 !important;
    }
    .icon {
        color: white;
        font-size: 15px;
    }
`
