import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '../../../../components/Tooltip/Tooltip.component'
import { useSessionParams } from '../../../../hooks/useSessionParams'
import { useZoomContext } from '../../../../context/ZoomContext'

export const RaiseHand = () => {
    const { IRaisedMyHand, toggleRaiseHand } = useZoomContext()
    const { role } = useSessionParams()

    if (role !== 'student') return null

    return (
        <div style={{ display: 'flex' }}>
            <Tooltip text={IRaisedMyHand ? 'Bajar la mano' : 'Levantar la mano'} placement="topCenter">
                <button onClick={() => toggleRaiseHand()}>
                    <FontAwesomeIcon icon={['far', 'hand']} />
                </button>
            </Tooltip>
        </div>
    )
}
