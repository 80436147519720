import React, { useEffect, useRef } from 'react'

import { Participant } from '../../pages/meeting-view/types/video-types'
import { VIDEO_FOOTER_ID } from '../../pages/meeting-view/util/video-constants'
import styled from 'styled-components'
import { useHostFunctions } from '../../pages/meeting-view/hooks/useHostFunctions'
import { useZoomContext } from '../../context/ZoomContext'

type Props = {
    participant: Participant
    setShowOptions: (val: boolean) => void
    isHost: boolean
}

const ParticipantOptions = ({ participant, setShowOptions, isHost }: Props) => {
    const { isUserHandRaised, toggleRaiseHand } = useZoomContext()
    const { makeHost, makeManager, removeUser, chatUser } = useHostFunctions()
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const footerElement = document.getElementById(VIDEO_FOOTER_ID)

            if (footerElement && footerElement.contains(event.target as Node)) return // Do nothing if the click is inside the footer
            if (ref.current && !ref.current.contains(event.target as Node)) setShowOptions(false)
        }

        document.addEventListener('click', handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [setShowOptions])

    return (
        <ParticipantOptionsStyled ref={ref}>
            <ul>
                {isHost && !participant.isHost && (
                    <li>
                        <button onClick={() => makeHost(participant)}>Hacer anfitrión</button>
                    </li>
                )}
                {isHost && !participant.isManager && (
                    <li>
                        <button onClick={() => makeManager(participant)}>Hacer Co-anfitrión</button>
                    </li>
                )}
                <li>
                    <button onClick={() => chatUser(participant)}>Chat</button>
                </li>
                {isHost && (
                    <>
                        {isUserHandRaised(participant.userId) && (
                            <li>
                                <button onClick={() => toggleRaiseHand(participant.userId, { force: true })}>
                                    Bajar la mano
                                </button>
                            </li>
                        )}
                        <li>
                            <button onClick={() => removeUser(participant)} className="danger">
                                Eliminar
                            </button>
                        </li>
                    </>
                )}
            </ul>
        </ParticipantOptionsStyled>
    )
}

export default ParticipantOptions

const ParticipantOptionsStyled = styled.div`
    position: absolute;
    top: 50%;
    right: calc(100% + 3px); /* Adjust as needed */
    transform: translateY(-50%);
    background-color: ${props => props.theme.color.sidebar.gray1};
    border: 1px solid ${props => props.theme.color.sidebar.gray2};
    border-radius: 12px;
    padding: 5px;
    z-index: 999;
    padding: 8px 15px;
    box-sizing: border-box;
    width: 180px;
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            padding: 8px 0;
            button {
                background: none;
                border: none;
                font-weight: 600;
                color: white;
                &:hover {
                    color: ${props => props.theme.color.darkGray};
                    cursor: pointer;
                }
                &.danger {
                    color: ${props => props.theme.color.salmon};
                    &:hover {
                        color: ${props => props.theme.color.salmonBorders};
                    }
                }
            }
            &:not(:last-child) {
                border-bottom: 1px solid ${props => props.theme.color.lightGray};
            }
        }
    }
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: -10px; /* Adjust as needed */
        transform: translateY(-50%);
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent ${props => props.theme.color.sidebar.gray1};
    }
`
