import { useCallback, useEffect, useState } from 'react'

import type { NetworkQuality } from '@zoom/videosdk'
import { ZoomClient } from '../types/video-types'
import produce from 'immer'

export function useNetworkQuality(zoomClient: ZoomClient) {
    const [networkQuality, setNetworkQuality] = useState<Record<string, NetworkQuality>>({})
    const onNetworkChange = useCallback((payload: any) => {
        const { userId, type, level } = payload
        setNetworkQuality(
            produce(draft => {
                if (draft[`${userId}`] !== undefined) {
                    if (type === 'uplink') {
                        draft[`${userId}`].uplink = level
                    } else {
                        draft[`${userId}`].downlink = level
                    }
                } else {
                    Object.assign(draft, {
                        [`${userId}`]: {
                            [`${type}`]: level,
                        },
                    })
                }
            })
        )
    }, [])

    // useEffect(() => {
    //     zoomClient.on('network-quality-change', onNetworkChange)
    //     return () => {
    //         zoomClient.off('network-quality-change', onNetworkChange)
    //     }
    // }, [zoomClient, onNetworkChange])
    return networkQuality
}
