import { getCourseSettings } from '../apis/api-signup'
import { useQuery } from '@tanstack/react-query'
import { useSessionParams } from './useSessionParams'

export const useSettings = () => {
    const { _classId = '' } = useSessionParams()
    const queryKey = ['Course', 'Settings', _classId]

    const queryFn = () => getCourseSettings(_classId)

    const { data, ...rest } = useQuery({
        queryKey,
        queryFn,
        staleTime: Infinity,
        retry: 2,
    })

    const { settings, ...restData } = data ?? {}

    return {
        settings: (settings ?? {}) as NonNullable<typeof settings>,
        ...restData,
        ...rest,
    }
}
