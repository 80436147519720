import './video.scss'
import './video.scss'

import { CaptureVideoOption, VideoCapturingState, VideoQuality } from '@zoom/videosdk'
import { MediaStream, Participant, ZoomClient } from '../../types/video-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Avatar from '../../../../components/AvatarZoom'
import { MediaContext } from '../../types/video-types'
import ReactGA from 'react-ga4'
import { SELF_VIDEO_ID } from '../../util/video-constants'
import _ from 'lodash'
import { getItem } from '../../../../helpers/localStorage'
import { isShallowEqual } from '../../util/util'
import { useCanvasDimension } from '../../hooks/useCanvasDimension'
import { useLocalVolume } from '../../hooks/useLocalVolume'
import { useNetworkQuality } from '../../hooks/useNetworkQuality'
import { usePrevious } from '../../hooks/usePrevious'
import { useShare } from '../../hooks/useShare'
import { useSizeCallback } from '../../hooks/useSizeCallback'
import { useZoomContext } from '../../../../context/ZoomContext'
import { useParticipants } from '../Participants/useParticipants'

type Props = {
    infoText?: string | null
    zoomClient: ZoomClient
    mediaContext: MediaContext
    selfShareRef: any
}

const VideoSingle = ({ infoText, zoomClient, mediaContext, selfShareRef }: Props) => {
    const {
        mediaStream,
        video: { decode: isVideoDecodeReady },
    } = mediaContext

    const videoRef = useRef<HTMLCanvasElement | null>(null)
    const shareRef = useRef<HTMLCanvasElement | null>(null)
    const shareContainerRef = useRef<HTMLDivElement | null>(null)

    const [participants, setParticipants] = useState<Participant[]>([])
    const [activeVideo, setActiveVideo] = useState<number>(mediaStream?.getActiveVideoId() ?? 0)
    const [isSoloParticipant, setIsSoloParticipant] = useState(false)
    const previousActiveUser = useRef<Participant>()
    const canvasDimension = useCanvasDimension(mediaStream, videoRef)
    const { userVolumeList, setLocalVolume } = useLocalVolume(mediaStream as MediaStream)
    const networkQuality = useNetworkQuality(zoomClient)
    const { setIsVideoStarted, currentUserInfo } = useZoomContext()
    const previousCanvasDimension = usePrevious(canvasDimension)
    const {
        isRecieveSharing,
        isStartedShare: isSendSharing,
        sharedContentDimension,
    } = useShare(zoomClient, mediaStream, shareRef)
    const [containerDimension, setContainerDimension] = useState({
        width: 0,
        height: 0,
    })
    const [shareViewDimension, setShareViewDimension] = useState({
        width: 0,
        height: 0,
    })

    const { totalParticipants, participants: changedParticipants } = useParticipants()
    useEffect(() => {
        setIsSoloParticipant(totalParticipants === 1)
    }, [totalParticipants])

    useEffect(() => {
        setParticipants(changedParticipants)
    }, [changedParticipants])

    const isSharing = isRecieveSharing || isSendSharing

    const onShareContainerResize = useCallback(({ width, height }: { width: number; height: number }) => {
        _.throttle(() => {
            setContainerDimension({ width, height })
        }, 50)()
    }, [])

    useSizeCallback(shareContainerRef.current, onShareContainerResize)

    useEffect(() => {
        if (!isShallowEqual(shareViewDimension, sharedContentDimension)) {
            mediaStream?.updateSharingCanvasDimension(shareViewDimension.width, shareViewDimension.height)
        }
    }, [mediaStream, sharedContentDimension, shareViewDimension])

    const activeUser = useMemo(
        () => participants.find(user => user.userId === activeVideo),
        [participants, activeVideo]
    )

    useEffect(() => {
        if (mediaStream && videoRef.current && isVideoDecodeReady && activeUser?.userId !== currentUserInfo?.userId) {
            const handleVideoRendering = () => {
                if (activeUser?.bVideoOn) {
                    mediaStream.renderVideo(
                        videoRef.current!,
                        activeUser.userId,
                        canvasDimension.width,
                        canvasDimension.height,
                        0,
                        0,
                        VideoQuality.Video_360P as any
                    )
                } else if (previousActiveUser.current?.bVideoOn) {
                    mediaStream.stopRenderVideo(videoRef.current!, previousActiveUser.current?.userId)
                }
            }

            const handleVideoPositioning = () => {
                if (activeUser?.bVideoOn && previousActiveUser.current?.bVideoOn) {
                    if (activeUser.userId !== previousActiveUser.current.userId) {
                        mediaStream.stopRenderVideo(videoRef.current!, previousActiveUser.current?.userId)
                        mediaStream.renderVideo(
                            videoRef.current!,
                            activeUser.userId,
                            canvasDimension.width,
                            canvasDimension.height,
                            0,
                            0,
                            VideoQuality.Video_360P as any
                        )
                    } else if (!isShallowEqual(canvasDimension, previousCanvasDimension)) {
                        mediaStream.adjustRenderedVideoPosition(
                            videoRef.current!,
                            activeUser.userId,
                            canvasDimension.width,
                            canvasDimension.height,
                            0,
                            0
                        )
                    }
                }
            }

            handleVideoRendering()
            handleVideoPositioning()

            previousActiveUser.current = activeUser
        }
    }, [mediaStream, activeUser, isVideoDecodeReady, canvasDimension, previousCanvasDimension, currentUserInfo])

    const startVideo = useCallback(async () => {
        if (zoomClient.getCurrentUserInfo().bVideoOn) return
        try {
            const startVideoOptions: Partial<CaptureVideoOption> = {
                mirrored: true,
                hd: true,
                fullHd: true,
            }
            const vfx: string | undefined = getItem('vfx')
            if (mediaStream?.isSupportVirtualBackground() && vfx) {
                startVideoOptions.virtualBackground = {
                    imageUrl: vfx,
                }
            }

            await mediaStream?.startVideo(startVideoOptions)
            setIsVideoStarted(true)
        } catch (error: any) {
            setIsVideoStarted(false)
            ReactGA.event({
                category: 'Error',
                action: 'Error auto-starting video',
                label: error,
            })
        }
    }, [mediaStream, setIsVideoStarted, zoomClient])

    useEffect(() => {
        mediaStream && startVideo()
        ReactGA.event('Video Single rendered')
    }, [mediaStream, startVideo])

    const handleCaptureChange = useCallback(
        (payload: { state: VideoCapturingState }) => {
            const canvasSelfElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLCanvasElement
            if (canvasSelfElement) {
                if (payload.state === 'Stopped') {
                    mediaStream?.stopRenderVideo(canvasSelfElement, zoomClient.getSessionInfo().userId).catch(() => {
                        console.log('Error stopping render self video on video single')
                    })
                } else if (payload.state === 'Started') {
                    mediaStream
                        ?.renderVideo(canvasSelfElement, zoomClient.getSessionInfo().userId, 450, 253, 0, 0, 3)
                        .catch(() => console.log('Error rendering self video on video single'))
                }
            }
        },
        [mediaStream, zoomClient]
    )

    const onActiveVideoChange = useCallback(
        (payload: any) => {
            const { userId } = payload
            if (userId !== currentUserInfo?.userId) {
                setActiveVideo(userId)
            }
        },
        [currentUserInfo]
    )

    useEffect(() => {
        zoomClient.on('video-active-change', onActiveVideoChange)
        zoomClient.on('video-capturing-change', handleCaptureChange)
        return () => {
            zoomClient.off('video-active-change', onActiveVideoChange)
            zoomClient.off('video-capturing-change', handleCaptureChange)
        }
    }, [handleCaptureChange, onActiveVideoChange, zoomClient])

    useEffect(() => {
        if (isSharing && shareContainerRef.current) {
            const { width, height } = sharedContentDimension
            const { width: containerWidth, height: containerHeight } = containerDimension
            const ratio = Math.min(containerWidth / width, containerHeight / height, 1)
            setShareViewDimension({
                width: Math.floor(width * ratio),
                height: Math.floor(height * ratio),
            })
        }
    }, [isSharing, sharedContentDimension, containerDimension])

    return (
        <div>
            {infoText && (
                <span
                    className="status-text"
                    style={{
                        backgroundColor: 'black',
                        color: 'white',
                        fontWeight: 'bold',
                        border: '1px solid black',
                        width: '100%',
                        padding: '5px',
                        boxSizing: 'border-box',
                        zIndex: 1000,
                        position: 'absolute',
                        top: 0,
                        textAlign: 'center',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                    }}
                >
                    {infoText}
                </span>
            )}
            <div className="viewportsingle">
                <div className={`share-container ${isSharing ? 'in-sharing' : ''}`} ref={shareContainerRef}>
                    <div
                        className="share-container-viewport"
                        style={{
                            width: `${shareViewDimension.width}px`,
                            height: `${shareViewDimension.height}px`,
                        }}
                    >
                        <canvas
                            id="them"
                            className={`share-canvas ${isSendSharing && !isRecieveSharing ? 'hidden' : ''}`}
                            ref={shareRef}
                        />
                        {mediaStream?.isStartShareScreenWithVideoElement() ? (
                            <video
                                id="self"
                                className={`share-canvas ${isRecieveSharing && !isSendSharing ? 'hidden' : ''}`}
                                ref={selfShareRef}
                            />
                        ) : (
                            <canvas
                                id="self"
                                className={`share-canvas ${isRecieveSharing && !isSendSharing ? 'hidden' : ''}`}
                                ref={selfShareRef}
                            />
                        )}
                    </div>
                </div>
                <div className={`video-container ${isSharing ? 'in-sharing' : ''}`}>
                    <canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} />
                    {mediaStream?.isRenderSelfViewWithVideoElement() ? (
                        <video id={SELF_VIDEO_ID} width="450" height="253" className={`self-video`} />
                    ) : (
                        <canvas id={SELF_VIDEO_ID} width="450" height="253" className={`self-video`} />
                    )}
                    {activeUser && activeUser.userId !== currentUserInfo?.userId && (
                        <Avatar
                            zoomClient={zoomClient}
                            participant={activeUser}
                            isActive={false}
                            className="single-view-avatar avatar-single"
                            volume={userVolumeList.find(u => u.userId === activeUser.userId)?.volume}
                            setLocalVolume={setLocalVolume}
                            networkQuality={networkQuality[`${activeUser.userId}`]}
                        />
                    )}
                    {isSoloParticipant && (
                        <div className="solo-participant-message">Esperando al resto de los participantes...</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default VideoSingle
