import ReactModal from 'react-modal'
import styled from 'styled-components'
import { ReactNode, useEffect, useRef } from 'react'
import { VIDEO_FOOTER_ID } from '../../pages/meeting-view/util/video-constants'
import { useZoomContext } from '../../context/ZoomContext'

export const ConfirmationDialog = ({ modal, isOpen }: { modal: ReactNode; isOpen: boolean }) => {
    const ref = useRef<any>(null)
    const { setConfirmationDialog } = useZoomContext()

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const footerElement = document.getElementById(VIDEO_FOOTER_ID)
            const modalContent = document.getElementsByClassName('ReactModal__Content')

            // Do nothing if the click is inside the footer
            if (footerElement && footerElement.contains(event.target as Node)) return

            // Prevent from closing when clicking over modalContent
            if (modalContent.length && modalContent[0].contains(event.target as Node)) return

            if (ref.current) setConfirmationDialog(undefined)
        }

        document.addEventListener('click', handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [setConfirmationDialog])

    const modalStyle = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
        },
    }

    return (
        <ReactModal ref={ref} className="react-modal-content" style={modalStyle} isOpen={isOpen}>
            {modal}
        </ReactModal>
    )
}

export default ConfirmationDialog

export const ConfirmationDialogStyled = styled.div`
    color: white;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    height: 100%;
    h2 {
        font-size: 20px;
        font-weight: 600;
    }
    .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 45px;
    }
    .actions {
        flex: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 45px;
        button {
            margin: 10px 0;
        }
    }
`
