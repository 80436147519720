import DropdownMenu from './Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaDevice } from '../../types/video-types'
import Tooltip from '../../../../components/Tooltip/Tooltip.component'
import styled from 'styled-components'
/* eslint-disable no-nested-ternary */
import { useState } from 'react'

interface MicrophoneButtonProps {
    isStartedAudio: boolean
    isMuted: boolean
    audio?: string
    onMicrophoneClick: () => void
    onMicrophoneMenuClick: (key: MediaDevice) => void
    microphoneList?: MediaDevice[]
    speakerList?: MediaDevice[]
    activeMicrophone?: string
    activeSpeaker?: string
}
const MicrophoneButton = (props: MicrophoneButtonProps) => {
    const {
        isStartedAudio,
        isMuted,
        audio,
        microphoneList = [],
        speakerList = [],
        activeMicrophone,
        activeSpeaker,
        onMicrophoneClick,
        onMicrophoneMenuClick,
    } = props

    const [dropdownVisible, setDropdownVisible] = useState(false)

    const menuItems = [...microphoneList, ...speakerList]

    const tooltipText = !microphoneList.length
        ? 'No hay micrófonos disponibles'
        : isMuted
        ? 'Iniciar Micrófono'
        : 'Mutear Micrófono'

    const toggleDropdown = () => setDropdownVisible(old => !old)

    return (
        <div>
            {isStartedAudio ? (
                <div style={{ display: 'flex' }}>
                    <Tooltip text={tooltipText} placement="topCenter">
                        <button onClick={onMicrophoneClick}>
                            {isMuted || !microphoneList.length ? (
                                <FontAwesomeIcon
                                    icon={['far', 'microphone-slash']}
                                    color={'#FF647C'}
                                />
                            ) : audio === 'phone' ? (
                                <span>Phone</span>
                            ) : (
                                <FontAwesomeIcon icon={['far', 'microphone']} />
                            )}
                        </button>
                    </Tooltip>
                    <Tooltip text="Opciones de audio" placement="topCenter">
                        <AngleButton onClick={toggleDropdown}>
                            <FontAwesomeIcon icon={['far', 'angle-up']} />
                        </AngleButton>
                    </Tooltip>
                    {dropdownVisible && !!menuItems.length && (
                        <DropdownMenu
                            menuItems={menuItems}
                            onMenuItemClick={onMicrophoneMenuClick}
                            onClose={() => setDropdownVisible(false)}
                            activeDevices={[`${activeMicrophone}-mic`, `${activeSpeaker}-speaker`]}
                        />
                    )}
                </div>
            ) : (
                <Tooltip text="Iniciar audio" placement="topCenter" open>
                    <button onClick={onMicrophoneClick}>
                        <FontAwesomeIcon icon={['far', 'volume-slash']} color={'#FF647C'} />
                    </button>
                </Tooltip>
            )}
        </div>
    )
}

export const AngleButton = styled.button`
    color: #4a4d52 !important;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    margin-left: 5px;
    margin-right: 5px;
    &:hover {
        color: white !important;
    }
`

export default MicrophoneButton
