import { ConnectionChangeReason } from '../meeting-view/hooks/useMediaContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Timer } from '../../components/Timer'
import { buildZoomLink } from '../meeting-view/util/util'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import { getSessionParameters } from '../../helpers/urls'
import styled from 'styled-components'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTimer } from '../meeting-view/hooks/useTimer'

type ReasonProps = {
    gralMeeting?: string | undefined
}

export const reasons: Record<ConnectionChangeReason, string> = {
    ended: '¿Necesitas un descanso?',
    'ended by host': 'La sesión ha sido finalizada por el Anfitrión',
    'expeled by host': 'Has sido expulsado de la sesión',
    'kicked by host': 'Has sido expulsado de la sesión',
    moving: 'Estás cambiando a otra sesión, espera un momento...',
}

export const Reason = ({ gralMeeting }: ReasonProps) => {
    const [searchParams] = useSearchParams()
    const reason = searchParams.get('reason') as ConnectionChangeReason
    const isGralMeeting = gralMeeting && reason === 'ended by host'
    const { room } = getSessionParameters()

    const roomName = room === 'general' ? 'general' : 'de equipo'

    const { start, time } = useTimer({
        initTime: isGralMeeting ? 10 : 300,
        onFinish: () => {
            isGralMeeting && window.open(buildZoomLink(gralMeeting || ''))
        },
    })

    useEffect(() => {
        start()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reason])

    const getReasonText = () => {
        if (isGralMeeting) {
            return (
                <>
                    <div className="icon rounded-icon">
                        <FontAwesomeIcon icon={['far', 'video']} />
                    </div>
                    <h1>¡Excelente! Ahora únete al encuentro general </h1>
                    <h2>
                        Regresa al encuentro general haciendo click en el botón o te redireccionaremos automáticamente
                        en:
                    </h2>
                    <Timer time={time} />
                </>
            )
        }

        if (!reason) {
            return (
                <>
                    <div className="icon rounded-icon">
                        <FontAwesomeIcon icon={['far', 'cup-togo']} />
                    </div>
                    <h1>¿Necesitas un descanso?</h1>
                    <h2>
                        Recuerda volver pronto para no disminuir tu participación.
                        <br />
                        Los otros participantes no podrán verte mientras te tomas un descanso.
                    </h2>
                    <Timer time={time} />
                </>
            )
        }

        if (reason === 'moving') {
            return (
                <>
                    <div className="icon rounded-icon">
                        <FontAwesomeIcon icon={['far', 'arrow-right']} style={{ color: '#00A3FF' }} />
                    </div>
                    <h1>Estás siendo enviado a la sala {roomName}, espera un momento...</h1>
                </>
            )
        }

        return <h1>{reasons[reason] ?? reasons.ended}</h1>
    }

    return <ReasonStyle>{getReasonText()}</ReasonStyle>
}

const ReasonStyle = styled.section`
    margin: 35px auto;
    & > h1 {
        font-size: 26px;
        font-weight: 600;
    }
    & > h2 {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        margin: 7px;
        max-width: 700px;
    }
    & > .icon {
        margin: 30px auto;
    }

    & > .timer {
        width: max-content;
        min-width: 110px;
        margin: 40px auto 0px auto;
        padding: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: ${defaultTheme.color.slateGray};
        border-radius: 14px;
        & > .icon {
            width: 22px;
            height: 100%;
        }
        & > p {
            padding: 0;
            margin: 0;
            font-size: 26px;
            font-weight: 600;
        }
    }
`
