export function isShallowEqual(objA: any, objB: any) {
    if (objA === objB) {
        return true
    }

    if (!objA || !objB) {
        return false
    }

    const aKeys = Object.keys(objA)
    const bKeys = Object.keys(objB)
    const len = aKeys.length

    if (bKeys.length !== len) {
        return false
    }

    for (let i = 0; i < len; i++) {
        const key = aKeys[i]

        if (objA[key] !== objB[key] || !Object.hasOwn(objB, key)) {
            return false
        }
    }

    return true
}

export function buildZoomLink(id: string) {
    // create a zoom link with id
    return `https://zoom.us/j/${id}`
}
