import './library.js'
import './styles/global.scss'
import './styles/redesign.scss'

import { AppRoutes } from './router/AppRoutes'
import DayJS from 'dayjs'
import LoadingPage from './pages/static/LoadingPage'
import ReactGA from 'react-ga4'
import { Suspense } from 'react'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from './styles/themes/defaultTheme'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

DayJS.extend(relativeTime)
DayJS.extend(duration)

const GoogleAnalyticsID = process.env.REACT_APP_GA_ID

const App = () => {
    if (GoogleAnalyticsID) {
        ReactGA.initialize([{ trackingId: GoogleAnalyticsID }])
    }

    return (
        <Suspense fallback={<LoadingPage />}>
            <ThemeProvider theme={defaultTheme}>
                <AppRoutes />
            </ThemeProvider>
        </Suspense>
    )
}

export default App
