import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../styles/themes/defaultTheme'
import DayJS from '../helpers/DayJS'

export const Timer = ({ time }: { time: number }) => {
    const remainingMinutes = DayJS.duration(time, 'seconds').format('m:ss')
    return (
        <TimerStyle>
            <FontAwesomeIcon icon={['far', 'clock']} className="icon" />
            <p>{remainingMinutes} m</p>
        </TimerStyle>
    )
}

const TimerStyle = styled.div`
    width: 140px;
    min-width: 110px;
    margin: 40px auto 0px auto;
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: ${defaultTheme.color.slateGray};
    border-radius: 14px;
    & > .icon {
        width: 22px;
        height: 100%;
    }
    & > p {
        padding: 0;
        margin: 0;
        font-size: 26px;
        font-weight: 600;
    }
`
