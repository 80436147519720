import { useCallback, useEffect, useState } from 'react'

import { VideoActiveState } from '@zoom/videosdk'
import { ZoomClient } from '../types/video-types'

export function useActiveSpeaker(zoomClient: ZoomClient) {
    const [activeSpeakerId, setActiveSpeaker] = useState(0)

    const onVideoActiveChange = useCallback(
        (payload: { state: VideoActiveState; userId: number }) => {
            const { userId } = payload
            setActiveSpeaker(userId)
        },
        []
    )

    useEffect(() => {
        zoomClient.on('video-active-change', onVideoActiveChange)

        return () => zoomClient.off('video-active-change', onVideoActiveChange)
    }, [zoomClient, onVideoActiveChange])

    return activeSpeakerId
}
