import { useState, useCallback, useMemo } from 'react'
import { produce } from 'immer'
import { MediaStream } from '../types/video-types'

export function useLocalVolume(mediaStream: MediaStream) {
    const [userVolumeList, setUserVolumeList] = useState<{ userId: number; volume: number }[]>([])

    const setLocalVolume = useCallback(
        async (userId: number, volume: number) => {
            await mediaStream?.adjustUserAudioVolumeLocally(userId, volume)
            setUserVolumeList(
                produce(draft => {
                    const user = draft.find(u => u.userId === userId)
                    if (user) {
                        user.volume = volume
                    } else {
                        draft.push({ userId, volume })
                    }
                })
            )
        },
        [mediaStream]
    )

    const value = useMemo(
        () => ({
            userVolumeList,
            setLocalVolume,
        }),
        [userVolumeList, setLocalVolume]
    )
    return value
}
