import { DiffusionMessage, GetTimelineResponse } from '../apis/api-egg'
import { MeetingRoom, decodeMeetingToken } from '../pages/meeting-view/api'
import { UIContextType, useUI } from '../context/UIContext'
import { getSessionParameters, updateSessionParams } from '../helpers/urls'
import { useCallback, useEffect, useRef } from 'react'

import { ChangeMeetingText } from '../components/ChangeMeetingText'
import { CountDown } from '../components/CountDown'
import DayJS from '../helpers/DayJS'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NotificationWithCross } from '../components/NotificationWithCross'
import { ToastType } from 'react-hot-toast'
import { getMeetingTokens } from '../apis/api-hca'
import { useChangeMeeting } from './useChangeMeeting'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useTimeline } from './useTimeline'
import { useZoomContext } from '../context/ZoomContext'
import { useSessionParams } from './useSessionParams'
import ReactGA from 'react-ga4'

export const useActions = () => {
    const { showToast, openPip } = useUI()
    const { currentEvent, nextEvent, isLoading } = useTimeline()
    const { changeMeeting } = useChangeMeeting()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { setDiffusion, setUnReadMessages } = useZoomContext()
    const { role } = useSessionParams()

    const timer = useRef<NodeJS.Timeout>()

    useEffect(() => {
        const { current } = timer
        if (!current) return
        return () => clearTimeout(current)
    }, [nextEvent])

    useEffect(() => {
        if (nextEvent) {
            const { room, date } = nextEvent
            const duration = DayJS(date).diff(DayJS(), 'milliseconds')

            if (room)
                actionDispatcher({
                    event: 'change-meeting',
                    payload: {
                        room,
                        duration,
                    },
                })
            else
                actionDispatcher({
                    event: 'meeting-end-in',
                    payload: {
                        duration,
                    },
                })
        } else if (currentEvent) {
            const { room } = getSessionParameters()
            if (room && currentEvent.room !== room)
                actionDispatcher({
                    event: 'change-meeting',
                    payload: {
                        room: currentEvent.room,
                        duration: 1,
                    },
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEvent, nextEvent, isLoading])

    const actionDispatcher = useCallback(
        async ({ event, payload }: ActionsTypes) => {
            try {
                // if has payload and scope is not meet, return
                if (payload && 'scope' in payload && payload?.scope?.length && !payload.scope?.includes('meet')) return

                switch (event) {
                    case 'show-toast': {
                        if (!payload?.message) return
                        const { message, type, ...rest } = payload

                        if (type === 'custom' && typeof message === 'string')
                            showToast(<MessageInJSX message={message} />, rest)
                        else showToast(message, rest)
                        return
                    }

                    case 'redirect-to': {
                        payload.scope?.includes('meet') && navigate(payload.goTo)
                        break
                    }

                    case 'meeting-end-in': {
                        const { duration = 5000 } = payload
                        const text = `El encuentro finaliza en`

                        const id = event
                        const time = DayJS().add(duration, 'milliseconds').toNow(true)
                        const icon = <EndIcon />

                        showToast.error(
                            <NotificationWithCross id={id}>
                                {text} {time}.{' '}
                            </NotificationWithCross>,
                            { id, duration, icon }
                        )

                        timer.current = setTimeout(() => {
                            navigate('/post-meeting?reason=ended by host')
                            clearTimeout(timer.current)
                        }, duration)
                        break
                    }

                    case 'change-meeting': {
                        if (role !== 'student') return
                        const { duration = 5000, room } = payload
                        if (!room || duration <= 0) return

                        clearTimeout(timer.current)

                        const id = `${event}-${room}`
                        const icon = <TimeIcon />

                        showToast(
                            <NotificationWithCross id={id}>
                                <ChangeMeetingText room={room} duration={duration} />
                            </NotificationWithCross>,
                            { id, duration, icon }
                        )

                        try {
                            const time = DayJS().add(duration, 'milliseconds').toNow(true)
                            ReactGA.event({
                                category: 'Socket event',
                                action: 'change-meeting',
                                label: `Time to change: ${time}`,
                            })
                        } catch (error) {
                            console.log('❗ Error in ReactGA.event:', error)
                        }

                        timer.current = setTimeout(async () => {
                            changeMeeting(room)
                            clearTimeout(timer.current)
                        }, duration)

                        break
                    }

                    case 'join-to-meeting': {
                        const { duration = 5000, meetingToken } = payload
                        const { room } = decodeMeetingToken(meetingToken)

                        const text = room === 'general' ? 'Cambiando a sala general' : 'Cambiando a sala de equipo'

                        const id = `${event}-${room}`
                        const time = DayJS().add(duration, 'milliseconds').toNow(true)
                        const icon = <TimeIcon />
                        const msg = (
                            <div>
                                {text} en {time}. (
                                <small>
                                    <CountDown duration={duration} />
                                </small>
                                )
                            </div>
                        )
                        showToast(msg, { id, duration, icon })

                        timer.current = setTimeout(() => {
                            changeMeeting(room)
                            clearTimeout(timer.current)
                        }, duration)

                        break
                    }

                    case 'ui-team-has-updated': {
                        const params = getSessionParameters()
                        const { _algorithmId, forceUpdate } = payload

                        if (params._algorithmId === _algorithmId && !forceUpdate) return

                        if (_algorithmId) updateSessionParams({ _algorithmId })

                        if (forceUpdate && params.room === 'teams') changeMeeting('teams')

                        break
                    }

                    case 'new-timeline-event': {
                        ReactGA.event({
                            category: 'Socket event',
                            action: 'new-timeline-event',
                        })

                        queryClient.setQueryData<GetTimelineResponse['data']>(
                            ['Course', 'Timeline', payload._courseId],
                            payload
                        )
                        break
                    }

                    case 'play-external-content': {
                        setDiffusion(old =>
                            old.concat([
                                {
                                    ...payload,
                                    timestamp: Date.now(),
                                },
                            ])
                        )

                        const icon = (
                            <FontAwesomeIcon
                                className="icon"
                                icon={['far', 'megaphone']}
                                style={{ color: '#00A3FF' }}
                            />
                        )
                        const duration = 10_000

                        if (payload.type === 'text') {
                            const { message } = payload
                            if (!message) return console.warn('❗ No `message` in play-external-content:', { payload })

                            const id = message

                            setUnReadMessages({ type: 'diffusion', status: true })
                            return showToast(<NotificationWithCross id={id}>{payload.message}</NotificationWithCross>, {
                                id,
                                icon,
                                duration,
                            })
                        }

                        const { src } = payload
                        if (!src) return console.warn('❗ No `src` in play-external-content:', { payload })

                        const id = src

                        showToast(
                            <NotificationWithCross id={id}>
                                En <CountDown duration={duration} format="s" /> segundos se reproducirá un mensaje de
                                difusión.
                            </NotificationWithCross>,
                            { icon, duration, id }
                        )

                        setTimeout(() => {
                            openPip(payload)
                            setUnReadMessages({ type: 'diffusion', status: true })
                        }, duration)

                        break
                    }

                    default: {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const exhaustiveCheck: never = event
                        console.warn('❗ Action not found:', { type: event, payload })
                    }
                }
            } catch (error) {
                console.error('❗ Error in actionDispatcher:', { type: event, payload, error })
            }
        },
        [changeMeeting, navigate, openPip, queryClient, role, setDiffusion, setUnReadMessages, showToast]
    )

    return { actionDispatcher }
}

const MessageInJSX = ({ message }: { message: string }) => <div dangerouslySetInnerHTML={{ __html: message }}></div>

const TimeIcon = () => <FontAwesomeIcon icon={['far', 'clock']} className="icon" style={{ color: '#00A3FF' }} />
const EndIcon = () => <FontAwesomeIcon icon={['far', 'hourglass-end']} className="icon" style={{ color: '#ff647c' }} />

type ActionsTypes =
    | {
          event: 'show-toast'
          payload?: {
              scope?: string[]
              type?: ToastType
              message: string
          } & Parameters<UIContextType['showToast']>[1]
      }
    | {
          event: 'meeting-end-in'
          payload: {
              duration: number
          }
      }
    | {
          event: 'redirect-to'
          payload: {
              goTo: string
              scope?: string[]
          }
      }
    | {
          event: 'change-meeting'
          payload: {
              room: MeetingRoom
              duration?: number
          }
      }
    | {
          event: 'join-to-meeting'
          payload: {
              meetingToken: string
              duration?: number
          }
      }
    | {
          event: 'ui-team-has-updated'
          payload: {
              _algorithmId?: string
              forceUpdate?: boolean
          }
      }
    | {
          event: 'new-timeline-event'
          payload: GetTimelineResponse['data']
      }
    | {
          event: 'play-external-content'
          payload: DiffusionMessage
      }

export const getGeneralToken = async () => {
    let { generalToken = null } = getSessionParameters()

    if (generalToken) return generalToken

    const { general_room } = await getMeetingTokens()
    updateSessionParams({ generalToken: general_room?.token })
    return general_room?.token
}

export const getTeamsToken = async ({ forceUpdate }: { forceUpdate?: boolean } = {}) => {
    let { teamsToken = null, _algorithmId } = getSessionParameters()

    if (teamsToken && !forceUpdate) return teamsToken

    const { teams_room } = await getMeetingTokens(_algorithmId)

    teamsToken = teams_room?.token ?? null

    updateSessionParams({ teamsToken })
    return teamsToken
}
