import { SidebarModeType, useZoomContext } from '../../../../context/ZoomContext'
import { useEffect, useRef } from 'react'

import { Messages } from '../Messages/Messages'
import Participants from '../Participants/Participants'
import SidebarHeader from './SidebarHeader'
import { VIDEO_FOOTER_ID } from '../../util/video-constants'
import styled from 'styled-components'
import { useSessionParams } from '../../../../hooks/useSessionParams'

type Props = {
    token?: string
}

const Sidebar = (props: Props) => {
    const { token } = props
    const { sidebarMode, setSidebarMode } = useZoomContext()
    const { room } = useSessionParams()
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        console.log('.')
        const handleClickOutside = (event: MouseEvent) => {
            const footerElement = document.getElementById(VIDEO_FOOTER_ID)

            if (footerElement && footerElement.contains(event.target as Node)) return // Do nothing if the click is inside the footer
            if (ref.current && !ref.current.contains(event.target as Node)) setSidebarMode(null)
        }

        document.addEventListener('click', handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [setSidebarMode])

    const getTool = () => {
        if (sidebarMode === 'messages') return <Messages />
        if (sidebarMode === 'widget') {
            if (room === 'general') {
                return (
                    <div style={{ color: 'white', textAlign: 'center', padding: '10px' }}>
                        No widget on this version
                    </div>
                )
            } else {
                return (
                    <WidgetFrame
                        title="Widget"
                        src={`${process.env.REACT_APP_WIDGET_APPFRAME}?token=${token}&source=video-sdk`}
                    />
                )
            }
        }
        if (sidebarMode === 'participants') return <Participants />
    }

    return (
        <SidebarStyled ref={ref} sidebarMode={sidebarMode}>
            {sidebarMode !== 'widget' && (
                <SidebarHeader
                    title={
                        sidebarMode === 'messages' ? 'Mensajes' : sidebarMode === 'participants' ? 'Participantes' : ''
                    }
                    handleClick={() => setSidebarMode(null)}
                />
            )}
            <div className="bottom">{getTool()}</div>
        </SidebarStyled>
    )
}

export default Sidebar

const SidebarStyled = styled.div<{ sidebarMode: SidebarModeType }>`
    font-family: 'Poppins', sans-serif;
    width: 380px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 20px 5px rgba(0, 55, 80, 0.15);
    background-color: ${props => props.theme.color.sidebar.background};
    backdrop-filter: blur(10px);
    /* border: none; */
    border-radius: 20px;
    overflow: hidden;
    box-sizing: border-box;
    gap: 10px;
    .bottom {
        padding: ${({ sidebarMode }) => (sidebarMode !== 'widget' ? '5px 23px 23px 23px' : 0)};
        max-height: ${({ sidebarMode }) => (sidebarMode !== 'widget' ? 'calc(100% - 120px)' : '100%')};
        height: 100%;
    }
`

export const WidgetFrame = styled.iframe`
    width: 100%;
    height: 100%;
    min-height: 440px;
    display: block;
    border: none;
    background-color: white;
    border-radius: 20px;
`
