import { useEffect, useRef, useState } from 'react'

import { ChatItem } from './ChatItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MessagesStyled } from '../Messages'
import { SelectReceiver } from './SelectReceiver'
import { useBottom } from '../../../../../hooks/useBottom'
import { useChat } from './useChat'

const Chat = () => {
    const { history, sendMessage, ...rest } = useChat()
    const mainRef = useBottom(history)
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const [newMessage, setNewMessage] = useState('')

    const onSubmit = (e: React.FormEvent<HTMLFormElement | HTMLTextAreaElement>) => {
        const {
            state: [receiverId],
        } = rest

        e.preventDefault()
        sendMessage(newMessage, receiverId)
        setNewMessage('')
    }

    useEffect(() => {
        const timeOutId = setTimeout(() => textAreaRef.current?.focus(), 400)
        return () => clearTimeout(timeOutId)
    }, [])

    return (
        <MessagesStyled>
            <main ref={mainRef}>
                {history.map(item => (
                    <ChatItem key={item.id} {...item} />
                ))}
            </main>

            <div>
                <SelectReceiver {...rest} />

                <form className="input-block" onSubmit={onSubmit}>
                    <textarea
                        ref={textAreaRef}
                        placeholder="Escribir mensaje"
                        value={newMessage}
                        onChange={({ target: { value } }) => setNewMessage(value)}
                        onKeyDown={e => {
                            if (e.key !== 'Enter' || e.shiftKey) return
                            onSubmit(e)
                        }}
                    />
                    <button disabled={!newMessage}>
                        <FontAwesomeIcon icon={['far', 'paper-plane-top']} />
                    </button>
                </form>
            </div>
        </MessagesStyled>
    )
}

export default Chat
