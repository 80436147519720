import Loader from '../../assets/loader-transparent.gif'
import styled from 'styled-components'

type Props = {
    metric?: '%' | 'vh'
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

const LoadingPage = ({ metric = 'vh', ...rest }: Props) => (
    <LoadingPageContainer metric={metric}>
        <img width={60} {...rest} src={Loader} alt="Loading..." />
    </LoadingPageContainer>
)

export default LoadingPage

const LoadingPageContainer = styled.div<{ metric?: '%' | 'vh' }>`
    height: ${props => `100${props.metric}`};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        mix-blend-mode: multiply;
    }
`

export const LoadingSpinner = ({ metric = 'vh', ...rest }: Props) => (
    <img width={60} {...rest} src={Loader} alt="Loading..." />
)

export const Spinner = styled.span`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    margin: auto;
    position: relative;
    box-sizing: border-box;
    animation: animloader 2s linear infinite;

    @keyframes animloader {
        0% {
            box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
        }
        25% {
            box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
        }
        50% {
            box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
        }
        75% {
            box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
        }
        100% {
            box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
        }
    }
`
