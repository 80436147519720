import { ObjectKeys } from './ObjectKeys'
import { ZoomEventDTO } from '../api'

export const parseEvents = <T extends Record<string, unknown>>(
    events: T,
    checker?: Checker<keyof T>
) =>
    ObjectKeys(events).reduce((acc, key) => {
        if (checker?.(key) !== false) acc = { ...acc, [key]: { state: events[key] } }
        return acc
    }, {} as ZoomEventDTO)

type Checker<K> = (key: K) => boolean
