import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const ThankYouFeedback = () => (
    <Section>
        <FontAwesomeIcon icon={['far', 'check-circle']} className="green" />
        <p className="green last">Calificación enviada con éxito. Gracias por tu feedback</p>
    </Section>
)

const Section = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin: 13px 0 36px 0;
    & > .green {
        font-size: 18px;
    }
    p.last {
        margin: 0;
        font-size: 16px;
    }
`
