import { Participant, ZoomClient } from '../types/video-types'
import { useCallback, useEffect, useRef } from 'react'

export const useParticipantsChange = (
    zoomClient: ZoomClient,
    fn: (participants: Participant[], updatedParticipants: Participant[]) => void
) => {
    const fnRef = useRef(fn)
    fnRef.current = fn

    const callback = useCallback(
        (updatedParticipants?: Participant[]) => {
            const participants = zoomClient.getAllUser()
            fnRef.current?.(participants, updatedParticipants ?? [])
        },
        [zoomClient]
    )

    useEffect(() => {
        zoomClient.on('user-added', callback)
        zoomClient.on('user-removed', callback)
        zoomClient.on('user-updated', callback)
        return () => {
            zoomClient.off('user-added', callback)
            zoomClient.off('user-removed', callback)
            zoomClient.off('user-updated', callback)
        }
    }, [zoomClient, callback])

    useEffect(() => {
        callback()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}
