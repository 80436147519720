import { MediaStream, ZoomClient } from '../types/video-types'
import { MeetingParams, useZoomContext } from '../../../context/ZoomContext'
import { deleteParamsFromURL, getSessionParameters, updateSessionParams } from '../../../helpers/urls'
import { useEffect, useState } from 'react'

import { ErrorType } from '../components/Video/ErrorComponent'
import ReactGA from 'react-ga4'
import ZoomVideo from '@zoom/videosdk'
import { capitalizeAll } from '../../../helpers/formatters'
import { decodeMeetingToken } from '../api'
import { useMeetingError } from './meeting-errors/useMeetingError'
import { useNavigate } from 'react-router-dom'
import { useTimer } from './useTimer'

const isProd = process.env.NODE_ENV === 'production'

export const useJoinSDK = () => {
    const navigate = useNavigate()
    const { handleError, QRCode } = useMeetingError()
    const [isJoining, setIsJoining] = useState(false)
    const [error, setError] = useState<ErrorType | null>(null)
    const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
    const {
        setCurrentUserInfo,
        setCurrentSessionInfo,
        setInfoText,
        clearInfo,
        zoomClient,
        setChatClient,
        setCommandChannel,
        setIsVideoStarted,
        setIsAudioStarted,
        setMediaStreamGlobal,
        setRecordingClient,
    } = useZoomContext()

    const urlParams = getSessionParameters<MeetingParams>()
    const { name = 'Anónimo', widgetToken: token } = urlParams

    const { time, isActive } = useTimer({
        initTime: 30,
        onFinish: () => {
            zoomClient.leave()
            ZoomVideo.destroyClient()
            navigate('/post-meeting')
        },
    })

    const join = async () => {
        if (isJoining) return

        setIsJoining(true)
        const password = ''

        const { room, ...rest } = getSessionParameters()
        if (!room) return setError(handleError({ errorType: 'default', payload: null }))

        const token = rest[`${room}Token`]
        if (!token) return setError(handleError({ errorType: 'default', payload: null }))

        const { tpc: topic } = decodeMeetingToken(token)
        if (rest.topic !== topic) updateSessionParams({ topic })

        try {
            await zoomClient.init('en-US', 'Global', {
                webEndpoint: 'zoom.us',
                stayAwake: true,
                leaveOnPageUnload: true,
                patchJsMedia: true,
            })

            setInfoText('Uniéndose a la sesión e iniciando cámara...')
            await zoomClient.leave()
            await zoomClient.join(topic, token, capitalizeAll(name), password)
            setIsJoining(false)

            deleteParamsFromURL()

            const stream = zoomClient.getMediaStream()
            setMediaStream(stream)
            setMediaStreamGlobal(stream)

            const chatClient = zoomClient.getChatClient()
            setChatClient(chatClient)

            const commandChannel = zoomClient.getCommandClient()
            setCommandChannel(commandChannel)

            const recordingClient = zoomClient.getRecordingClient()
            setRecordingClient(recordingClient)

            // if (isProd) {
            //     console.log('IsProd starting logger')
            //     const loggerClient = zoomClient.getLoggerClient()
            //     setLoggerClient(loggerClient)
            // }

            await autoStartAudio(zoomClient, stream)
                .then(() => {
                    setIsAudioStarted(true)
                })
                .catch(err => {
                    setIsVideoStarted(false)
                    console.info('Unable to auto start audio', err)
                })

            ReactGA.event({
                category: 'Performance',
                action: 'user-joined',
            })

            setCurrentUserInfo(zoomClient.getCurrentUserInfo())
            setCurrentSessionInfo(zoomClient.getSessionInfo())
            clearInfo()
        } catch (error) {
            setError(handleError({ errorType: 'default', payload: null }))
            console.error('Hubo un error al unirse a la sesión.', error)
        } finally {
            setIsJoining(false)
        }
    }

    useEffect(() => {
        if (error && error?.errorType !== 'permissions-denied') return
        if (isActive && time > 0) setError(handleError({ errorType: 'permissions-denied', payload: { time } }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time])

    return {
        join,
        QRCode,
        isJoining: isJoining && !error,
        errorType: error,
        mediaStream,
        token,
    }
}

const autoStartAudio = async (zoomClient: ZoomClient, mediaStream: MediaStream) => {
    if (!zoomClient || !mediaStream) {
        console.log('No client or stream to start audio')
        return
    }
    await mediaStream
        ?.startAudio({ mute: true, backgroundNoiseSuppression: true })
        .then(() => {
            return console.info('Audio started succesfully')
        })
        .catch(error => {
            ReactGA.event({
                category: 'Error',
                action: 'Error auto-starting audio',
                label: error,
            })
            return error
        })
}
