import { ChatMessage } from '@zoom/videosdk'
import DayJS from '../../../../../helpers/DayJS'
import { capitalizeAll } from '../../../../../helpers/formatters'
import { isUrl } from '../../../../../helpers/urls'
import styled from 'styled-components'
import { useZoomContext } from '../../../../../context/ZoomContext'

export const ChatItem = ({ sender, receiver, timestamp, message }: ChatMessage) => {
    const parts = message?.split(/(https?:\/\/[^\s]+)/g) ?? []

    const { currentUserInfo } = useZoomContext()
    const { userId } = currentUserInfo ?? {}

    const Sending = sender.userId === userId
    const Receiving = receiver.userId === userId
    const ReceiverStyle = Receiving ? 'me' : receiver.userId === 0 ? 'everyone' : 'private'

    // prevent

    return (
        <Item>
            <header className="message-header">
                <b>
                    {capitalizeAll(sender.name)} {Sending && '(Tú)'}
                </b>
                <p className="to">a</p>
                <p className={`receiver ${ReceiverStyle}`}>{Receiving ? 'Tí' : capitalizeAll(receiver.name)}</p>
                <small>{DayJS(timestamp).format('HH:mm')} hs</small>
            </header>
            <p className="message">
                {parts.map((part, index) =>
                    isUrl(part) ? (
                        <a key={part + index} href={part} target="_blank" rel="noreferrer">
                            {part}
                        </a>
                    ) : (
                        part.toString()
                    )
                )}
            </p>
        </Item>
    )
}

export const Item = styled.div`
    margin: 1em 0px;
    color: white;
    .message-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        & > * {
            flex-shrink: 0;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        p.to {
            color: #b2b2b2;
        }
        p.receiver {
            font-weight: 500;
            text-overflow: ellipsis;

            &.me {
                color: ${props => props.theme.color.errors};
            }
            &.private {
                color: #00a3ff;
            }
            &.everyone {
                color: ${props => props.theme.color.lightGray};
            }
        }
        small {
            color: #b2b2b2;
            font-size: 10px;
            margin-left: 1em;
        }
    }
    p {
        margin: 0px !important;
        padding: 0px !important;
    }
    p.message {
        white-space: pre-line;
        word-wrap: break-word;
    }
`
