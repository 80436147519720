import { useCallback, useEffect, useReducer, useState } from 'react'
import produce from 'immer'
import { ZoomClient, MediaStream } from '../pages/meeting-view/types/video-types'
import { useZoomContext } from '../context/ZoomContext'

export default function usePermissionChange(
    zoomClient: ZoomClient,
    mediaStream: MediaStream | null,
    stage: 'premeeting' | 'meeting'
) {
    const [permissionState] = useReducer(mediaReducer, permissionShape)
    const [permissionGranted, setPermissionGranted] = useState(true)
    const [checking, setChecking] = useState(true)
    const { currentUserInfo } = useZoomContext()

    const checkPermissions = useCallback(async () => {
        try {
            setChecking(true)
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
                // Both devices are available
                setPermissionGranted(true)
                stream.getTracks().forEach(track => track.stop())
                return true
            } else {
                // None of the devices are available
                stream.getTracks().forEach(track => track.stop())
                setPermissionGranted(false)
                return false
            }
        } catch (error) {
            setPermissionGranted(false)
            return false // There is an error
        } finally {
            setChecking(false)
        }
    }, [])

    useEffect(() => {
        checkPermissions()
    }, [checkPermissions, stage])

    const onDevicePermissionChange = useCallback(
        async (payload: { name: PermissionShapeKey; state: 'denied' | 'granted' | 'prompt' }) => {
            const { name, state } = payload
            if (mediaStream) {
                if (state === 'denied') {
                    console.info('Permission denied')
                    await mediaStream.muteAllUserAudioLocally().catch(() => {})
                    setPermissionGranted(false)
                    if (name === 'camera') {
                        try {
                            currentUserInfo?.bVideoOn && (await mediaStream.stopVideo())
                        } catch (error) {
                            console.info('Error stopping video', error)
                        }
                    }
                    if (name === 'microphone') {
                        try {
                            if (currentUserInfo?.audio !== '') return
                            !currentUserInfo?.muted && (await mediaStream.muteAudio().catch(() => {}))
                            currentUserInfo?.audio !== '' && (await mediaStream.stopAudio().catch(() => {}))
                        } catch (error) {
                            console.info('Error stopping audio', error)
                        }
                    }
                }
            }
        },
        [currentUserInfo?.audio, currentUserInfo?.bVideoOn, currentUserInfo?.muted, mediaStream]
    )

    useEffect(() => {
        zoomClient.on('device-permission-change', onDevicePermissionChange)
        return () => {
            zoomClient.off('device-permission-change', onDevicePermissionChange)
        }
    }, [onDevicePermissionChange, zoomClient])

    return {
        permissionState,
        checkPermissions,
        permissionGranted,
        checking,
    }
}

type PermissionShapeKey = keyof typeof permissionShape

const permissionShape = {
    microphone: {
        state: false,
    },
    camera: {
        state: false,
    },
}

const mediaReducer = produce((draft, action) => {
    switch (action.type) {
        case 'microphone': {
            draft.microphone.state = action.payload
            break
        }
        case 'camera': {
            draft.camera.state = action.payload
            break
        }
        case 'reset-permission': {
            Object.assign(draft, { ...permissionShape })
            break
        }
        default:
            break
    }
}, permissionShape)
