import { useCallback, useEffect } from 'react'
import { useZoomContext } from '../../../context/ZoomContext'
import { RecordingStatus } from '@zoom/videosdk'
import { useUI } from '../../../context/UIContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import toast from 'react-hot-toast'
import ToastNotification from '../components/Misc/ToastNotification'

const useRecording = () => {
    const { recordingClient, setIsRecording, commandChannel, zoomClient } = useZoomContext()
    const { showToast } = useUI()

    const handleRecording = useCallback(async () => {
        const recordingStatus = recordingClient?.getCloudRecordingStatus()
        switch (recordingStatus) {
            case RecordingStatus.Recording:
                await recordingClient?.stopCloudRecording()
                console.log('Recording stopped')
                commandChannel?.send(`El coach detuvo la grabación de este encuentro`)
                showToast('Se detuvo la grabación de este encuentro')
                setIsRecording(false)
                return
            case RecordingStatus.Stopped:
                if (!recordingClient?.canStartRecording()) return
                recordingClient?.startCloudRecording()
                commandChannel?.send(`El coach comenzó la grabación de este encuentro`)
                showToast(
                    <ToastNotification
                        icon={['fas', 'circle']}
                        iconStyle={{ color: '#ff647c' }}
                        msg={'Se está grabando este encuentro'}
                    />,
                    {
                        id: 'record-start',
                    }
                )
                setIsRecording(true)
                console.log('Recording started')
                return
        }
    }, [commandChannel, recordingClient, setIsRecording, showToast])

    const onRecordingChange = useCallback(
        (payload?: RecordingStatus) => {
            // Sets recording state for participants even when they join.
            switch (payload) {
                case RecordingStatus.Recording:
                    setIsRecording(true)
                    showToast(
                        <ToastNotification
                            icon={['fas', 'circle']}
                            iconStyle={{ color: '#ff647c' }}
                            msg={
                                zoomClient.isHost()
                                    ? 'Se está grabando este encuentro'
                                    : 'El coach está grabando este encuentro'
                            }
                        />,
                        {
                            id: 'record-start',
                        }
                    )
                    return
                case RecordingStatus.Stopped:
                    setIsRecording(false)
                    return
            }
        },
        [setIsRecording, showToast, zoomClient]
    )

    useEffect(() => {
        zoomClient.on('recording-change', onRecordingChange)
        return () => {
            zoomClient.off('recording-change', onRecordingChange)
        }
    }, [zoomClient, onRecordingChange])

    return {
        handleRecording,
    }
}

export default useRecording
