import { useQuery } from '@tanstack/react-query'
import { getGeneralData, MeetingRoom } from '../pages/meeting-view/api'

export const useGeneralData = (type: MeetingRoom) => {
    const { data, ...rest } = useQuery({
        queryKey: ['GeneralData'],
        queryFn: () => getGeneralData(type),
    })

    return {
        data,
        ...rest,
    }
}
