import './video-footer.scss'

import { AudioChangeAction, ParticipantPropertiesPayload, SharePrivilege, VideoCapturingState } from '@zoom/videosdk'
import { MediaDevice, MediaStream, Participant } from '../../types/video-types'
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import { SidebarModeType, useZoomContext } from '../../../../context/ZoomContext'

import { Blur } from './Blur'
import CameraButton from './Camera'
import { ChatIcon } from './ChatIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Footer } from './FooterStyled'
import { FooterIcon } from './FooterIcon'
import { LeaveButton } from './Leave'
import MicrophoneButton from './Mic'
import { RaiseHand } from './RaiseHand'
import ReactGA from 'react-ga4'
import { RoomName } from '../RoomName'
import { ScreenShareButton } from './ScreenShare'
import { Spinner } from './Spinner'
import ToastNotification from '../Misc/ToastNotification'
import { VIDEO_FOOTER_ID } from '../../util/video-constants'
import { WidgetIcon } from './WidgetIcon'
import { getItem } from '../../../../helpers/localStorage'
import { isAndroidOrIOSBrowser } from '../../util/platform'
import { renderSelfVideo } from '../../helpers/renderSelfVideo'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import useRecording from '../../hooks/useRecording'
import { useSessionParams } from '../../../../hooks/useSessionParams'
import { useTimeline } from '../../../../hooks/useTimeline'
import { useUI } from '../../../../context/UIContext'
import { useZoomEvents } from '../../hooks/useZoomEvents'

interface VideoFooterProps {
    selfShareRef: MutableRefObject<HTMLCanvasElement | null>
    mediaStream: MediaStream | null
}

const VideoFooter = (props: VideoFooterProps) => {
    const { mediaStream, selfShareRef } = props

    const {
        isStartingVideo,
        isStartedScreenShare,
        setIsStartedScreenShare,
        showInfo,
        setIsStartingVideo,
        zoomClient,
        sidebarMode,
        setSidebarMode,
        setUnReadMessages,
        isVideoStarted: isStartedVideo,
        isAudioStarted: isStartedAudio,
        setIsVideoStarted: setIsStartedVideo,
        setIsAudioStarted: setIsStartedAudio,
        isRecording,
        isHostOrManager,
        setConfirmationDialog,
        currentUserInfo,
        setCurrentUserInfo,
        IRaisedMyHand,
    } = useZoomContext()

    const [audio, setAudio] = useState('')
    const [isFx, setIsFx] = useState(() => getItem<boolean>('vfx'))
    const [isMuted, setIsMuted] = useState(true)
    const [activeMicrophone, setActiveMicrophone] = useState('')
    const [activeSpeaker, setActiveSpeaker] = useState('')
    const [activeCamera, setActiveCamera] = useState('')
    const [micList, setMicList] = useState<MediaDevice[]>([])
    const [speakerList, setSpeakerList] = useState<MediaDevice[]>([])
    const [, setSomeOneIsSharing] = useState(false)
    const camerasRef = useRef<MediaDevice[]>([])
    const { showToast } = useUI()
    const [audioEnabled, setAudioEnabled] = useState(false)

    const [shareEnabled, setShareEnabled] = useState<boolean>(!mediaStream?.isShareLocked())

    camerasRef.current = mediaStream?.getCameraList() ?? []

    const { role, room } = useSessionParams()

    const navigate = useNavigate()
    const { handleRecording } = useRecording()

    const { currentEvent } = useTimeline()

    const isAudioEnable = useCallback(() => {
        if (isHostOrManager) return true
        if (currentEvent?.room === 'teams') return true
        return currentEvent?.settings.enabledMic !== false
    }, [currentEvent?.room, currentEvent?.settings.enabledMic, isHostOrManager])

    useEffect(() => {
        setAudioEnabled(isAudioEnable())
    }, [isAudioEnable])

    useZoomEvents(zoomClient, {
        video: isStartedVideo,
        audio: isStartedAudio,
        speaker: isStartedAudio ? (isMuted ? 'stopped' : 'started') : 'stopped',
    })

    const onCameraClick = useCallback(async () => {
        if (!zoomClient || !mediaStream) return

        setIsStartingVideo(true)

        renderSelfVideo(zoomClient, mediaStream)
            .then(() => setIsStartedVideo(true))
            .finally(() => setIsStartingVideo(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaStream, zoomClient, setIsStartingVideo])

    const onMicrophoneClick = useCallback(async () => {
        if (isStartedAudio) {
            if (!micList.length) return
            if (isMuted) {
                await mediaStream?.unmuteAudio()
                setIsMuted(false)
            } else {
                await mediaStream?.muteAudio()
                setIsMuted(true)
            }
        } else {
            await mediaStream?.startAudio({ mute: true, backgroundNoiseSuppression: true }).catch(error => {
                console.log('Error starting audio', error)
            })
            // await mediaStream?.muteAudio()
            setIsMuted(true)
            setIsStartedAudio(true)
        }
    }, [isStartedAudio, isMuted, mediaStream, setIsMuted, setIsStartedAudio, micList])

    const onMicrophoneMenuClick = async (device: MediaDevice) => {
        if (mediaStream) {
            const { type, deviceId, label } = device
            if (type === 'microphone') {
                if (deviceId !== activeMicrophone) {
                    await mediaStream.switchMicrophone(deviceId)
                    setActiveMicrophone(mediaStream.getActiveMicrophone())
                }
            } else if (type === 'speaker') {
                if (deviceId !== activeSpeaker) {
                    await mediaStream.switchSpeaker(deviceId)
                    setActiveSpeaker(mediaStream.getActiveSpeaker())
                }
            } else if (type === 'leave audio') {
                if (audio === 'computer') {
                    await mediaStream.stopAudio()
                } else if (audio === 'phone') {
                    await mediaStream.hangup()
                }
                setIsStartedAudio(false)
            }
            showInfo(`Haz seleccionado ${label}`)
        }
    }

    const onSwitchCamera = async (key: string) => {
        if (mediaStream) {
            if (activeCamera !== key) {
                await mediaStream.switchCamera(key)
                setActiveCamera(mediaStream.getActiveCamera())
            }
        }
    }

    const onHostAudioMuted = useCallback(
        (payload: any) => {
            const { action, type } = payload
            if (action === AudioChangeAction.Join) {
                setIsStartedAudio(true)
                setAudio(type)
            } else if (action === AudioChangeAction.Leave) {
                setIsStartedAudio(false)
            } else if (action === AudioChangeAction.Muted) {
                setIsMuted(true)
            } else if (action === AudioChangeAction.Unmuted) {
                setIsMuted(false)
            }
        },
        [setAudio, setIsMuted, setIsStartedAudio]
    )

    const onScreenShareClick = useCallback(async () => {
        try {
            if (!isStartedScreenShare && selfShareRef && selfShareRef.current) {
                await mediaStream
                    ?.startShareScreen(selfShareRef.current, { requestReadReceipt: true })
                    .then(() => {
                        setIsStartedScreenShare(true)
                        const icon = <FontAwesomeIcon icon={['far', 'screencast']} style={{ color: '#48A0F8' }} />
                        showToast('Estás compartiendo tu pantalla', { id: 'screen-sharing', duration: Infinity, icon })
                    })
                    .catch(() => {})
            } else if (isStartedScreenShare) {
                await mediaStream?.stopShareScreen().then(() => {
                    toast.dismiss('screen-sharing')
                })
                setIsStartedScreenShare(false)
            }
            ReactGA.event('Screenshare Clicked')
        } catch (error) {
            setIsStartedScreenShare(false)
            console.error(error)
            ReactGA.event({
                category: 'Screenshare start failed',
                action: 'Error sharing screen',
            })
        }
    }, [isStartedScreenShare, selfShareRef, mediaStream, setIsStartedScreenShare, showToast])

    const stopMediaStream: () => void = useCallback(async () => {
        try {
            if (isStartedAudio) setIsStartedAudio(false)
            if (isStartedVideo) setIsStartedVideo(false)
            if (isStartedScreenShare) await mediaStream?.stopShareScreen()
        } catch (error) {
            console.log(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStartedAudio, isStartedScreenShare, isStartedVideo, mediaStream])

    const onLeaveClick = useCallback(async () => {
        const existsManager = zoomClient.getAllUser().find(item => item.isManager)
        try {
            stopMediaStream()

            if (zoomClient.isHost() && !existsManager) {
                setConfirmationDialog('QUIT_MEETING')
                return
            }

            await zoomClient.leave(false)
            navigate('/post-meeting')
        } catch (error) {
            console.log('Error leaving meeting', error)
        }
    }, [zoomClient, stopMediaStream, navigate, setConfirmationDialog])

    const onDeviceChange = useCallback(() => {
        if (!mediaStream) return

        setMicList(
            mediaStream.getMicList().map(item => ({
                ...item,
                deviceId: `${item.deviceId}-mic`,
                type: 'microphone',
            }))
        )
        setSpeakerList(
            mediaStream.getSpeakerList().map(item => ({
                ...item,
                deviceId: `${item.deviceId}-speaker`,
                type: 'speaker',
            }))
        )

        setActiveMicrophone(mediaStream.getActiveMicrophone())
        setActiveSpeaker(mediaStream.getActiveSpeaker())
        setActiveCamera(mediaStream.getActiveCamera())
    }, [mediaStream, setActiveCamera, setActiveMicrophone, setActiveSpeaker, setMicList, setSpeakerList])

    const onVideoCaptureChange = useCallback(
        async (payload: { state: VideoCapturingState }) => {
            setIsStartedVideo(payload.state === VideoCapturingState.Started)
        },
        [setIsStartedVideo]
    )

    const onPassivelyStopShare = useCallback(() => {
        setIsStartedScreenShare(false)
        toast.dismiss('screen-sharing')
    }, [setIsStartedScreenShare])

    const onUserUpdated = useCallback(
        (newUsers: ParticipantPropertiesPayload[]) => {
            const { userId } = currentUserInfo ?? {}
            const newCurrentUser = newUsers.find(item => item.userId === userId)

            if (newCurrentUser) {
                if (newCurrentUser?.isHost || newCurrentUser?.isManager) setShareEnabled(true)

                setCurrentUserInfo(old => ({ ...old, ...newCurrentUser } as Participant))
            }

            const someoneElseIsSharing = newUsers.some(user => user.sharerOn && user.userId !== userId)

            setSomeOneIsSharing(someoneElseIsSharing)
        },
        [currentUserInfo, setCurrentUserInfo]
    )

    const determineShareEnabled = useCallback(
        (privilege: SharePrivilege): boolean => {
            switch (privilege) {
                case SharePrivilege.Locked:
                    return zoomClient.isHost() || zoomClient.isManager()
                case SharePrivilege.MultipleShare:
                case SharePrivilege.Unlocked:
                    return true
                default:
                    return false
            }
        },
        [zoomClient]
    )

    const onSharePrivilegeChange = useCallback(
        (payload: { privilege: SharePrivilege }) => {
            const shareEnabled = determineShareEnabled(payload.privilege)
            setShareEnabled(shareEnabled)
            if (!zoomClient.isHost()) {
                switch (payload.privilege) {
                    case SharePrivilege.Locked:
                        showToast(
                            <ToastNotification
                                icon={['fal', 'circle-info']}
                                iconStyle={{ color: '#48A0F8' }}
                                msg={'Solo el Coach y Anfitriones pueden compartir pantalla'}
                            />,
                            {
                                id: payload.privilege.toString(),
                            }
                        )
                        break
                    case SharePrivilege.MultipleShare:
                    case SharePrivilege.Unlocked:
                        showToast(
                            <ToastNotification
                                icon={['fal', 'circle-info']}
                                iconStyle={{ color: '#48A0F8' }}
                                msg={'Todos los participantes pueden compartir pantalla'}
                            />,
                            {
                                id: payload.privilege.toString(),
                            }
                        )

                        break
                    default:
                        return false
                }
            }
        },
        [determineShareEnabled, showToast, zoomClient]
    )

    const onChatMessage = useCallback(
        () => sidebarMode !== 'messages' && setUnReadMessages({ type: 'chat', status: true }),
        [setUnReadMessages, sidebarMode]
    )

    const onToggleSidebar = useCallback(
        (toggle: SidebarModeType) => {
            setSidebarMode(oldSidebarMode => {
                return oldSidebarMode === toggle ? null : toggle
            })
        },
        [setSidebarMode]
    )

    useEffect(() => {
        zoomClient.on('current-audio-change', onHostAudioMuted)
        zoomClient.on('device-change', onDeviceChange)
        zoomClient.on('video-capturing-change', onVideoCaptureChange)
        zoomClient.on('passively-stop-share', onPassivelyStopShare)
        zoomClient.on('user-updated', (payload: ParticipantPropertiesPayload[]) => onUserUpdated(payload))
        zoomClient.on('share-privilege-change', onSharePrivilegeChange)
        zoomClient.on('chat-on-message', onChatMessage)
        return () => {
            zoomClient.off('current-audio-change', onHostAudioMuted)
            zoomClient.off('device-change', onDeviceChange)
            zoomClient.off('video-capturing-change', onVideoCaptureChange)
            zoomClient.off('passively-stop-share', onPassivelyStopShare)
            zoomClient.off('user-updated', onUserUpdated)
            zoomClient.off('share-privilege-change', onSharePrivilegeChange)
            zoomClient.off('chat-on-message', onChatMessage)
        }
    }, [
        zoomClient,
        onHostAudioMuted,
        onDeviceChange,
        onVideoCaptureChange,
        onPassivelyStopShare,
        onUserUpdated,
        onSharePrivilegeChange,
        onChatMessage,
    ])

    return (
        <Footer showSidebar={sidebarMode} id={VIDEO_FOOTER_ID}>
            <ul>
                <RoomName />
                {audioEnabled && (
                    <li>
                        <MicrophoneButton
                            isStartedAudio={isStartedAudio}
                            isMuted={isMuted}
                            audio={audio}
                            onMicrophoneClick={onMicrophoneClick}
                            onMicrophoneMenuClick={onMicrophoneMenuClick}
                            microphoneList={micList}
                            speakerList={speakerList}
                            activeMicrophone={activeMicrophone}
                            activeSpeaker={activeSpeaker}
                        />
                    </li>
                )}
                {isStartingVideo && <Spinner />}
                {isStartedVideo && mediaStream?.isSupportVirtualBackground() && (
                    <li className={isFx ? 'active' : ''}>
                        <Blur isFx={isFx} setIsFx={setIsFx} />
                    </li>
                )}
                {zoomClient && isHostOrManager && (
                    <li className={isRecording ? 'active' : ''}>
                        <FooterIcon
                            icon={isRecording ? ['fas', 'circle'] : ['far', 'circle']}
                            active={isRecording}
                            tooltip={{ hide: 'Detener grabación', show: 'Comenzar grabación' }}
                            handleClick={() => handleRecording()}
                            id={'record-btn'}
                        />
                    </li>
                )}
                {camerasRef.current.length > 1 && (
                    <li>
                        <CameraButton
                            isStartedVideo={isStartedVideo}
                            onCameraClick={onCameraClick}
                            onSwitchCamera={onSwitchCamera}
                            cameraList={camerasRef.current}
                            activeCamera={activeCamera}
                        />
                    </li>
                )}
                {!isAndroidOrIOSBrowser() && shareEnabled && (
                    <li className={isStartedScreenShare ? 'active' : ''}>
                        <ScreenShareButton
                            isStartedScreenShare={isStartedScreenShare}
                            onScreenShareClick={onScreenShareClick}
                        />
                    </li>
                )}
                <li className={sidebarMode === 'messages' ? 'active' : ''}>
                    <ChatIcon onClick={() => onToggleSidebar('messages')} />
                </li>
                {role === 'student' && room === 'teams' && (
                    <li className={sidebarMode === 'widget' ? 'active' : ''}>
                        <WidgetIcon showSidebar={sidebarMode} onClick={() => onToggleSidebar('widget')} />
                    </li>
                )}
                {role === 'student' && (
                    <li className={IRaisedMyHand ? 'active' : ''}>
                        <RaiseHand />
                    </li>
                )}
                <li className={sidebarMode === 'participants' ? 'active' : ''}>
                    <FooterIcon
                        icon={['far', 'users']}
                        tooltip={{ hide: 'Ocultar participantes', show: 'Mostrar participantes' }}
                        active={sidebarMode === 'participants'}
                        showSidebar={sidebarMode}
                        handleClick={() => onToggleSidebar('participants')}
                    />
                </li>
                <li style={{ paddingRight: '0' }}>
                    <LeaveButton onClick={onLeaveClick} />
                </li>
            </ul>
        </Footer>
    )
}
export default VideoFooter
