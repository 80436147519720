import { useEffect, useMemo, useRef } from 'react'

import { ConfirmationDialog } from '../../components/Modals/ConfirmationDialog'
import DevBlock from './components/Dev/DevBlock'
import ErrorComponent from './components/Video/ErrorComponent'
import { LoadingBolt } from '../../components/LoadingBolt'
import MeetingLayout from '../../layouts/MeetingLayout'
import { PermissionModal } from '../../components/Modals/PermissionModal'
import QuitModal from '../../components/Modals/QuitModal'
import VideoFooter from './components/VideoFooter/VideoFooter'
import VideoSAB from './components/Video/VideoSAB'
import VideoSingle from './components/Video/VideoSingle'
import { persistSessionParameters } from '../../helpers/urls'
import toast from 'react-hot-toast'
import { useActions } from '../../hooks/useActions'
import useCommandChannel from './hooks/useCommandChannel'
import { useJoinSDK } from './hooks/useJoinSDK'
import { useMediaContext } from './hooks/useMediaContext'
import { useNetworkQuality } from './hooks/useNetworkQuality'
import { useOffline } from './hooks/useOffline'
import usePermissionChange from '../../hooks/usePermissionChange'
import useRecording from './hooks/useRecording'
import { useSessionParams } from '../../hooks/useSessionParams'
import { useSettings } from '../../hooks/useSettings'
import { useUI } from '../../context/UIContext'
import { useZoomContext } from '../../context/ZoomContext'
import ReactGA from 'react-ga4'

const MeetingView = () => {
    persistSessionParameters()

    const zoom = useZoomContext()
    const { infoText, zoomClient, showInfo, clearInfo, confirmationDialog, currentUserInfo } = zoom
    const { join, QRCode, mediaStream, errorType, isJoining, ...restSDK } = useJoinSDK()
    const networkQuality = useNetworkQuality(zoomClient)
    const mediaContext = useMediaContext(zoomClient, mediaStream)
    const selfShareRef = useRef<(HTMLCanvasElement & HTMLVideoElement) | null>(null)
    const { showToast } = useUI()
    const { permissionGranted } = usePermissionChange(zoomClient, mediaStream, 'meeting')
    useActions()

    useOffline({ ...zoom, join })
    useCommandChannel()
    useRecording()

    const { room } = useSessionParams()
    const { settings, isLoading } = useSettings()
    const { hasSingleViewMeeting } = settings

    const VideoComponent = useMemo(() => {
        ReactGA.event({
            category: 'Performance',
            action: 'support-multiple-video',
            label: `${mediaStream?.isSupportMultipleVideos()}`,
        })

        if (room === 'general') {
            if (hasSingleViewMeeting || !mediaStream?.isSupportMultipleVideos()) return VideoSingle
            return VideoSAB
        } else if (room === 'teams') {
            if (!mediaStream?.isSupportMultipleVideos()) return VideoSingle
            return VideoSAB
        }

        return VideoSAB
    }, [hasSingleViewMeeting, mediaStream, room])

    const props = {
        infoText,
        zoomClient,
        mediaContext,
        selfShareRef,
        mediaStream,
    }

    useEffect(() => {
        if (!zoomClient.getSessionInfo().isInMeeting) join()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!zoomClient || !mediaStream) return
        const checkNetworkQuality = async () => {
            try {
                if (!currentUserInfo || typeof currentUserInfo.userId === 'undefined') {
                    console.error('Failed to retrieve user info or userId is undefined.')
                    return
                }
                const { userId } = currentUserInfo
                const userNetworkQuality = networkQuality[userId]

                if (userNetworkQuality) {
                    const uplink = userNetworkQuality.uplink ?? Number.MAX_VALUE
                    const downlink = userNetworkQuality.downlink ?? Number.MAX_VALUE

                    if (uplink <= 1 || downlink <= 1) {
                        showToast('Hay problemas con tu conexión a internet', {
                            id: 'network-quality-info',
                        })
                    } else {
                        clearInfo()
                        toast.dismiss('network-quality-info')
                    }
                }
            } catch (error) {
                console.error('Error checking network quality:', error)
            }
        }

        checkNetworkQuality()
    }, [clearInfo, currentUserInfo, mediaStream, networkQuality, showInfo, showToast, zoomClient])

    if (!permissionGranted) return <ConfirmationDialog modal={<PermissionModal />} isOpen={true} />

    if (isJoining || isLoading) return <LoadingBolt />

    return (
        <MeetingLayout {...restSDK}>
            <div>
                {errorType ? (
                    <ErrorComponent {...{ ...errorType }} />
                ) : (
                    mediaStream && (
                        <>
                            <VideoComponent {...props} />
                            <VideoFooter {...props} />
                        </>
                    )
                )}
                <DevBlock />
            </div>
            <QRCode />
            {
                // If there is a modal on state to show, it will be displayed here
                confirmationDialog && <ConfirmationDialog modal={<QuitModal />} isOpen={true} />
            }
        </MeetingLayout>
    )
}

export default MeetingView

export const ConfirmationDialogComponents = {
    QUIT_MEETING: <QuitModal />,
    // Assuming you have other modals, they should be uncommented or added here:
    // NEW_MODAL: <NewModal />,
    // Another possible modal:
    // END_MEETING: <EndModal />,
}
