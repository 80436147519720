import { MeetingRoom, decodeMeetingToken } from '../pages/meeting-view/api'
import { getGeneralToken, getTeamsToken } from './useActions'

import { updateSessionParams } from '../helpers/urls'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useUI } from '../context/UIContext'
import ReactGA from 'react-ga4'

export const useChangeMeeting = () => {
    const navigate = useNavigate()
    const { showToast } = useUI()
    const queryClient = useQueryClient()

    const changeMeeting = async (newRoom: MeetingRoom) => {
        const token = newRoom === 'teams' ? await getTeamsToken({ forceUpdate: true }) : await getGeneralToken()
        if (!token) {
            ReactGA.event({
                category: 'Error',
                action: 'Error moving to new room',
                label: `Token: ${String(token)}`,
            })
            return showToast.error(`No fue posible cambiarte a tu nueva sala. Por favor comunícate con el anfitrión.`)
        }

        const { room, tpc: topic } = decodeMeetingToken(token)
        updateSessionParams({ room, topic })
        queryClient.invalidateQueries({ queryKey: ['Course', 'Timeline'] })
        navigate('/post-meeting?reason=moving')
        await new Promise(resolve => setTimeout(resolve, 2000))
        ReactGA.event({
            category: 'Change Meeting',
            action: 'Success moving to new room',
            label: `Token: ${String(token)}`,
        })
        navigate('/meeting')
    }

    return { changeMeeting }
}
