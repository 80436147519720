import DayJS from 'dayjs'
import duration from 'dayjs/plugin/duration'
import es from 'dayjs/locale/es'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

DayJS.locale(es)

DayJS.extend(isSameOrAfter)
DayJS.extend(isSameOrBefore)
DayJS.extend(duration)
DayJS.extend(timezone)
DayJS.extend(utc)
DayJS.extend(relativeTime)
DayJS.extend(localizedFormat)

export default DayJS
