import ZoomVideo from '@zoom/videosdk'
import { useEffect } from 'react'
import { useZoomContext } from '../../../context/ZoomContext'

export const useOffline = ({
    zoomClient,
    setInfoText,
    join,
}: ReturnType<typeof useZoomContext> & { join: () => Promise<void> }) => {
    useEffect(() => {
        const onLeavePage = async () => {
            await zoomClient.leave()
            ZoomVideo.destroyClient()
        }

        window.addEventListener('beforeunload', onLeavePage)

        return () => window.removeEventListener('beforeunload', onLeavePage)
    }, [zoomClient])

    useEffect(() => {
        const onOnline = () => {
            setInfoText(null)
            // if (!zoomClient.getSessionInfo().isInMeeting) join()
        }
        const onOffline = () => setInfoText('No tienes conexión a internet. Reconectando...')

        window.addEventListener('online', onOnline)
        window.addEventListener('offline', onOffline)

        return () => {
            window.removeEventListener('online', onOnline)
            window.removeEventListener('offline', onOffline)
        }
    }, [join, setInfoText, zoomClient])
}
