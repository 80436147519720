import { useEffect, useState } from 'react'

import DayJS from '../helpers/DayJS'
import { MeetingRoom } from '../pages/meeting-view/api'
import { getSessionParameters } from '../helpers/urls'

export const ChangeMeetingText = ({ room, duration }: { duration: number; room: MeetingRoom }) => {
    const [timer, setTimer] = useState(duration)
    const { room: currentRoom } = getSessionParameters()

    useEffect(() => {
        setTimer(duration)
    }, [duration])

    useEffect(() => {
        const interval = setInterval(() => setTimer(prev => prev - 1000), 1000)
        return () => clearInterval(interval)
    }, [])

    const roomName =
        room === 'general' ? 'sala general' : currentRoom === 'teams' ? 'nueva sala de equipos' : 'sala de equipos'

    const time = DayJS().add(timer, 'milliseconds').toNow(true)

    return (
        <>
            Serás enviado a la {roomName} en {time}.
        </>
    )
}
