import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Participant } from '../../types/video-types'
import ParticipantOptions from '../../../../components/Modals/ParticipantOptions'
import SquareButton from '../../../../components/SquareButton'
import styled from 'styled-components'
import { useHostFunctions } from '../../hooks/useHostFunctions'
import { useState } from 'react'
import { useZoomContext } from '../../../../context/ZoomContext'

type Props = {
    participant: Participant
    currentUser: number
}

const ParticipantItem = ({ participant, currentUser }: Props) => {
    const { isHost, isUserHandRaised } = useZoomContext()
    const { displayName } = participant
    const [showOptions, setShowOptions] = useState(false)
    const { muteParticipant } = useHostFunctions()

    const toggleMenu = () => {
        setShowOptions(!showOptions)
    }

    return (
        <ParticipantItemStyled>
            <div className="flex">
                {isUserHandRaised(participant.userId) && <FontAwesomeIcon icon={['far', 'hand']} />}
                <div className="name">
                    {displayName} {participant.isHost ? '(Anfitrión)' : participant.isManager ? '(Co-anfitrión)' : ''}{' '}
                </div>
            </div>
            {/* Show options to others users but not me */}
            {participant.userId !== currentUser && (
                <div className="options">
                    <SquareButton
                        disabled={!isHost}
                        icon={['far', participant.muted ? 'microphone-slash' : 'microphone']}
                        muted={participant.muted === true || participant.muted === undefined ? true : false}
                        handleClick={() => muteParticipant(participant)}
                    />
                    <div className="dropdown-container">
                        <SquareButton icon={['far', 'ellipsis-vertical']} handleClick={toggleMenu} />
                        {showOptions && (
                            <div className="dropdown-menu">
                                <ParticipantOptions
                                    setShowOptions={setShowOptions}
                                    participant={participant}
                                    isHost={isHost}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </ParticipantItemStyled>
    )
}

export default ParticipantItem

const ParticipantItemStyled = styled.div`
    justify-content: space-between;
    font-size: 14px;
    display: grid;
    grid-template-columns: 4fr auto;
    grid-template-rows: auto;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    min-height: 44px;
    color: white;
    .options {
        display: flex;
    }
    .dropdown-container {
        position: relative;
    }
`
