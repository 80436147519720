import DropdownMenu, { MediaDeviceType } from './Dropdown'

import { AngleButton } from './Mic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaDevice } from '../../types/video-types'
import Tooltip from '../../../../components/Tooltip/Tooltip.component'
import { useState } from 'react'

interface CameraButtonProps {
    isStartedVideo: boolean
    onCameraClick: () => void
    onSwitchCamera: (deviceId: string) => void
    cameraList?: MediaDevice[]
    activeCamera?: string
}

const CameraButton = (props: CameraButtonProps) => {
    const { cameraList = [], activeCamera, isStartedVideo, onCameraClick, onSwitchCamera } = props
    const [dropdownVisible, setDropdownVisible] = useState(false)

    const menuItems = cameraList.map(item => ({ ...item, type: 'camera' }))

    const onMenuItemClick = (payload: MediaDeviceType) =>
        payload.type === 'camera' && onSwitchCamera(payload.deviceId ?? '')

    const toggleDropdown = () => setDropdownVisible(old => !old)

    const tooltipText = !cameraList.length
        ? 'No hay cámaras disponibles'
        : isStartedVideo
        ? 'Apagar cámara'
        : 'Prender cámara'

    return (
        <div>
            {isStartedVideo && !!cameraList.length ? (
                <div style={{ display: 'flex' }}>
                    <Tooltip text={tooltipText} placement="topCenter">
                        <button onClick={onCameraClick}>
                            <FontAwesomeIcon
                                icon={['far', 'video']}
                                cursor="not-allowed"
                                opacity={0.6}
                                color={'#4A4D52'}
                            />
                        </button>
                    </Tooltip>
                    <Tooltip text="Opciones de cámara" placement="topCenter">
                        <AngleButton onClick={toggleDropdown}>
                            <FontAwesomeIcon icon={['far', 'angle-up']} />
                        </AngleButton>
                    </Tooltip>
                    {dropdownVisible && (
                        <DropdownMenu
                            menuItems={menuItems}
                            onMenuItemClick={onMenuItemClick}
                            activeDevices={[activeCamera]}
                            onClose={() => setDropdownVisible(false)}
                        />
                    )}
                </div>
            ) : (
                <Tooltip text={tooltipText} placement="topCenter">
                    <button onClick={onCameraClick}>
                        <FontAwesomeIcon icon={['far', 'camera-slash']} />
                    </button>
                </Tooltip>
            )}
        </div>
    )
}
export default CameraButton
