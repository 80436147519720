import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PropsWithChildren } from 'react'
import { useUI } from '../context/UIContext'

export const NotificationWithCross = ({ children, id }: PropsWithChildren<{ id: string }>) => {
    const { showToast } = useUI()

    return (
        <>
            {children}{' '}
            <FontAwesomeIcon
                icon={['far', 'times']}
                onClick={() => showToast.dismiss(id)}
                style={{
                    cursor: 'pointer',
                    paddingLeft: '0.7em',
                    /* position: 'absolute',
                    right: '10px',
                    top: `50%`,
                    transform: `translateY(-50%)`, */
                    fontSize: `18px`,
                }}
            />
        </>
    )
}
