import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import MeetingView from '../pages/meeting-view/MeetingView'
import PostMeeting from '../pages/post-meeting/PostMeeting'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SocketProvider } from '../context/SocketContext'
import { UIProvider } from '../context/UIContext'
import { ZoomProvider } from '../context/ZoomContext'

const queryClient = new QueryClient()

export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <UIProvider>
                    <ZoomProvider>
                        <SocketProvider>
                            <Routes>
                                <Route path="/meeting" element={<MeetingView />} />
                                <Route path="/post-meeting" element={<PostMeeting />} />
                            </Routes>
                        </SocketProvider>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </ZoomProvider>
                </UIProvider>
            </QueryClientProvider>
        </BrowserRouter>
    )
}
