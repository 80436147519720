import { MediaStream, ZoomClient } from '../types/video-types'
import { MutableRefObject, useCallback, useEffect, useState } from 'react'
import { useMount, useUnmount } from './useUnmount'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import toast from 'react-hot-toast'
import { usePrevious } from './usePrevious'
import { useUI } from '../../../context/UIContext'
import { useZoomContext } from '../../../context/ZoomContext'

export function useShare(
    zoomClient: ZoomClient,
    mediaStream: MediaStream | null,
    shareRef: MutableRefObject<HTMLCanvasElement | HTMLVideoElement | null>
) {
    const [isRecieveSharing, setIsReceiveSharing] = useState(false)
    const { isStartedScreenShare, setIsStartedScreenShare } = useZoomContext()
    const [activeSharingId, setActiveSharingId] = useState(mediaStream?.getActiveShareUserId() ?? 0)
    const [sharedContentDimension, setSharedContentDimension] = useState({ width: 0, height: 0 })
    const [currentUserId, setCurrentUserId] = useState(zoomClient.getSessionInfo().userId)
    const { showToast } = useUI()

    const getShareUserName = useCallback(
        (uid: number) => {
            const user = zoomClient.getAllUser().find(user => user.userId === uid)
            return user ? `${user.displayName}` : ''
        },
        [zoomClient]
    )

    const onActiveShareChange = useCallback(
        ({ state, userId }: any) => {
            setActiveSharingId(userId)
            setIsReceiveSharing(state === 'Active')

            if (state === 'Active') {
                const icon = <FontAwesomeIcon icon={['far', 'screencast']} style={{ color: '#48A0F8' }} />
                showToast(`${getShareUserName(userId)} está compartiendo su pantalla`, {
                    id: `shared-screen-${userId}`,
                    duration: Infinity,
                    icon,
                })
            } else if (state === 'Inactive') {
                toast.dismiss(`shared-screen-${userId}`)
            }
        },
        [getShareUserName, showToast]
    )

    const onPeerShareChange = useCallback(
        (payload: { action: 'Start' | 'Stop'; userId: number }) => {
            const { action, userId } = payload
            const me = zoomClient.getCurrentUserInfo()
            if (action === 'Start') {
                setActiveSharingId(userId)
                const icon = <FontAwesomeIcon icon={['far', 'screencast']} style={{ color: '#48A0F8' }} />
                showToast(`${getShareUserName(userId)} está compartiendo su pantalla`, {
                    id: `shared-screen-${userId}`,
                    duration: Infinity,
                    icon,
                })
            }
            if (userId !== currentUserId) {
                if (action === 'Start') {
                    if (me.sharerOn) {
                        mediaStream?.stopShareScreen().then(() => {
                            toast.dismiss('screen-sharing')
                            mediaStream?.startShareView(shareRef.current as HTMLCanvasElement, userId).catch(() => {})
                        })
                    }
                }
                if (action === 'Stop') {
                    toast.dismiss(`shared-screen-${userId}`)
                }
            } else {
                if (action === 'Start') {
                    const icon = <FontAwesomeIcon icon={['far', 'screencast']} style={{ color: '#48A0F8' }} />
                    showToast(`${getShareUserName(userId)} está compartiendo su pantalla`, {
                        id: `shared-screen-${userId}`,
                        duration: Infinity,
                        icon,
                    })
                }
            }
        },
        [currentUserId, getShareUserName, mediaStream, shareRef, showToast, zoomClient]
    )

    const onSharedContentDimensionChange = useCallback(({ width, height }: any) => {
        setSharedContentDimension({ width, height })
    }, [])

    const onCurrentUserUpdate = useCallback(
        (payload: any) => {
            if (Array.isArray(payload) && payload.length > 0) {
                payload.forEach(item => {
                    if (item.userId === currentUserId && item.sharerOn !== undefined) {
                        setIsStartedScreenShare(item.sharerOn)
                        if (item.sharerOn) {
                            setIsReceiveSharing(false)
                        }
                    }
                })
            }
        },
        [currentUserId, setIsStartedScreenShare]
    )

    const previousIsRecieveSharing = usePrevious(isRecieveSharing)

    useEffect(() => {
        if (!shareRef.current) return
        if (isRecieveSharing)
            mediaStream?.startShareView(shareRef.current as HTMLCanvasElement, activeSharingId).catch(() => {})
        else mediaStream?.stopShareView().catch(() => {})
    }, [mediaStream, shareRef, previousIsRecieveSharing, isRecieveSharing, activeSharingId])

    useEffect(() => {
        if (mediaStream) {
            const activeSharedUserId = mediaStream.getActiveShareUserId()
            if (activeSharedUserId) {
                setIsReceiveSharing(true)
                setActiveSharingId(activeSharedUserId)
                const icon = <FontAwesomeIcon icon={['far', 'screencast']} style={{ color: '#48A0F8' }} />
                showToast(`${getShareUserName(activeSharedUserId)} está compartiendo su pantalla`, {
                    id: `shared-screen-${activeSharedUserId}`,
                    duration: Infinity,
                    icon,
                })
            }
        }
    }, [getShareUserName, mediaStream, showToast])

    useMount(() => {
        setCurrentUserId(zoomClient.getSessionInfo().userId)
    })

    useUnmount(() => {
        if (isRecieveSharing && zoomClient.getSessionInfo().isInMeeting) {
            mediaStream?.stopShareView()
        }
    })

    useEffect(() => {
        zoomClient.on('active-share-change', onActiveShareChange)
        zoomClient.on('share-content-dimension-change', onSharedContentDimensionChange)
        zoomClient.on('user-updated', onCurrentUserUpdate)
        zoomClient.on('peer-share-state-change', onPeerShareChange)
        return () => {
            zoomClient.off('active-share-change', onActiveShareChange)
            zoomClient.off('share-content-dimension-change', onSharedContentDimensionChange)
            zoomClient.off('user-updated', onCurrentUserUpdate)
            zoomClient.off('peer-share-state-change', onPeerShareChange)
        }
    }, [zoomClient, onActiveShareChange, onSharedContentDimensionChange, onCurrentUserUpdate, onPeerShareChange])

    return { isRecieveSharing, isStartedShare: isStartedScreenShare, sharedContentDimension }
}
