import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SidebarModeType } from '../../../../context/ZoomContext'
import Tooltip from '../../../../components/Tooltip/Tooltip.component'

export const WidgetIcon = ({ showSidebar, ...rest }: WidgetIconProps) => (
    <Tooltip text={showSidebar === 'widget' ? 'Ocultar widget' : 'Mostrar widget'} placement="topCenter">
        <button {...rest}>
            <FontAwesomeIcon icon={['far', 'egg']} />
        </button>
    </Tooltip>
)

interface WidgetIconProps extends React.HTMLAttributes<HTMLButtonElement> {
    showSidebar: SidebarModeType
}
