import { TokenPayload } from '../apis/api-egg'
import { getSessionParameters } from '../helpers/urls'
import jwtDecode from 'jwt-decode'

export const useSessionParams = () => {
    const { widgetToken: token, ...params } = getSessionParameters()

    let payload: Partial<TokenPayload> = {}

    try {
        if (token) payload = jwtDecode<TokenPayload>(token)
    } catch (error) {}

    return {
        token,
        ...payload,
        ...params,
    }
}
