import { ApiEgg } from './api-egg'
import { ApiHCA } from './api-hca'
import { ApiSignUp } from './api-signup'

export const setNewToken = (token?: string | boolean) => {
    if (!token) token = false

    ApiHCA.defaults.headers.common.Authorization = token ? `Bearer ${token}` : token
    ApiSignUp.defaults.headers.common.Authorization = token ? `Bearer ${token}` : token
    ApiEgg.defaults.headers.common['access-token'] = token ? `${token}` : token
}

export type Api<T> = {
    data: T
    success: boolean
    message: string
    code: number
}
