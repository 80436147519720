import { SELF_VIDEO_ID } from '../util/video-constants'
import { CaptureVideoOption, Stream } from '@zoom/videosdk'
import { ZoomClient } from '../types/video-types'
import { getItem } from '../../../helpers/localStorage'

export const renderSelfVideo = async (
    zoomClient: ZoomClient,
    mediaStream: typeof Stream
): Promise<any> => {
    if (!zoomClient || !mediaStream) return
    console.log('Render self video')
    try {
        if (mediaStream?.isRenderSelfViewWithVideoElement()) {
            const videoElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLVideoElement
            if (videoElement)
                await mediaStream
                    ?.startVideo({
                        videoElement
                    })
                    .then(() => {
                        console.log('video started with self video element')
                    })
                    .catch((error: any) => {
                        console.log('video error with self video element', error)
                    })
        } else {
            const startVideoOptions: Partial<CaptureVideoOption> = {
                hd: mediaStream.isSupportHDVideo(),
                mirrored: true,
                captureWidth: mediaStream.isSupportHDVideo() ? 1280 : 640,
                captureHeight: mediaStream.isSupportHDVideo() ? 720 : 360
            }
            if (mediaStream?.isSupportVirtualBackground() && getItem('isBlur')) {
                startVideoOptions.virtualBackground = {
                    imageUrl: 'blur',
                }
            }
            await mediaStream
                ?.startVideo(startVideoOptions)
                .then(() => {
                    console.log('video started with self canvas element')
                })
                .catch((error: any) => {
                    console.log('video error with self canvas element', error)
                })


            // Renders video in Self Canvas only if video single has canvasSingleElement
            // This doesnt affect when rendering self on gallery view.
            const canvasSelfElement = document.querySelector(
                `#${SELF_VIDEO_ID}`
            ) as HTMLCanvasElement
            if (canvasSelfElement && !mediaStream?.isSupportMultipleVideos()) {
                await mediaStream
                    ?.renderVideo(
                        canvasSelfElement,
                        zoomClient.getSessionInfo().userId,
                        10,
                        10,
                        0,
                        0,
                        1
                    )
                    .then(() => {
                        console.log('render video from renderSelfVideo.ts')
                    })
                    .catch(() => ({}))
            }
        }
        return {}
    } catch (error) {
        console.error('Error starting video in render Self Video', error)
    }
}
