import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import Tooltip from '../../../../components/Tooltip/Tooltip.component'
import { AngleButton } from './Mic'
import DropdownMenu from './Dropdown'
import { useZoomContext } from '../../../../context/ZoomContext'
import { getItem, setItem } from '../../../../helpers/localStorage'
import { useTimeline } from '../../../../hooks/useTimeline'

type BgType = {
    label: string
    deviceId: string
    key: undefined
    type: 'option'
    scope: 'public' | string[]
}

export const Blur = ({ isFx, setIsFx, ...rest }: BlurProps) => {
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const { mediaStreamGlobal } = useZoomContext()
    const { _courseId } = useTimeline()
    const [bgs, setBgs] = useState<BgType[]>([])

    const menuItems = useMemo<BgType[]>(
        () => [
            {
                label: 'Desenfocar fondo',
                deviceId: 'blur',
                key: undefined,
                type: 'option',
                scope: 'public',
            },
            {
                label: 'Pulse',
                deviceId: 'https://egg-meet-assets.s3.amazonaws.com/virtual-backgrounds/Pulse.jpg',
                key: undefined,
                type: 'option',
                scope: 'public',
            },
            {
                label: 'Network',
                deviceId: 'https://egg-meet-assets.s3.amazonaws.com/virtual-backgrounds/Network.jpg',
                key: undefined,
                type: 'option',
                scope: 'public',
            },
            {
                label: 'NetBalls',
                deviceId: 'https://egg-meet-assets.s3.amazonaws.com/virtual-backgrounds/NetBalls.jpg',
                key: undefined,
                type: 'option',
                scope: 'public',
            },
            {
                label: 'Earth',
                deviceId: 'https://egg-meet-assets.s3.amazonaws.com/virtual-backgrounds/Earth.jpg',
                key: undefined,
                type: 'option',
                scope: 'public',
            },
            {
                label: 'Dot Network',
                deviceId: 'https://egg-meet-assets.s3.amazonaws.com/virtual-backgrounds/Dot-network.jpg',
                key: undefined,
                type: 'option',
                scope: 'public',
            },
            {
                label: 'Creativity',
                deviceId: 'https://egg-meet-assets.s3.amazonaws.com/virtual-backgrounds/Creativity.jpg',
                key: undefined,
                type: 'option',
                scope: 'public',
            },
            {
                label: 'Fondo MELI',
                deviceId: 'https://egg-meet-assets.s3.amazonaws.com/virtual-backgrounds/Meli-Bg.jpg',
                key: undefined,
                type: 'option',
                scope: ['6662246654572e9c2f102199', '65a96c12e4af98f8337e3965'],
            },
            {
                label: 'SREvolution',
                deviceId: 'https://egg-meet-assets.s3.amazonaws.com/virtual-backgrounds/SREvolution.jpg',
                key: undefined,
                type: 'option',
                scope: [
                    '66be2d662552adc2ede2f532',
                    '66be39d52552ad0b17e2f68b',
                    '66be3b1b2552ad1c75e2f694',
                    '66be36002552ad2f38e2f632',
                    '66be38172552ad9a39e2f679',
                    '66be25e32552ad2f48e2f394',
                ],
            },
            {
                label: 'Meli Shipping',
                deviceId: 'https://egg-meet-assets.s3.amazonaws.com/virtual-backgrounds/Meli-Shipping.jpg',
                key: undefined,
                type: 'option',
                scope: [
                    '66b3a4514ba55f4667c39e63',
                    '66c3a99d520920497774007a',
                    '66c3ace55209204dc574029a',
                    '66c3aeb2520920cc4b7402fc',
                    '66c3b0ac520920c5aa740308',
                    '66c3b1ed5209207b5f740311',
                ],
            },
            {
                label: 'Desactivar',
                deviceId: 'disable-effect',
                key: undefined,
                type: 'option',
                scope: 'public',
            },
        ],
        []
    )

    useEffect(() => {
        if (_courseId) {
            const filteredBgs = menuItems.filter(
                bg => bg.scope === 'public' || (Array.isArray(bg.scope) && bg.scope.includes(_courseId))
            )
            setBgs(filteredBgs)
        } else {
            const filteredBgs = menuItems.filter(bg => bg.scope === 'public')
            setBgs(filteredBgs)
        }
    }, [_courseId, menuItems])

    const handleSetFxClick = () => {
        if (!isFx) {
            const vfx: string | undefined = getItem('vfx')
            if (vfx) {
                mediaStreamGlobal?.updateVirtualBackgroundImage(vfx)
                setIsFx(true)
            } else {
                mediaStreamGlobal?.updateVirtualBackgroundImage('blur')
                setIsFx(true)
            }
        } else {
            mediaStreamGlobal?.updateVirtualBackgroundImage(undefined)
            setIsFx(false)
        }
    }

    const onFxClick = async (fx: any) => {
        if (!mediaStreamGlobal) return
        if (fx.deviceId === 'disable-effect') {
            mediaStreamGlobal?.updateVirtualBackgroundImage(undefined)
            setIsFx(false)
            window.localStorage.removeItem('vfx')
            return
        }
        const vbStatus = mediaStreamGlobal?.getVirtualbackgroundStatus()
        if (vbStatus?.isVBPreloadReady) {
            await mediaStreamGlobal?.updateVirtualBackgroundImage(fx.deviceId).then(() => {
                setIsFx(true)
                setItem('vfx', fx.deviceId)
            })
        }
    }

    return (
        <div style={{ display: 'flex' }}>
            <Tooltip text={isFx ? 'Desactivar efectos' : 'Activar efecto'} placement="topCenter">
                <button onClick={handleSetFxClick} {...rest}>
                    <FontAwesomeIcon icon={['far', 'sparkles']} />
                </button>
            </Tooltip>
            <Tooltip text="Efectos visuales" placement="topCenter">
                <AngleButton onClick={() => setDropdownVisible(old => !old)}>
                    <FontAwesomeIcon icon={['far', 'angle-up']} />
                </AngleButton>
            </Tooltip>
            {dropdownVisible && !!bgs.length && (
                <DropdownMenu
                    menuItems={bgs}
                    onMenuItemClick={onFxClick}
                    onClose={() => setDropdownVisible(false)}
                    activeDevices={[getItem('vfx')]}
                />
            )}
        </div>
    )
}

interface BlurProps extends React.HTMLAttributes<HTMLButtonElement> {
    isFx: boolean
    setIsFx: Dispatch<SetStateAction<boolean>>
}
