import { ZoomEvents, sendZoomEvents } from '../api'

import { ZoomClient } from '../types/video-types'
import { parseEvents } from '../util/parseEvents'
import { useEffect } from 'react'
import { usePrevious } from './usePrevious'

export const useZoomEvents = (
    zoomClient: ZoomClient,
    events: Record<string, unknown> & ZoomEvents
) => {
    const previousEvents = usePrevious(events)
    const meetingUUID = zoomClient?.getSessionInfo().sessionId

    useEffect(() => {
        if (!previousEvents) return

        const newEvents = parseEvents(events, key => events[key] !== previousEvents[key])

        if (!Object.keys(newEvents).length) return

        const media = { ...parseEvents(previousEvents), ...newEvents }
        const timestamp = Date.now()

        sendZoomEvents({ media, timestamp, meetingUUID })
    }, [events, meetingUUID, previousEvents])
}
