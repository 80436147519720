import { useEffect, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PrimaryBtnBlue } from './Buttons'
import Styles from './Pip.module.css'
import { Tip } from './Tip'
import { VideoMessage } from '../apis/api-egg'
import { useUI } from '../context/UIContext'
import { useZoomContext } from '../context/ZoomContext'

export const Pip = (props: Props) => {
    const { closePip } = useUI()
    const ref = useRef<HTMLVideoElement | null>(null)
    const { mediaStreamGlobal } = useZoomContext()
    const [participantsAudioEnabled, setParticipantsAudioEnabled] = useState(false)

    const goPip = () => {
        if (document.pictureInPictureElement) document.exitPictureInPicture()
        else if (document.pictureInPictureEnabled) ref.current?.requestPictureInPicture()
    }

    const toggleAudio = async () => {
        if (!mediaStreamGlobal) return
        try {
            if (participantsAudioEnabled) {
                await mediaStreamGlobal?.muteAllUserAudioLocally()
                setParticipantsAudioEnabled(false)
            } else {
                await mediaStreamGlobal?.unmuteAllUserAudioLocally()
                setParticipantsAudioEnabled(true)
            }
        } catch (error) {
            console.error('Unable to resume audio on users')
        }
    }

    useEffect(() => {
        const video = ref.current
        if (!video) return

        video.addEventListener('ended', closePip)

        return () => video.removeEventListener('ended', closePip)
    }, [closePip])

    return (
        <section className={Styles.pip}>
            <video ref={ref} autoPlay controls width={800} {...props} />

            <span className={`${Styles.audioNotice} ${participantsAudioEnabled ? Styles.enabled : ''}`}>
                Sonido de participantes {participantsAudioEnabled ? ' habilitado' : ' deshabilitado'}
            </span>

            <div className={Styles.controls}>
                <PrimaryBtnBlue data-tooltip-id="pip-close" className="primary" onClick={closePip}>
                    <FontAwesomeIcon icon={['fas', 'times']} />
                </PrimaryBtnBlue>
                <Tip id="pip-close" content="Cerrar video" place={'left'} />

                <PrimaryBtnBlue data-tooltip-id="pip-pip" className="primary" onClick={goPip}>
                    <FontAwesomeIcon icon={['fas', 'arrow-up-right-from-square']} />
                </PrimaryBtnBlue>
                <Tip id="pip-pip" content="Modo Picture-in-Picture" place={'left'} />

                <PrimaryBtnBlue data-tooltip-id="pip-enable-audio" className="primary" onClick={toggleAudio}>
                    <FontAwesomeIcon
                        icon={['far', participantsAudioEnabled ? 'volume' : 'volume-slash']}
                        color={participantsAudioEnabled ? 'white' : 'rgba(255, 100, 124, 0.8)'}
                    />
                </PrimaryBtnBlue>
                <Tip
                    id="pip-enable-audio"
                    content={`${
                        participantsAudioEnabled ? 'Deshabilitar' : 'Habilitar'
                    } el sonido de los participantes`}
                    place={'left'}
                />
            </div>
        </section>
    )
}

export type PipType = Omit<VideoMessage, 'type'> & { onClose?: () => unknown }
type Props = Omit<PipType, 'onClose'>
