import { CountDown } from '../../../components/CountDown'
import DayJS from '../../../helpers/DayJS'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getSessionParameters } from '../../../helpers/urls'
import { useTimeline } from '../../../hooks/useTimeline'
import { useZoomContext } from '../../../context/ZoomContext'

export const RoomName = () => {
    const { room, topic } = getSessionParameters()
    const { nextEvent } = useTimeline()
    const { isRecording } = useZoomContext()

    const teamNumber = Number(topic?.split('@')[1]?.split('_').at(0)?.replace('t', '')) + 1
    const roomName = room === 'teams' ? `Equipo ${teamNumber}` : 'General'

    return (
        <li style={{ paddingLeft: 0, width: 'max-content', display: 'flex', alignItems: 'center' }}>
            <span
                style={{
                    padding: '5px 10px',
                    borderRadius: '7px',
                    color: 'white',
                    fontWeight: '600',
                    textAlign: 'center',
                }}
            >
                {roomName}
                {nextEvent && (
                    <div>
                        <CountDown duration={DayJS(nextEvent.date).diff(DayJS(), 'milliseconds')} />
                    </div>
                )}
            </span>
            {isRecording && <FontAwesomeIcon icon={['fas', 'circle']} style={{ color: '#FF647C', fontSize: '10px' }} />}
        </li>
    )
}
