import Axios from 'axios'

export const ApiHCA = Axios.create({
    baseURL: process.env.REACT_APP_HCA_API,
})

export const getMeetingTokens = async (algorithm_id?: string) =>
    ApiHCA.post<GetTokensDTO>('/sdk_session/get_sdk_tokens', {
        algorithm_id,
    }).then(({ data }) => data)

interface GetTokensDTO {
    general_room?: {
        // Undefined if the course's settings are not set to use the general room
        token: string
    }
    teams_room?: {
        // Undefined if there is a not valid algorithm_id
        token: string
        table_number: number
    }
}
