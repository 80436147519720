export const getItem = <T = unknown>(key: string): T => {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : null
}
export const getSessionItem = <T = unknown>(key: string): T => {
    const value = sessionStorage.getItem(key)
    return value ? JSON.parse(value) : null
}

export const setItem = (key: string, value: unknown) => localStorage.setItem(key, JSON.stringify(value))
export const setSessionItem = (key: string, value: unknown) => sessionStorage.setItem(key, JSON.stringify(value))

export const removeItem = (key: string) => {
    localStorage.removeItem(key)
}
