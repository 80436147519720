import { getSessionParameters } from '../../../helpers/urls'
import { useNavigate } from 'react-router-dom'

export const useReJoin = () => {
    const navigate = useNavigate()

    return () => {
        const urlParams = getSessionParameters()
        const encodedParams = new URLSearchParams(urlParams as Record<string, string>).toString()
        navigate(`/meeting?${encodedParams}`)
    }
}
