import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Tooltip from '../../../../components/Tooltip/Tooltip.component'
import { useZoomContext } from '../../../../context/ZoomContext'

export const ChatIcon = (props: ChatIconProps) => {
    const { sidebarMode, unReadMessages } = useZoomContext()

    return (
        <Tooltip text={sidebarMode === 'messages' ? 'Ocultar mensajes' : 'Mostrar mensajes'} placement="topCenter">
            <button {...props}>
                <FontAwesomeIcon icon={['far', 'message-dots']} />
            </button>
            {unReadMessages && (
                <FontAwesomeIcon
                    icon={['fas', 'circle']}
                    style={{
                        color: '#FF647C',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        fontSize: '12px',
                    }}
                />
            )}
        </Tooltip>
    )
}

interface ChatIconProps extends React.HTMLAttributes<HTMLButtonElement> {}
