import './index.css'

import React, { Suspense } from 'react'

import App from './App'
import LoadingPage from './pages/static/LoadingPage'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Suspense fallback={<LoadingPage />}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Suspense>
)

reportWebVitals()
