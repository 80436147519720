import { useCallback, useEffect, useState } from 'react'

import { Dimension } from '../types/video-types'
import { MAX_NUMBER_PER_PAGE } from '../util/video-constants'
import { ZoomClient } from '../types/video-types'
import { maxViewportVideoCounts } from '../helpers/video-layout-helper'
import { useMount } from '../hooks/useUnmount'

// eslint-disable-next-line import/prefer-default-export
export function usePagination(zoomClient: ZoomClient, dimension: Dimension) {
    const [page, setPage] = useState(0)
    const [totalSize, setTotalSize] = useState(0)
    const [pageSize, setPageSize] = useState(MAX_NUMBER_PER_PAGE)

    useEffect(() => {
        const size = Math.min(MAX_NUMBER_PER_PAGE, maxViewportVideoCounts(dimension.width, dimension.height))
        setPageSize(size)
    }, [dimension])

    const onParticipantsChange = useCallback(() => {
        const newTotalSize = zoomClient.getAllUser().length
        setTotalSize(newTotalSize)
        // Check if the current page should be reset to the first page
        if (newTotalSize <= page * pageSize) {
            setPage(0) // Reset to the first page if the current page has no participants
        }
    }, [zoomClient, page, pageSize])

    useEffect(() => {
        zoomClient.on('user-added', onParticipantsChange)
        zoomClient.on('user-removed', onParticipantsChange)
        zoomClient.on('user-updated', onParticipantsChange)
        return () => {
            zoomClient.off('user-added', onParticipantsChange)
            zoomClient.off('user-removed', onParticipantsChange)
            zoomClient.off('user-updated', onParticipantsChange)
        }
    }, [zoomClient, onParticipantsChange])

    useMount(() => {
        setTotalSize(zoomClient.getAllUser().length)
    })
    return {
        page,
        totalPage: Math.ceil(totalSize / pageSize),
        pageSize,
        totalSize,
        setPage,
    }
}
