import { ChatUserItem } from '@zoom/videosdk'
import React from 'react'
import { capitalize } from '../../../../../helpers/formatters'
import { setSessionItem } from '../../../../../helpers/localStorage'
import styled from 'styled-components'

export const SelectReceiver = ({ receivers, state: [receiverId, setReceiverId] }: Props) => (
    <Section>
        <b>ENVIAR A</b>
        <select
            onChange={({ target: { value } }) => {
                setReceiverId(+value)
                setSessionItem('receiverId', value)
            }}
            value={receiverId}
        >
            {receivers.map(item => (
                <option key={item.userId} value={item.userId}>
                    {capitalize(item.displayName)}
                </option>
            ))}
        </select>
    </Section>
)

const Section = styled.section`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-radius: 14px 14px 0 0;
    background-color: #f4f4f4;
    height: 50px;
    padding: 0 10px;
    background-color: ${props => props.theme.color.sidebar.gray3};
    /* border: 1px solid ${props => props.theme.color.sidebar.gray2}; */
    color: white;
    b {
        font-size: 10px;
        color: #3a3c40;
        color: white;
        min-width: 47px;
    }
    select {
        margin: 0 10px;
        width: 100%;
        border: none;
        border-radius: 6px;
        outline: none;
        /* background-color: transparent; */
        font-family: 'Poppins', sans-serif;
        padding: 5px;
        color: white;
        background-color: ${props => props.theme.color.sidebar.gray1};
    }
`

interface Props {
    receivers: ChatUserItem[]
    state: [number, React.Dispatch<React.SetStateAction<number>>]
}
