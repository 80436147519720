import { CellLayout, Dimension, Pagination } from '../types/video-types'
import { MediaStream, Participant, ZoomClient } from '../types/video-types'
import { MutableRefObject, useCallback, useEffect, useState } from 'react'

import { getVideoLayout } from '../helpers/video-layout-helper'
import { useParticipantsChange } from './useParticipantsChange'
import { useRenderVideo } from '../hooks/useRenderVideo'

/**
 * Default order of video:
 *  1. video's participants first
 *  2. self on the second position
 */
export function useGalleryLayout(
    zoomClient: ZoomClient,
    mediaStream: MediaStream | null,
    isVideoDecodeReady: boolean,
    videoRef: MutableRefObject<HTMLCanvasElement | null>,
    dimension: Dimension,
    pagination: Pagination,
    activeSpeakerId?: number
) {
    const [visibleParticipants, setVisibleParticipants] = useState<Participant[]>([])
    const [layout, setLayout] = useState<CellLayout[]>([])
    const [subscribedVideos, setSubscribedVideos] = useState<number[]>([])
    const { page, pageSize, totalPage, totalSize } = pagination
    const size = page === totalPage - 1 ? Math.min(pageSize, totalSize % pageSize || pageSize) : pageSize

    const onParticipantsChange = useCallback(
        (participants: Participant[]) => {
            const currentUser = zoomClient.getCurrentUserInfo()
            if (currentUser && participants.length > 0) {
                let pageParticipants: Participant[] = participants

                // Find the active speaker in the participants list
                const activeSpeaker = participants.find(participant => participant.userId === activeSpeakerId)

                if (participants.length === 1) {
                    pageParticipants = participants
                } else {
                    // If the active speaker is not the current user and not on the first page, add them to the start
                    if (activeSpeaker && activeSpeaker.userId !== currentUser.userId) {
                        const activeSpeakerIndex = pageParticipants.findIndex(user => user.userId === activeSpeakerId)
                        if (Math.floor(activeSpeakerIndex / pageSize) !== 0) {
                            // Remove the active speaker from their current position
                            pageParticipants.splice(activeSpeakerIndex, 1)
                            // Add the active speaker to the start
                            pageParticipants.unshift(activeSpeaker)
                        }
                    }

                    // Filter out the current user and sort by video status
                    pageParticipants = participants
                        .filter(user => user.userId !== currentUser.userId)
                        .sort((user1, user2) => Number(user2.bVideoOn) - Number(user1.bVideoOn))
                    // Add the current user at the second position
                    pageParticipants.splice(1, 0, currentUser)

                    // Filter participants for the current page
                    pageParticipants = pageParticipants.filter((_, index) => Math.floor(index / pageSize) === page)
                }

                setVisibleParticipants(pageParticipants)

                const videoParticipants = pageParticipants.filter(user => user.bVideoOn).map(user => user.userId)
                setSubscribedVideos(videoParticipants)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [zoomClient, page, pageSize, activeSpeakerId]
    )

    useEffect(() => {
        setLayout(getVideoLayout(dimension.width, dimension.height, size))
    }, [dimension, size])

    useEffect(() => {
        if (zoomClient) onParticipantsChange(zoomClient.getAllUser())
    }, [zoomClient, onParticipantsChange, page])

    useParticipantsChange(zoomClient, onParticipantsChange)

    useRenderVideo(
        mediaStream,
        isVideoDecodeReady,
        videoRef,
        layout,
        subscribedVideos,
        visibleParticipants,
        zoomClient.getCurrentUserInfo()?.userId
    )
    return {
        visibleParticipants,
        layout,
    }
}
