import './video.scss'

import { MediaContext, ZoomClient } from '../../types/video-types'
import { useCallback, useEffect, useRef, useState } from 'react'

import Avatar from '../../../../components/AvatarZoom'
import { CaptureVideoOption } from '@zoom/videosdk'
import Pagination from '../Pagination/Pagination'
import ReactGA from 'react-ga4'
import _ from 'lodash'
import classnames from 'classnames'
import { getItem } from '../../../../helpers/localStorage'
import { isShallowEqual } from '../../util/util'
import styled from 'styled-components'
import { useActiveSpeaker } from '../../hooks/useActiveVideo'
import { useCanvasDimension } from '../../hooks/useCanvasDimension'
import { useGalleryLayout } from '../../hooks/useGalleryLayout'
import { useNetworkQuality } from '../../hooks/useNetworkQuality'
import { usePagination } from '../../hooks/usePagination'
import { useShare } from '../../hooks/useShare'
import { useSizeCallback } from '../../hooks/useSizeCallback'
import { useZoomContext } from '../../../../context/ZoomContext'

type Props = {
    infoText?: string | null
    zoomClient: ZoomClient
    mediaContext: MediaContext
    selfShareRef: any
}

const VideoSAB = ({ infoText, zoomClient, mediaContext, selfShareRef }: Props) => {
    const {
        mediaStream,
        video: { decode: isVideoDecodeReady },
    } = mediaContext

    const videoRef = useRef<HTMLCanvasElement | null>(null)
    const shareRef = useRef<HTMLCanvasElement | null>(null)
    const { setIsVideoStarted, setIsStartingVideo, isStartingVideo } = useZoomContext()
    const shareContainerRef = useRef<HTMLDivElement | null>(null)
    const [containerDimension, setContainerDimension] = useState({
        width: 0,
        height: 0,
    })
    const [shareViewDimension, setShareViewDimension] = useState({
        width: 0,
        height: 0,
    })
    const canvasDimension = useCanvasDimension(mediaStream, videoRef)
    const activeSpeakerId = useActiveSpeaker(zoomClient)

    const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zoomClient, canvasDimension)
    const { isRecieveSharing, isStartedShare, sharedContentDimension } = useShare(zoomClient, mediaStream, shareRef)

    const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
        zoomClient,
        mediaStream,
        isVideoDecodeReady,
        videoRef,
        canvasDimension,
        {
            page,
            pageSize,
            totalPage,
            totalSize,
            setPage,
        },
        activeSpeakerId
    )
    const networkQuality = useNetworkQuality(zoomClient)

    const isSharing = isRecieveSharing || isStartedShare

    useEffect(() => {
        ReactGA.event('Video SAB rendered')
    }, [])

    useEffect(() => {
        if (isSharing && shareContainerRef.current) {
            const { width, height } = sharedContentDimension
            const { width: containerWidth, height: containerHeight } = containerDimension
            const ratio = Math.min(containerWidth / width, containerHeight / height, 1)
            setShareViewDimension({
                width: Math.floor(width * ratio),
                height: Math.floor(height * ratio),
            })
        }
    }, [isSharing, sharedContentDimension, containerDimension])

    const onShareContainerResize = useCallback(({ width, height }: { width: number; height: number }) => {
        _.throttle(() => {
            setContainerDimension({ width, height })
        }, 50)()
    }, [])

    useSizeCallback(shareContainerRef.current, onShareContainerResize)

    useEffect(() => {
        if (!isShallowEqual(shareViewDimension, sharedContentDimension)) {
            mediaStream?.updateSharingCanvasDimension(shareViewDimension.width, shareViewDimension.height)
        }
    }, [mediaStream, sharedContentDimension, shareViewDimension])

    const startVideo = useCallback(async () => {
        if (!mediaStream) return
        if (isStartingVideo) return
        // if (zoomClient.getCurrentUserInfo().bVideoOn) return
        try {
            setIsStartingVideo(true)
            const startVideoOptions: Partial<CaptureVideoOption> = {
                mirrored: true,
                hd: true,
                fullHd: true,
            }
            const vfx: string | undefined = getItem('vfx')
            if (mediaStream?.isSupportVirtualBackground() && vfx) {
                startVideoOptions.virtualBackground = {
                    imageUrl: vfx,
                }
            }
            await mediaStream
                ?.startVideo(startVideoOptions)
                .then(() => {
                    console.log('Start video success with res:', mediaStream.getCapturedVideoResolution())
                })
                .catch(console.error)
            setIsVideoStarted(true)
        } catch (error: any) {
            setIsVideoStarted(false)
            ReactGA.event({
                category: 'Error',
                action: 'Error auto-starting video',
                label: error,
            })
        } finally {
            setIsStartingVideo(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaStream, setIsVideoStarted, zoomClient])

    useEffect(() => {
        mediaStream && startVideo()
        ReactGA.event('Video SAB rendered')
    }, [mediaStream, startVideo])

    return (
        <VideoContainer>
            {infoText && <span className="status-text">{infoText}</span>}
            <Viewport>
                <div className={`share-container ${isSharing ? 'in-sharing' : ''}`} ref={shareContainerRef}>
                    <div
                        className="share-container-viewport"
                        style={{
                            width: `${shareViewDimension.width}px`,
                            height: `${shareViewDimension.height}px`,
                        }}
                    >
                        <div style={{ display: isStartedShare ? 'none' : 'block' }}>
                            <canvas className={`share-canvas ${isStartedShare ? 'hidden' : ''}`} ref={shareRef} />
                        </div>
                        {mediaStream?.isStartShareScreenWithVideoElement() ? (
                            <video
                                className={classnames('share-canvas', {
                                    hidden: isRecieveSharing,
                                })}
                                ref={selfShareRef}
                            />
                        ) : (
                            <canvas
                                className={classnames('share-canvas', {
                                    hidden: isRecieveSharing,
                                })}
                                ref={selfShareRef}
                            />
                        )}
                    </div>
                </div>
                <div className={classnames('video-container', { 'in-sharing': isSharing })}>
                    <canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} />
                    <ul className="avatar-list">
                        {visibleParticipants.map((user, index) => {
                            if (index > videoLayout.length - 1) return null
                            const dimension = videoLayout[index]
                            const { width, height, x, y } = dimension
                            const { height: canvasHeight } = canvasDimension
                            return (
                                <Avatar
                                    participant={user}
                                    key={user.userId}
                                    isActive={activeSpeakerId === user.userId}
                                    zoomClient={zoomClient}
                                    networkQuality={networkQuality[`${user.userId}`]}
                                    style={{
                                        width: `${width - 10}px`,
                                        height: `${height}px`,
                                        top: `${canvasHeight - y - height}px`,
                                        left: `${x + 5}px`,
                                        borderRadius: '5px',
                                    }}
                                />
                            )
                        })}
                    </ul>
                </div>
                {totalPage > 1 && (
                    <Pagination page={page} totalPage={totalPage} setPage={setPage} inSharing={isSharing} />
                )}
            </Viewport>
        </VideoContainer>
    )
}

export default VideoSAB

export const VideoContainer = styled.div`
    .status-text {
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        font-weight: bold;
        border: 1px solid black;
        /* border-radius: 5px; */
        width: fit-content;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        z-index: 1000;
        position: absolute;
        top: 0;
        text-align: center;
    }
`

export const Viewport = styled.div`
    position: relative;
    box-sizing: border-box;
    background-color: rgb(20, 20, 20);
    padding: 20px;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    .share-container {
        display: none;
        &.in-sharing {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }
        .share-container-viewport {
            display: inline-block;
            max-width: 100%;
        }
        .share-canvas {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            &.hidden {
                display: none;
            }
        }
    }

    .video-container {
        position: relative;
        width: 100%;
        height: 100%;
        // background: red;

        &.in-sharing {
            width: 264px;
            flex-shrink: 0;
            border-left: 1px solid #333;
        }

        .video-canvas {
            width: 100%;
            height: 100%;
            // background-color: yellow;
        }

        .self-video {
            position: absolute;
            width: 254px;
            height: 143px;
            top: 50px;
            right: 30px;
            z-index: 2;
            display: none;

            &.single-self-video {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }

            &.self-video-show {
                display: block;
            }
        }
    }

    .avatar-list {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        overflow: hidden;
        padding: 0;
        margin: 0;
    }

    .video-operations {
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .avatar-wrap {
        position: absolute;
        pointer-events: auto;
        list-style: none;
        top: 0;
        left: 0;
    }

    .avatar-wrap-dragging {
        z-index: 10;
    }

    .single-view-avatar {
        top: 0;
        left: 0;
    }

    .self-video-non-sab {
        display: none;
        position: absolute;
        z-index: 1;
    }
`
