import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '../../../../components/Tooltip/Tooltip.component'
import { useCallback, useEffect, useRef, useState } from 'react'
import { AngleButton } from './Mic'
import { DropdownStyled, StyledButton } from './Dropdown'
import { useZoomContext } from '../../../../context/ZoomContext'
import { useHostFunctions } from '../../hooks/useHostFunctions'
import { SharePrivilege } from '@zoom/videosdk'

interface ScreenShareButtonProps {
    isStartedScreenShare: boolean
    onScreenShareClick: () => void
}

export enum SharePermissionType {
    SHARE_ALL = 'Permitir que todos los participantes compartan',
    SHARE_COACH = 'Permitir que solo el coach comparta',
}

const ScreenShareButton = (props: ScreenShareButtonProps) => {
    const { isStartedScreenShare, onScreenShareClick } = props
    const { mediaStreamGlobal, isHostOrManager } = useZoomContext()
    const { switchSharePermissions } = useHostFunctions()
    const [dropdownVisible, setDropdownVisible] = useState(false)

    const menuItems = [SharePermissionType.SHARE_ALL, SharePermissionType.SHARE_COACH]

    const getActiveOption = () => {
        const privilege = mediaStreamGlobal?.getSharePrivilege()
        if (privilege === SharePrivilege.Locked) {
            return SharePermissionType.SHARE_COACH
        }
        return SharePermissionType.SHARE_ALL
    }

    const [activeOption, setActiveOption] = useState(getActiveOption())
    const toggleDropdown = () => setDropdownVisible(old => !old)
    const ref = useRef<HTMLDivElement | null>(null)

    const onMenuItemClick = (item: SharePermissionType) => {
        setActiveOption(item)
        switchSharePermissions(item)
    }

    const onClose = useCallback(() => {
        setDropdownVisible(false)
    }, [])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) =>
            ref.current && !ref.current.contains(event.target as Node) && onClose?.()

        document.addEventListener('click', handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [onClose])

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <Tooltip text={isStartedScreenShare ? 'Dejar de compartir' : 'Compartir pantalla'} placement="topCenter">
                <button onClick={onScreenShareClick}>
                    <FontAwesomeIcon icon={['far', 'screencast']} />
                </button>
            </Tooltip>
            {isHostOrManager && (
                <Tooltip text="Opciones de compartir" placement="topCenter">
                    <AngleButton onClick={toggleDropdown}>
                        <FontAwesomeIcon icon={['far', 'angle-up']} />
                    </AngleButton>
                </Tooltip>
            )}
            {dropdownVisible && isHostOrManager && (
                <DropdownStyled visible ref={ref}>
                    <ul>
                        <div className="devices-group">
                            <div className="dropdown-label">
                                <span>
                                    <strong>Opciones de uso compartido</strong>
                                </span>
                            </div>
                            {menuItems.map((item, index) => {
                                return (
                                    <li key={item ?? index}>
                                        <StyledButton
                                            className={`${activeOption === item ? 'active' : ''}`}
                                            onClick={() => onMenuItemClick(item)}
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'left',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {item}
                                                {activeOption === item && <FontAwesomeIcon icon={['fas', 'check']} />}
                                            </div>
                                        </StyledButton>
                                    </li>
                                )
                            })}
                        </div>
                    </ul>
                </DropdownStyled>
            )}
        </div>
    )
}

interface ScreenShareLockButtonProps {
    isLockedScreenShare: boolean
    onScreenShareLockClick: () => void
}

const ScreenShareLockButton = (props: ScreenShareLockButtonProps) => {
    const { isLockedScreenShare, onScreenShareLockClick } = props

    return (
        <Tooltip
            text={isLockedScreenShare ? 'unlock screen share' : ' lock screen share'}
            placement="topCenter"
        >
            <button onClick={onScreenShareLockClick}>Lock screen</button>
        </Tooltip>
    )
}

export { ScreenShareButton, ScreenShareLockButton }
