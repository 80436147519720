import { MessageHeader, MessagesStyled } from './Messages'
import { TextMessage, VideoMessage } from '../../../../apis/api-egg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Item } from './Chat/ChatItem'
import Styles from './Messages.module.css'
import { useUI } from '../../../../context/UIContext'
import { useZoomContext } from '../../../../context/ZoomContext'

export const Diffusion = () => {
    const { diffusion } = useZoomContext()

    return (
        <MessagesStyled>
            <main>
                {diffusion.map(item => (
                    <Item key={item.timestamp}>
                        <MessageHeader {...item} />
                        {item.type === 'video' ? <Video {...item} /> : item.type === 'text' ? <Text {...item} /> : null}
                    </Item>
                ))}
            </main>
        </MessagesStyled>
    )
}

const Video = (video: VideoMessage) => {
    const { openPip } = useUI()

    return (
        <div className={Styles['video-message']} onClick={() => openPip(video)}>
            <FontAwesomeIcon icon={['fas', 'circle-play']} />
            Mensaje de video
        </div>
    )
}

const Text = ({ message, ...rest }: TextMessage) => <p {...rest}>{message}</p>
