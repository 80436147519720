import { useCallback, useEffect, useRef, useState } from 'react'

export const useTimer = ({ initTime, enabled, onFinish }: TimerProps) => {
    const [time, setTime] = useState(initTime)
    const [isActive, setIsActive] = useState(!!enabled)
    const intervalRef = useRef<NodeJS.Timeout>()

    const start = () => {
        if (isActive || time < 0 || intervalRef.current) return
        setIsActive(true)
        intervalRef.current = setInterval(() => setTime(old => old - 1), 1000)
    }

    const stop = useCallback(() => {
        if (!isActive) return
        clearInterval(intervalRef.current)
        intervalRef.current = undefined
        setIsActive(false)
    }, [isActive])

    const reset = useCallback(() => {
        setTime(initTime)
        stop()
    }, [initTime, stop])

    useEffect(() => {
        if (time !== 0 || !isActive) return
        onFinish?.()
        stop()
    }, [time, stop, onFinish, isActive])

    // Effect to clean up the interval on unmount
    useEffect(() => stop, [stop])

    return { time, isActive, start, stop, reset }
}

export interface TimerProps {
    initTime: number // [seconds]
    enabled?: boolean
    onFinish?: () => void
}
