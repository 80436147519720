import { ConfirmationDialogStyled } from './ConfirmationDialog'
import { DangerButton, PrimaryBtnOutline } from '../Buttons'
import { useZoomContext } from '../../context/ZoomContext'
import { useNavigate } from 'react-router-dom'

const QuitModal = () => {
    const { zoomClient, setConfirmationDialog } = useZoomContext()
    const navigate = useNavigate()

    const handleQuit = async () => {
        setConfirmationDialog(undefined)
        await zoomClient.leave(true)
        navigate('/post-meeting')
    }

    return (
        <ConfirmationDialogStyled>
            <div className="info">
                <h2>Salir del encuentro</h2>
                <div>
                    <p>
                        No hay asignado un co-host. Al salir de la reunión el encuentro se cerrará para todos los
                        participantes.
                    </p>
                    <p>¿Estas seguro que deseas salir de la reunión?</p>
                </div>
            </div>
            <div className="actions">
                <DangerButton onClick={handleQuit}>Salir</DangerButton>
                <PrimaryBtnOutline onClick={() => setConfirmationDialog(undefined)} colorTheme={'light'}>
                    Volver
                </PrimaryBtnOutline>
            </div>
        </ConfirmationDialogStyled>
    )
}

export default QuitModal
