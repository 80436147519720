import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { useParticipants } from '../Participants/useParticipants'

type Props = {
    title: string
    handleClick: () => void
}

const SidebarHeader = ({ title, handleClick }: Props) => {
    const { totalParticipants } = useParticipants()

    return (
        <SidebarHeaderStyled>
            <div className="title">
                <div>{title}</div>
                <div className="connected">{`Total conectados: ${totalParticipants}`}</div>
            </div>
            <div className="close">
                <button onClick={handleClick}>
                    <FontAwesomeIcon icon={['fas', 'times']} />
                </button>
            </div>
        </SidebarHeaderStyled>
    )
}

export default SidebarHeader

const SidebarHeaderStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.color.sidebar.gray1};
    padding: 8px 10px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.05rem;
    font-size: 13px;
    color: white;
    .title {
        flex: 1;
        text-align: center;
        .connected {
            color: ${props => props.theme.color.sidebar.gray3};
            font-size: 11px;
        }
    }
    .close {
        button {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 18px;
            color: white;
        }
    }
`
