import { useEffect, useRef, useState } from 'react'

export const useBottom = <T extends HTMLElement>(...dependencies: unknown[]) => {
    const ref = useRef<T>(null)
    const [isNearBottom, setIsNearBottom] = useState(true)

    const scrollToBottom = () => {
        if (ref.current) {
            const { scrollHeight, clientHeight } = ref.current
            ref.current.scrollTop = scrollHeight - clientHeight
        }
    }

    const checkIfNearBottom = () => {
        if (ref.current) {
            const { scrollTop, scrollHeight, clientHeight } = ref.current
            const bottomThreshold = 100 // pixels from bottom
            setIsNearBottom(scrollTop + clientHeight >= scrollHeight - bottomThreshold)
        }
    }

    useEffect(() => {
        const element = ref.current
        if (element) {
            element.addEventListener('scroll', checkIfNearBottom)
            return () => element.removeEventListener('scroll', checkIfNearBottom)
        }
    }, [])

    useEffect(() => {
        if (isNearBottom) {
            scrollToBottom()
        }
    }, [dependencies, isNearBottom])

    return ref
}
