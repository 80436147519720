import './pagination.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from 'react'

interface PaginationProps {
    page: number
    totalPage: number
    setPage: (page: number) => void
    inSharing?: boolean
}

const Pagination = (props: PaginationProps) => {
    const { page, totalPage, setPage, inSharing } = props

    const toPreviousPage = useCallback(() => {
        if (page > 0) {
            setPage(page - 1)
        }
    }, [page, setPage])

    const toNextPage = useCallback(() => {
        if (page < totalPage - 1) {
            setPage(page + 1)
        }
    }, [page, totalPage, setPage])

    // Determine button visibility
    const isPreviousHidden = page <= 0 ? 'hidden' : ''
    const isNextHidden = page >= totalPage - 1 ? 'hidden' : ''

    return (
        <div className={`pagination ${inSharing ? 'in-sharing' : ''}`}>
            <button
                key="left"
                className={`previous-page-button ${isPreviousHidden}`}
                onClick={toPreviousPage}
            >
                <FontAwesomeIcon className="pag-button" icon={['fas', 'angle-left']} />
            </button>
            <button key="right" className={`next-page-button ${isNextHidden}`} onClick={toNextPage}>
                <FontAwesomeIcon className="pag-button" icon={['fas', 'angle-right']} />
            </button>
        </div>
    )
}

export default Pagination
