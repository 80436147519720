import { ComponentProps } from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import ReactModal from 'react-modal'
import styled from 'styled-components'

const DEFAULT_QR_SIZE = 350

ReactModal.setAppElement('#root')

export const QRCode = ({ qrURL, size = DEFAULT_QR_SIZE, ...rest }: QRCodeProps) => (
    <ReactModal isOpen={!!qrURL} style={getQRStyles(size)} {...rest}>
        <QRCodeCanvas value={qrURL!} size={size} level="L" {...rest} />
        <P>Escanea el código QR para unirte al encuentro desde tu dispositivo móvil.</P>
    </ReactModal>
)

const getQRStyles = (size: number) =>
    ({
        content: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 'auto',
            borderRadius: '10px',
            padding: 25,
            width: size,
            height: size + 100,
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
    } as const)

type QRCodeProps = Omit<ReactModal.Props, 'isOpen'> &
    Omit<ComponentProps<typeof QRCodeCanvas>, 'value'> & {
        qrURL: string | null
    }

const P = styled.p`
    text-align: center;
`
