import { useState } from 'react'
import { useZoomContext } from '../../../context/ZoomContext'
import { sendQosFeedback } from '../api'

const INITIAL_SCORE = 0

export const useScore = () => {
    const [maxScore, setMaxScore] = useState(INITIAL_SCORE)
    const [scoreSent, setScoreSent] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [scoreSelected, setScoreSelected] = useState(false)
    const { currentSessionInfo, currentUserInfo } = useZoomContext()

    const sendScore = async (props?: SendScoreProps) => {
        if (!props) return

        const isComment = 'comment' in props
        const comment = isComment ? props.comment : null
        const score = 'score' in props ? props.score : maxScore

        if (score < 1) return

        setMaxScore(score)
        setScoreSelected(true)

        if (!isComment) return // Allow user to add a comment for any score

        setIsLoading(true)

        sendQosFeedback({
            profile_id: currentUserInfo?.userIdentity ?? '',
            session_id: currentSessionInfo?.sessionId ?? '',
            score,
            comment,
        })
            .catch(() => {})
            .finally(() => {
                setScoreSent(true)
                setIsLoading(false)
            })
    }

    const resetScore = () => !scoreSelected && setMaxScore(INITIAL_SCORE)

    return {
        maxScore,
        chooseScore: (value: React.SetStateAction<number>) => !scoreSelected && setMaxScore(value),
        scoreSent,
        scoreSelected,
        isLoading,
        sendScore,
        resetScore,
    }
}

export type SendScoreProps = { comment: string | null } | { score: number }
