import { Toaster } from 'react-hot-toast'

export const AppToaster = () => (
    <Toaster
        containerStyle={{
            top: '2.12rem',
        }}
        toastOptions={{
            className: 'toast-content',
            style: {
                color: '#FFF',
                fontSize: '15px',
                fontWeight: 600,
                borderRadius: '0.625rem',
                border: '1px solid #414141',
                background: 'rgba(47, 48, 50, 0.7)',
                backdropFilter: 'blur(4px)',
                width: 'max-content',
                maxWidth: '100%',
                padding: '5px 10px',
            },
        }}
    />
)
