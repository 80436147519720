import _ from 'lodash'
import { useState, useCallback, useRef, useEffect, MutableRefObject } from 'react'
import { useSizeCallback } from '../hooks/useSizeCallback'
import { MediaStream } from '../types/video-types'

export function useCanvasDimension(
    mediaStream: MediaStream | null,
    videoRef: MutableRefObject<HTMLCanvasElement | null>
) {
    const [dimension, setDimension] = useState({ width: 0, height: 0 })
    
    const debounceRef = useRef(_.debounce(setDimension, 300))

    const onCanvasResize = useCallback(
        ({ width, height }: { width: number; height: number }) => {
            if (videoRef) {
                // eslint-disable-next-line no-useless-call
                debounceRef.current({ width, height })
            }
        },
        [videoRef]
    )

    useSizeCallback(videoRef.current, onCanvasResize)
    
    useEffect(() => {
        if (videoRef.current) {
            const { width, height } = videoRef.current.getBoundingClientRect()
            setDimension({ width, height })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        const { width, height } = dimension
        try {
            if (videoRef.current) {
                videoRef.current.width = width
                videoRef.current.height = height
            }
        } catch (e) {
            mediaStream?.updateVideoCanvasDimension(
                videoRef.current as HTMLCanvasElement,
                width,
                height
            )
        }
    }, [mediaStream, dimension, videoRef])
    
    return dimension
}
