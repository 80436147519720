import { ParticipantPropertiesPayload, ChatMessage as ZoomChatMessage } from '@zoom/videosdk'
import { useCallback, useEffect, useState } from 'react'
import { getSessionItem, setSessionItem } from '../../../../../helpers/localStorage'

import toast from 'react-hot-toast'
import { useZoomContext } from '../../../../../context/ZoomContext'

export interface ChatMessage extends ZoomChatMessage {
    isFeatured: boolean
}

export interface ChatUserItem {
    userId: number
    displayName: string
    isHost: boolean
    isManager: boolean
}

export const useChat = () => {
    const { chatClient, zoomClient } = useZoomContext()
    const [history, setHistory] = useState<ChatMessage[]>(() =>
        (chatClient?.getHistory() ?? []).map((message: ZoomChatMessage) => ({ ...message, isFeatured: false }))
    )
    const [receivers, setReceivers] = useState<ChatUserItem[]>(() => chatClient?.getReceivers() ?? [])
    // Initialize the state for the selected receiver ID
    // It uses a combination of local storage and the current receivers list
    const state = useState(() => {
        // Retrieve the saved receiver ID from local storage, defaulting to 0 if not found
        const savedReceiverId = +getSessionItem<string>('receiverId') ?? 0
        // Return the saved ID if it's 0 (send to all) or if it exists in the current receivers list
        // Otherwise, default to 0 (send to all)
        return savedReceiverId === 0 || receivers.some(r => r.userId === savedReceiverId) ? savedReceiverId : 0
    })
    // Destructure the state to get the current receiver ID and the setter function
    const [receiverId, setReceiverId] = state

    const sendMessage = useCallback(
        async (message: string | undefined, receiverId: number) => {
            if (!message || !chatClient) return
            try {
                if (receiverId === 0) {
                    // Send to everyone
                    await chatClient.sendToAll(message)
                } else {
                    await chatClient.send(message, receiverId)
                }
            } catch (error) {
                console.error('Error sending message:', error)
                toast.error('Error enviando mensaje. Intentalo nuevamente en un instante')
            }
        },
        [chatClient]
    )

    const onUserUpdated = useCallback(
        (updatedParticipants: Partial<ParticipantPropertiesPayload> | Partial<ParticipantPropertiesPayload>[]) =>
            setReceivers(prev => {
                const updated = Array.isArray(updatedParticipants) ? updatedParticipants : [updatedParticipants]
                return prev.map(p => {
                    const update = updated.find(u => u.userId === p.userId)
                    return update ? { ...p, ...update } : p
                })
            }),
        []
    )

    const onUserAdded = useCallback(
        (newParticipants: ParticipantPropertiesPayload | ParticipantPropertiesPayload[]) =>
            setReceivers(prev => {
                const toAdd = Array.isArray(newParticipants) ? newParticipants : [newParticipants]
                return [
                    ...prev,
                    ...toAdd.filter(p => !prev.some(existing => existing.userId === p.userId)),
                ] as ChatUserItem[]
            }),
        []
    )

    const onUserRemoved = useCallback(
        (removedParticipants: ParticipantPropertiesPayload | ParticipantPropertiesPayload[]) => {
            const toRemove = Array.isArray(removedParticipants) ? removedParticipants : [removedParticipants]
            setReceivers(prev => prev.filter(p => !toRemove.some(removed => removed.userId === p.userId)))
            if (toRemove.some(p => p.userId === receiverId)) setReceiverId(0)
        },
        [receiverId, setReceiverId]
    )

    const onChatMessage = (message: ChatMessage) => setHistory(old => [...old, message])

    useEffect(() => {
        setSessionItem('receiverId', receiverId.toString())
    }, [receiverId])

    useEffect(() => {
        zoomClient.on('chat-on-message', onChatMessage)
        zoomClient.on('user-added', onUserAdded)
        zoomClient.on('user-removed', onUserRemoved)
        zoomClient.on('user-updated', onUserUpdated)

        return () => {
            zoomClient.off('chat-on-message', onChatMessage)
            zoomClient.off('user-added', onUserAdded)
            zoomClient.off('user-removed', onUserRemoved)
            zoomClient.off('user-updated', onUserUpdated)
        }
    }, [zoomClient, receiverId, setReceiverId, onUserAdded, onUserRemoved, onUserUpdated])

    return { history, receivers, state, sendMessage }
}
