import { SidebarModeType } from '../../../../context/ZoomContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Tooltip from '../../../../components/Tooltip/Tooltip.component'
import { IconName, IconPrefix } from '@fortawesome/pro-light-svg-icons'

export const FooterIcon = ({ handleClick, icon, tooltip, active, id, ...rest }: FooterIconProps) => {
    return (
        <Tooltip text={active ? tooltip.hide : tooltip.show} placement="topCenter">
            <button onClick={handleClick}>
                <FontAwesomeIcon
                    icon={icon}
                    style={{ color: id === 'record-btn' && active ? '#FF647C' : active ? 'rgb(0, 163, 255)' : 'white' }}
                />
            </button>
        </Tooltip>
    )
}

interface FooterIconProps extends React.HTMLAttributes<HTMLButtonElement> {
    showSidebar?: SidebarModeType
    icon: [IconPrefix, IconName]
    handleClick: () => void
    tooltip: { hide: string; show: string }
    active: boolean
    id?: string
}
