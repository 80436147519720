import ParticipantItem from './ParticipantItem'
import { PrimaryBtnOutline } from '../../../../components/Buttons'
import SquareButton from '../../../../components/SquareButton'
import { signInToAdmin } from '../../../../apis/api-egg'
import styled from 'styled-components'
import { useHostFunctions } from '../../hooks/useHostFunctions'
import { useParticipants } from './useParticipants'
import { useSessionParams } from '../../../../hooks/useSessionParams'
import { useTimeline } from '../../../../hooks/useTimeline'
import { useZoomContext } from '../../../../context/ZoomContext'

const Participants = () => {
    const { participants, searchParticipants } = useParticipants()
    const { enableMic, disableMic } = useHostFunctions()
    const { isHostOrManager, currentUserInfo } = useZoomContext()
    const { _id: _userId, _classId } = useSessionParams()
    const { currentEvent } = useTimeline()
    const { settings } = currentEvent || {}
    const { enabledMic } = settings || {}

    return (
        <ParticipantsStyled>
            <div className="top">
                <div className="search">
                    <input
                        type="text"
                        onChange={e => searchParticipants(e.target.value)}
                        placeholder="Buscar participantes"
                    />
                    <SquareButton disabled icon={['far', 'magnifying-glass']} dark />
                </div>
                <div className="participants">
                    {participants?.map(participant => {
                        return (
                            <ParticipantItem
                                key={participant.userId}
                                participant={participant}
                                currentUser={currentUserInfo?.userId || 0}
                            />
                        )
                    })}
                </div>
            </div>
            {isHostOrManager && (
                <div className="bottom">
                    <PrimaryBtnOutline colorTheme="light" fz="sm" onClick={enabledMic ? disableMic : enableMic}>
                        {enabledMic ? 'Silenciar a todos' : 'Habilitar micrófonos'}
                    </PrimaryBtnOutline>
                    <PrimaryBtnOutline
                        colorTheme="light"
                        fz="sm"
                        className="manage-meetings"
                        onClick={() => _userId && _classId && signInToAdmin(_userId, _classId)}
                    >
                        Administrar encuentros
                    </PrimaryBtnOutline>
                </div>
            )}
        </ParticipantsStyled>
    )
}

export default Participants

const ParticipantsStyled = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    /* padding: 30px; */
    box-sizing: border-box;
    .top {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 0; /* Hide the scrollbar */
            background: transparent; /* Optionally, you can set it to transparent */
        }
        .search {
            display: grid;
            grid-template-columns: auto 44px;
            margin-bottom: 20px;
            grid-gap: 5px;
            input {
                width: 100%;
                border-radius: 12px;
                border: none;
                background-color: ${props => props.theme.color.sidebar.gray1};
                border: ${props => props.theme.color.sidebar.gray2};
                color: white;
                height: 40px;
                padding: 0 20px;
                box-sizing: border-box;
                margin: 4px 0;
                outline-style: solid;
                outline-color: #444444;
                margin-left: 1px;
            }
        }
    }
    .bottom {
        flex: 1;
        display: flex;
        padding: 20px 0 !important;
        padding-bottom: 0 !important;
        gap: 10px;
    }
`
