import { useCallback, useEffect } from 'react'

import { CommandChannelMsg } from '@zoom/videosdk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NotificationWithCross } from '../../../components/NotificationWithCross'
import { useParticipants } from '../components/Participants/useParticipants'
import { useUI } from '../../../context/UIContext'
import { useZoomContext } from '../../../context/ZoomContext'

const useCommandChannel = () => {
    const { showToast } = useUI()
    const { zoomClient, currentUserInfo, raisedHands, toggleRaiseHand } = useZoomContext()
    const { participants } = useParticipants()

    const handleTextMsg = useCallback(
        (payload: CommandChannelMsg) => {
            try {
                const data = JSON.parse(payload.text)
                // Handle JSON messages
                switch (data.type) {
                    case 'disableMic':
                        !zoomClient.isManager() &&
                            showToast('El anfitrión ha silenciado los participantes', { id: 'disableMic' })
                        break
                    case 'enableMic':
                        !zoomClient.isManager() &&
                            showToast('El anfitrión ha habilitado los micrófonos', { id: 'enableMic' })
                        break
                    case 'toggle-raise-hand':
                        if (!currentUserInfo) return

                        const { userId, isRaised, force } = data
                        if (!isRaised && !raisedHands.some(item => item.userId === userId)) return // Avoid duplicates

                        const user = participants.find(item => item.userId === userId)
                        if (!user) return

                        // Show only raised hand notifications
                        if (isRaised)
                            showToast(
                                <NotificationWithCross id={userId}>
                                    {user.displayName} ha levantado la mano
                                </NotificationWithCross>,
                                {
                                    id: userId,
                                    icon: <FontAwesomeIcon icon={['far', 'hand']} style={{ color: '#00A3FF' }} />,
                                }
                            )

                        if (userId !== currentUserInfo?.userId || force) toggleRaiseHand(userId, { notify: false })

                        break
                    default:
                        return console.warn('Unknown command channel message', data)
                }
            } catch (error) {}
        },
        [currentUserInfo, participants, raisedHands, showToast, toggleRaiseHand, zoomClient]
    )

    useEffect(() => {
        zoomClient.on('command-channel-message', handleTextMsg)
        return () => {
            zoomClient.off('command-channel-message', handleTextMsg)
        }
    }, [zoomClient, handleTextMsg])

    return {
        handleTextMsg,
    }
}

export default useCommandChannel
