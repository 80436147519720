import { ConfirmationDialogStyled } from './ConfirmationDialog'
import { PrimaryBtnOutline } from '../Buttons'

export const PermissionModal = () => {
    return (
        <ConfirmationDialogStyled>
            <div className="info">
                <h2>Permisos insuficientes</h2>
                <div>
                    <p>Tu navegador no tiene los permisos suficientes para iniciar este encuentro.</p>
                    <p>Permite el acceso a la cámara, el micrófono y luego recarga tu navegador.</p>
                </div>
            </div>
            <div className="actions">
                <PrimaryBtnOutline onClick={() => window.location.reload()} colorTheme={'light'}>
                    Volver a unirse
                </PrimaryBtnOutline>
            </div>
        </ConfirmationDialogStyled>
    )
}
