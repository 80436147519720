import { PropsWithChildren, createContext, useContext, useState } from 'react'
import toast, { type ToastOptions } from 'react-hot-toast'
import { VideoMessage } from '../apis/api-egg'
import { AppToaster } from '../components/AppToaster'
import { Pip, PipType } from '../components/Pip'
import { getMessageFromError } from '../helpers/errors'

const UIContext = createContext<UIContextType | undefined>(undefined)
UIContext.displayName = 'UIContext'

const UIProvider = ({ children }: PropsWithChildren) => {
    const [pip, setPip] = useState<PipType | null>(null)

    const showToast = toast

    const openPip = (pip: PipType) => {
        const { mute = true, ...rest } = pip

        if (mute) window.mediaStream?.muteAllUserAudioLocally()

        setPip({
            ...rest,
            onClose: () => {
                if (mute) window.mediaStream?.unmuteAllUserAudioLocally()
                pip.onClose?.()
                setPip(null)
            },
        })

        sessionStorage.setItem('last-pip', JSON.stringify(pip))
    }

    const showError = (error: unknown, options?: ToastOptions) => toast.error(getMessageFromError(error), options)

    const closePip = () => pip?.onClose?.()

    return (
        <UIContext.Provider value={{ showToast, showError, pip, openPip, closePip }}>
            {children}
            <AppToaster />
        </UIContext.Provider>
    )
}

const useUI = () => {
    const context = useContext(UIContext)
    if (context === undefined) {
        throw new Error('useUI must be used within a UIProvider')
    }
    return context
}

export { UIProvider, useUI }
export interface UIContextType {
    showToast: typeof toast
    // eslint-disable-next-line no-unused-vars
    showError: (error: unknown, options?: ToastOptions) => string
    pip: PipType | null
    openPip: (props: Omit<VideoMessage, 'type'>) => void
    closePip: () => void
}
