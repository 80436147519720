import styled from 'styled-components'
import { PrimaryBtnBlue } from '../../../../components/Buttons'
import { useZoomContext } from '../../../../context/ZoomContext'

const DevBlock = () => {
    const { loggerClient } = useZoomContext()

    const sendDetailedReport = () => {
        if (!loggerClient) {
            console.log('No logger client')
            return
        }

        loggerClient.reportToGlobalTracing().then(res => {
            console.info('Detailed report sent', res)
        })
    }

    return (
        <DevBlockStyled id="dev-block">
            <section>
                <h3>Send detailed report</h3>
                <PrimaryBtnBlue style={{ width: '100%' }} onClick={sendDetailedReport}>
                    Send
                </PrimaryBtnBlue>
            </section>
        </DevBlockStyled>
    )
}

export default DevBlock

const DevBlockStyled = styled.div`
    background-color: white;
    width: auto;
    height: auto;
    visibility: hidden;
    position: fixed;
    top: 10px;
    right: 10px;
    border-radius: 10px;
    padding: 20px;
`
